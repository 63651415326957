import styled from 'styled-components'
import { media } from 'ui'

export const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 2.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colours.text};
  line-height: 1.5;

  ${media.greaterThan('tablet')`
    line-height: 1.5;
  `}
`

export const TitleMedium = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  line-height: 1.6;
  font-weight: bold;
  color: ${({ theme }) => theme.colours.text};
`

export const TitleSmall = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  line-height: 2;
  font-weight: bold;
  color: ${({ theme }) => theme.colours.text};
  text-transform: uppercase;
  letter-spacing: .06em;
`
