import React from 'react'
import { ContentBox, Progress, ErrorMessage, colours, radius } from 'ui'
import { errorMessages } from 'resources'
import { useDisciplineStats } from './hooks'
import ContentLoader from 'react-content-loader'

export const DisciplineStats = () => {
  const { disciplineStats, loading, error } = useDisciplineStats()

  if (loading) return <ContentLoading />

  if (error) {
    return (
      <ContentBox title='Aulas assistidas'>
        <ErrorMessage message={errorMessages.DISCIPLINE_STATS_ERROR} textSize='medium' hasRefresh />
      </ContentBox>
    )
  }

  return (
    <ContentBox title='Aulas assistidas'>
      <Progress items={disciplineStats} />
    </ContentBox>
  )
}

const ContentLoading = (props) => (
  <ContentLoader
    speed={2}
    width={100}
    height={425}
    backgroundColor={colours.contentLoadingColor1}
    foregroundColor={colours.contentLoadingColor2}
    style={{ width: '100%', height: '42.5rem' }}
    {...props}
  >
    <rect x='0' y='0' rx={radius.large} ry={radius.large} width='100%' height='42.5rem' />
  </ContentLoader>
)
