import React from 'react'
import t from 'prop-types'

export const IconClose = ({ color = '#fff', size = 24 }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 24 24' fill='none' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' className='feather feather-x'><line x1='18' y1='6' x2='6' y2='18' /><line x1='6' y1='6' x2='18' y2='18' /></svg>
)

IconClose.propTypes = {
  color: t.string,
  size: t.number,
}
