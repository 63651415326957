import { gql } from '@apollo/client'

export const STATES_QUERY = gql`
query GetStates($filter: FilterStatesInput) {
  states(filter: $filter) {
    nodes {
      id
      name
      slug
    }
  }
}
`
