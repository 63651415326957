import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import { useHistory } from 'react-router-dom'
import { IconClose, Button, theme, media } from 'ui'
import { useTransition, animated } from 'react-spring'

const SimulatedModal = ({
  title,
  subtitle,
  description,
  buttonAction,
  buttonText,
  visible,
  onClose,
  hasCancel,
  buttonDisabled,
  questions,
  buttonTextCancel,
  status,
}) => {
  const history = useHistory()
  const transitions = useTransition(visible, null, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const goBack = () => history.goBack()

  return transitions.map(({ item, props, key }) => item ? (
    <Overlay key={key} style={props}>
      <Wrapper>
        <>
          {onClose &&
            <CloseButton onClick={onClose}>
              <IconClose
                size={24}
                color={theme.colours.primary}
              />
            </CloseButton>}
          <Title>{title}</Title>
          <SubTitle>{subtitle}</SubTitle>
          {description && <Description>{description}</Description>}

          {questions || null}
          <Actions>
            <ButtonAction disabled={buttonDisabled || status} as='button' onClick={buttonAction}>
              {buttonText || 'Concluir'}
            </ButtonAction>
            {hasCancel && <ButtonCancel variant='ghost' as='button' onClick={buttonTextCancel ? onClose : goBack}>{buttonTextCancel || 'Cancelar'}</ButtonCancel>}
          </Actions>
        </>
      </Wrapper>
    </Overlay>
  ) : null)
}

SimulatedModal.propTypes = {
  title: t.string.isRequired,
  subtitle: t.string,
  description: t.string,
  buttonAction: t.func.isRequired,
  buttonText: t.string.isRequired,
  visible: t.bool.isRequired,
  onClose: t.func,
  hasCancel: t.bool,
  buttonTextCancel: t.string,
}

const Overlay = styled(animated.div)`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: ${theme.zIndex.aboveAll};
  overflow: hidden;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  background: ${({ theme }) => rgba(theme.colours.mainBackground, 0.8)};
  border-radius: ${theme.radius.large};
  backdrop-filter: blur(.2rem);
`

const Wrapper = styled.div`
  position: relative;
  padding: 2rem 2.4rem;
  background: ${({ theme }) => rgba(theme.colours.background, 0.8)};
  border-radius: ${theme.radius.medium};
  max-width: 50rem;
  width: 100%;
  box-shadow: 0 0 2rem 1rem rgba(0, 0, 0, .05);
  margin-top: 8rem;
  height: auto;

  ${media.lessThan('phone')`
    max-width: 90%;
  `}
`

const CloseButton = styled.button`
  border: 0;
  outline: 0;
  background: 0;
  box-shadow: 0;
  position: absolute;
  top: -4rem;
  right: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in 200ms;
  width: 4rem;
  height: 4rem;

  &:hover {
    transform: rotate(90deg);
  }
`

const Title = styled.h4`
  font-size: 1.8rem;
  color: ${theme.colours.primary};
  line-height: 1.4;
`

const SubTitle = styled.p`
  font-size: 1rem;
  color: ${theme.colours.gray2};
  line-height: 1.6;
  padding: .4rem 0 0;
  margin: 0;
  font-weight: 600;
`

const Description = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colours.text};
  line-height: 1.6;
  padding: 1.2rem 0 .5rem;
  margin: 0;
  font-weight: 600;
  text-align: justify;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  ${media.lessThan('phone')`
    flex-direction: column;
    gap: 1rem;

    > a,
    > button {
      width: 100%;
    }
  `}
`

const ButtonAction = styled(Button)`
  font-size: 1.3rem;
  padding: .9em 1.6em 1em;
  height: 4.2rem;
`

const ButtonCancel = styled(Button)`
  font-size: 1.3rem;
  padding: .8em 1.6em 1em;
  height: 4.2rem;

  ${media.greaterThan('phone')`
    margin-left: 1rem;
  `}
`

export { SimulatedModal }
