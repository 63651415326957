import { gql } from '@apollo/client'

export const GET_ME_QUERY = gql`
  query GetMe {
    me {
      id
      email
      role
      name
      photo
      hasSomeUnreadNotification
      startedStudyPlan
      hasRenewalDiscount
      hasSupport
      expiresIn
      active
      expired
      isMonitor
      canUseCommunity
      plans {
        name
        initialDate
        finalDate
        active
      }
      course {
        slug
        name
      }
    }
  }
`
