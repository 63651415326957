import React from 'react'
import { Announcement } from './announcement'
import { useAnnouncement } from './hooks'

const AnnouncementInterface = () => {
  const { dismissAnnouncement, announcement, loading, error } = useAnnouncement()

  return (
    <Announcement
      loading={loading}
      error={error}
      handleDismiss={dismissAnnouncement}
      {...announcement}
    />
  )
}

export { AnnouncementInterface }
