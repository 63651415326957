import React from 'react'
import t from 'prop-types'
import { colours, theme, darkTheme } from 'ui'

const IconSee = ({ active }) => {
  const storedValue = localStorage.getItem('darkMode')
  let checkedTheme = theme
  const myBoolean = storedValue ? storedValue.toLowerCase() === 'true' : false

  if (myBoolean) {
    checkedTheme = darkTheme
  }

  return (
    <svg width='24px' height='24px' viewBox='0 0 500 500' fill={`${myBoolean ? active ? checkedTheme.colours.text : checkedTheme.colours.gray3 : active ? colours.text : colours.gray3}`}>
      <g><path d='M508.745,246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818,239.784,3.249,246.035 c-4.332,5.936-4.332,13.987,0,19.923c4.569,6.257,113.557,153.206,252.748,153.206s248.174-146.95,252.748-153.201 C513.083,260.028,513.083,251.971,508.745,246.041z M255.997,385.406c-102.529,0-191.33-97.533-217.617-129.418 c26.253-31.913,114.868-129.395,217.617-129.395c102.524,0,191.319,97.516,217.617,129.418 C447.361,287.923,358.746,385.406,255.997,385.406z' /></g>
      <g><path d='M255.997,154.725c-55.842,0-101.275,45.433-101.275,101.275s45.433,101.275,101.275,101.275 s101.275-45.433,101.275-101.275S311.839,154.725,255.997,154.725z M255.997,323.516c-37.23,0-67.516-30.287-67.516-67.516 s30.287-67.516,67.516-67.516s67.516,30.287,67.516,67.516S293.227,323.516,255.997,323.516z' /></g>
    </svg>
  )
}

IconSee.propTypes = {
  active: t.bool,
}

export { IconSee }
