import React, { useState } from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { FormControl, FormLabel, FormField, FormError } from 'ui'
import { IconSeePassword } from './icon-see-password'

const InputPassword = ({
  required,
  id,
  label,
  name,
  error,
  disabled,
  inputRef,
  placeholder = 'Senha',
  ...rest
}) => {
  const [isValueVisible, setIsValueVisible] = useState(false)

  const toggleVisibility = () => setIsValueVisible(!isValueVisible)

  const type = isValueVisible ? 'text' : 'password'

  return (
    <FormControl>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <FormField
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        ref={inputRef}
        error={error}
        disabled={disabled}
        {...rest}
      />
      <SeePassword onClick={() => toggleVisibility()}>
        <IconSeePassword showingPassword={isValueVisible} />
      </SeePassword>
      {error && <FormError>{error?.message || 'Campo obrigatório'}</FormError>}
    </FormControl>
  )
}

InputPassword.propTypes = {
  label: t.string.isRequired,
  name: t.string.isRequired,
  id: t.string.isRequired,
  required: t.bool,
  error: t.object,
  disabled: t.bool,
  placeholder: t.string,
  inputRef: t.func,
}

const SeePassword = styled.div`
  position: absolute;
  top: 42%;
  right: 2rem;
  cursor: pointer;
`

export { InputPassword }
