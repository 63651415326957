import React from 'react'
import { Select } from '../'

export const QuestionStatus = props => (
  <Select
    items={options}
    {...props}
  />
)

const options = [
  {
    label: 'Todas',
    value: '',
  },
  {
    label: 'Não respondidas',
    value: 'NOT_ANSWERED',
  },
  {
    label: 'Respondidas',
    value: 'ANSWERED',
  },
  {
    label: 'Erradas',
    value: 'WRONG_ANSWER',
  },
]
