import { useQuery, gql } from '@apollo/client'
import { pipe, path, map, defaultTo } from 'ramda'

import { IconGoldMedal, IconSilverMedal, IconBronzeMedal } from 'ui'

export const useCommunityRanking = () => {
  const { data, loading } = useQuery(COMMUNITY_RANKING_QUERY)
  return {
    data: mapDataToRanking(data),
    loading,
  }
}

const COMMUNITY_RANKING_QUERY = gql`
  {
    mostActiveInCommunity {
      user {
        name
        surname
        photo
      }
      quantityOfAnswers
      position
    }
  }
`

const rankingColorTypes = ['gold', 'silver', 'bronze']
const rankingIconTypes = [IconGoldMedal, IconSilverMedal, IconBronzeMedal]

const toRankingItem = ({ user, position, quantityOfAnswers }) => ({
  name: `${user?.name} ${user?.surname}`,
  photo: user?.photo,
  quantityOfAnswers,
  position: position + 1,
  color: position < 3 ? rankingColorTypes[position] : '',
  icon: position < 3 ? rankingIconTypes[position] : '',
})

const mapDataToRanking = pipe(
  path(['mostActiveInCommunity']),
  defaultTo([]),
  map(toRankingItem)
)
