import React, { useEffect, useCallback, useRef } from 'react'
import ReactDOM from 'react-dom'

import t from 'prop-types'

import { IconClose, FreePlanMessage } from 'ui'
import { Dimmer, Content, Header, HeaderIcon } from './components'

const FreePlanMessageModal = ({ handleClose }) => {
  const modalRef = useRef(null)

  const handleOutsideClick = useCallback((elm) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(elm.target)
    ) {
      handleClose()
    }
  }, [handleClose])

  const handleMount = useCallback(() => {
    document.addEventListener('mousedown', handleOutsideClick)
  }, [handleOutsideClick])

  const handleUnmount = useCallback(() => {
    document.removeEventListener('mousedown', handleOutsideClick)
  }, [handleOutsideClick])

  useEffect(() => {
    handleMount()

    return () => {
      handleUnmount()
    }
  }, [handleMount, handleUnmount])

  return ReactDOM.createPortal(
    (
      <Dimmer>
        <Content ref={modalRef}>
          <Header>
            <HeaderIcon onClick={handleClose}>
              <IconClose color='black' />
            </HeaderIcon>
          </Header>

          <FreePlanMessage />
        </Content>
      </Dimmer>
    ),
    document.getElementById('modal-root')
  )
}

FreePlanMessageModal.propTypes = {
  handleClose: t.func.isRequired,
}

export { FreePlanMessageModal }
