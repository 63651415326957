import React from 'react'
import { rgba } from 'polished'
import { ProgressBar, ErrorMessage, colours } from 'ui'
import { errorMessages } from 'resources'
import { Wrapper, Item, Title, Numbers, NumbersText, NumbersText2, ContentLoading } from './components'
import { useFeaturedStats } from './hooks'

const FeaturedStats = () => {
  const { featuredStats, loading, error } = useFeaturedStats()

  if (loading) return <ContentLoading />

  if (error) {
    return (
      <Wrapper>
        <ErrorMessage message={errorMessages.FEATURED_STATS_ERROR} textSize='large' hasBackground hasRefresh />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {featuredStats.map(({ type, resolved, total }) => {
        if (type === 'Simulados realizados' || type === 'Redações enviadas') {
          return (
            <Item key={type}>
              <Title>{type}</Title>
              <NumbersText2>{resolved}</NumbersText2>
            </Item>
          )
        }
        return (
          <Item key={type}>
            <Title>{type}</Title>
            <Numbers>
              <NumbersText>{resolved}/{total}</NumbersText>
              <ProgressBar
                completed={resolved}
                total={total}
                background={rgba(colours.text, 0.4)}
                foreground={colours.background}
              />
            </Numbers>
          </Item>
        )
      })}
    </Wrapper>
  )
}

export { FeaturedStats }
