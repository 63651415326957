import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
} from 'recharts'

const ScoreChart = ({ data, color }) => {
  const dataLine = data.filter(d => d.type === 'ENEM').length ? data.filter(d => d.type === 'ENEM').slice(0, 3) : data.slice(0, 3)

  return (
    <>
      <Wrapper>
        <LineChart
          width={260}
          height={180}
          data={dataLine}
          syncId='anyId'
          margin={{
            top: 40,
            right: 10,
            left: 10,
            bottom: 10,
          }}
        >
          <XAxis dataKey='index' padding={{ left: 20, right: 20 }} />
          <YAxis hide />
          <Line dataKey='grade' stroke={color} strokeWidth={3} fill={color} label={{ fill: (theme) => theme.colours.text, fontSize: 13, position: 'top', offset: 10, fontWeight: 'bold' }} />
        </LineChart>
      </Wrapper>
    </>
  )
}

ScoreChart.propTypes = {
  data: t.array,
  color: t.string,
}

export { ScoreChart }

const Wrapper = styled.div`
  align-items: end;
  display: flex;
  flex-direction: row;
  justify-content: center;
`
