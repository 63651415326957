import React from 'react'
import t from 'prop-types'

import { Select } from '../'
import { useSubjectsFetcher } from './use-subjects-fetcher'

export const SubjectSelect = ({ disciplineSlug, onlyParents, parentSlug, disabled, ...props }) => {
  const { subjects, isFetching } = useSubjectsFetcher({ onlyParents, parentSlug, disciplineSlug })
  return <Select loading={isFetching} items={subjects} disabled={disabled} {...props} />
}

SubjectSelect.propTypes = {
  onlyParents: t.bool,
  parentSlug: t.string,
  disciplineSlug: t.string,
  disabled: t.bool,
}
