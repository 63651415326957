import React from 'react'
import styled from 'styled-components'
import t from 'prop-types'
import { readableColor } from 'polished'

const possibleLevels = {
  EASY: 'Fácil',
  MEDIUM: 'Médio',
  HARD: 'Difícil',
}

export const QuestionLevel = ({ level }) => {
  return (
    <Wrapper>
      {Object.keys(possibleLevels).map(item => (
        <Item
          key={item}
          isActive={item === level}
        >
          {possibleLevels[item]}
        </Item>
      ))}
    </Wrapper>
  )
}

QuestionLevel.propTypes = {
  level: t.string,
}

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  padding: 1.5rem .125rem;
`

const Item = styled.div`
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  color: ${({ theme }) => theme.darkTheme ? readableColor(theme.colours.primaryBlue) : readableColor(theme.colours.primary)};
  background-color: ${({ theme }) => theme.colours.primary};
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  padding: .475rem 1rem;
  font-size: 1.2rem;
  border-radius: ${({ theme }) => theme.radius.small};
  opacity: .3;

  ${({ isActive }) => isActive && `
    opacity: 1;
  `}

  &:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`
