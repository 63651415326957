import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import ReactStars from 'react-rating-stars-component'
import { rgba, darken } from 'polished'

import { colours } from 'ui'
import { confirmFadeIn } from 'ui/keyframes'

export const SupportCloseModal = ({ handleClose, handleCloseTicket, feedback, handleRating, handleMessageChange }) => {
  const isButtonDisabled = feedback.rating < 3 && feedback.message.length < 100

  return (
    <Overlay>
      <Box>
        <CloseButton onClick={handleClose}>×</CloseButton>
        <Title>Instruções para fechamento do seu chamado</Title>
        <Warning>Você está prestes a fechar o seu chamado atual de monitoria. Lembre-se que o prazo para recebimento da resposta é de 24h úteis. Ao fechar o seu chamado atual, o monitor não poderá mais te responder.</Warning>
        <RattingBox>
          <RattingTitle>Avalie esse chamado</RattingTitle>
          <ReactStars
            key={feedback.rating}
            count={5}
            value={feedback.rating}
            onChange={handleRating}
            size={24}
            color={colours.gray3}
          />
          {feedback.showField && (
            <FeedbackWrapper>
              <FeedbackTextArea
                placeholder='Fale mais sobre o que achou do atendimento'
                value={feedback.message}
                onChange={handleMessageChange}
              />
              <FeedbackWarning>Min. 100 caracteres</FeedbackWarning>
            </FeedbackWrapper>
          )}
          <CloseTicketButton
            as='button'
            onClick={handleCloseTicket}
            disabled={isButtonDisabled}
          >
            Fechar chamado
          </CloseTicketButton>
        </RattingBox>
      </Box>
    </Overlay>
  )
}

SupportCloseModal.propTypes = {
  handleClose: t.func.isRequired,
  handleCloseTicket: t.func.isRequired,
  feedback: t.shape({
    showField: t.bool,
    rating: t.number,
    message: t.string,
  }),
  handleRating: t.func,
  handleMessageChange: t.func,
}

const Box = styled.div`
  border-radius: ${({ theme }) => theme.radius.medium};
  background-color: ${({ theme }) => theme.colours.background};
  padding: 4rem 3rem;
  position: relative;
  width: 100%;
  max-width: 60rem;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
`

const CloseButton = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;
  font-size: 3rem;
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  cursor: pointer;
`

const Title = styled.h3`
  font-size: 2rem;
  margin-bottom: 2rem;
`

const Warning = styled.p`
  font-size: 1.4rem;
  line-height: 1.5;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => rgba(theme.colours.background, 0.9)};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  opacity: 0;
  animation: ${confirmFadeIn} 0.5s 0.2s forwards;
`

const RattingBox = styled.div`
  padding: 3rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const RattingTitle = styled.h4`
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
`

const CloseTicketButton = styled.button`
  appearance: none;
  border: none;
  background-color: ${({ theme }) => theme.colours.primary};
  color: ${({ theme }) => theme.colours.background};
  border-radius: ${({ theme }) => theme.radius.small};
  padding: 0.6rem 1.8rem;
  outline: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.2rem;
  margin-top: 3rem;
  transition: background-color .25s ease;

  &:focus,
  &:hover {
    background-color: ${({ theme }) => darken(0.08, theme.colours.primary)};
  }

  &:disabled {
    opacity: .5;
    cursor: default;
    pointer-events: none;
  }
`

const FeedbackWrapper = styled.div`
  width: 100%;
  position: relative;
`

const FeedbackTextArea = styled.textarea`
  font-size: 1.2rem;
  border-radius: ${({ theme }) => theme.radius.small};
  width: 100%;
  height: 6rem;
  outline: 0;
  border: 1px solid ${({ theme }) => theme.colours.gray3};
  padding: 1rem;
  margin-top: 2rem;

  &:focus {
    border-color: ${({ theme }) => theme.colours.gray2};
  }
`

const FeedbackWarning = styled.span`
  color: ${({ theme }) => theme.colours.error};
  position: absolute;
  right: 0;
  bottom: -1rem;
`
