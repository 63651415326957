import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { ThemeContext } from '../../providers'
import t from 'prop-types'

const Switch = ({ isSidebarOpened }) => {
  const { toggleDarkMode, darkMode } = useContext(ThemeContext)
  const [, setIsChecked] = useState(darkMode)

  useEffect(() => {
    const storedValue = localStorage.getItem('darkMode')
    if (storedValue !== null) {
      setIsChecked(storedValue === 'true')
    }
  }, [])

  return (
    <Wrapper isSidebarOpened={isSidebarOpened}>
      <Input type='checkbox' onChange={toggleDarkMode} checked={darkMode} />
      <Slider />
    </Wrapper>
  )
}

const Wrapper = styled.label`
  position: ${({ isSidebarOpened }) => isSidebarOpened ? 'relative' : 'absolute'};
  display: inline-block;
  width: 37px;
  height: 19px;
  margin-top: ${({ isSidebarOpened }) => !isSidebarOpened && '4rem'};
`

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.darkTheme ? theme.colours.background2 : theme.colours.primary};
  transition: .4s;
  border-radius: 24px;

  &:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 3px;
    bottom: 2px;
    background-color: ${({ theme }) => theme.darkTheme ? theme.colours.primary : theme.colours.background};
    transition: .4s;
    border-radius: 50%;
  }
`

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: ${({ theme }) => theme.darkTheme ? theme.colours.secondary : theme.colours.primary};
  }

  &:checked + ${Slider}:before {
    transform: translateX(16px);
  }
`

Switch.propTypes = {
  isSidebarOpened: t.bool,
}

export { Switch }
