import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import { identity, ifElse, isNil } from 'ramda'
import { flow } from 'resources/composable'

const makeUrl = ({ id, initialTime }) => flow(
  `https://fast.player.liquidplatform.com/pApiv2/embed/f5ed1e5797cf4c36a6a292ae786327ae/${id}?html5=true&startOutput=480p`,
  ifElse(
    _ => !isNil(initialTime),
    url => `${url}&initialTime=${initialTime}&autoStart=true`,
    identity
  )
)

const Player = ({ id, initialTime }) => {
  return (
    <Wrapper>
      <PlayerWrapper>
        <iframe
          src={makeUrl({ id, initialTime })}
          allowFullScreen='allowfullscreen'
          scrolling='no'
          frameBorder='0'
          title={`Video ${id}`}
        />
      </PlayerWrapper>
    </Wrapper>
  )
}

Player.propTypes = {
  id: t.string.isRequired,
  initialTime: t.number,
}

const Wrapper = styled.div`
  position: relative;
  background: ${({ theme }) => rgba(theme.colours.gray4, 0.6)};
  width: 100%;
  height: auto;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.radius.small};
`

const PlayerWrapper = styled.div`
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0;
    left: 0;
    top: 0;
  }
`

export { Player }
