import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import { useWindowSize } from '@react-hook/window-size/throttled'
import ContentLoader from 'react-content-loader'
import { NavLink } from 'react-router-dom'

import { ContentBox, ErrorMessage, colours, radius, media, Button } from 'ui'
import { errorMessages } from 'resources'
import { SUPPORT_NEW } from 'routes'

import { useSupportDisciplines } from './use-support-disciplines'

export const SupportDisciplines = ({ context, children }) => {
  const { supportDisciplines, loading, error } = useSupportDisciplines()
  const [width] = useWindowSize()
  const showCollapsedFilter = width < 900

  if (error) {
    return (
      <ContentBox>
        <ErrorMessage message={errorMessages.DISCIPLINES_ERROR} hasRefresh />
      </ContentBox>
    )
  }

  return (
    <Wrapper>
      <Content>{children}</Content>
      <Nav>
        <StickyContentBox
          title='Matérias'
          collapsable={showCollapsedFilter}
          collapsed={showCollapsedFilter}
        >
          {loading
            ? <ContentLoading />
            : supportDisciplines.map(({ name, id }) => (
              <Item key={id} to={`${context}/${id}`}>{name}</Item>))}
          <CTAWrapper>
            <Button to={SUPPORT_NEW}>Abrir Chamado</Button>
          </CTAWrapper>
          <Warning>O prazo de resposta é de <b>24 horas úteis</b></Warning>
        </StickyContentBox>
      </Nav>
    </Wrapper>
  )
}

SupportDisciplines.propTypes = {
  context: t.string.isRequired,
  children: t.oneOfType([
    t.arrayOf(t.node),
    t.node,
  ]).isRequired,
}

const activeClassName = 'active-discipline'

const Warning = styled.div`
  background-color: ${({ theme }) => rgba(theme.colours.warning, 0.5)};
  font-size: 1.2rem;
  padding: 1.5rem;
  margin-top: 2rem;
  border-radius: ${({ theme }) => theme.radius.small};
`

const CTAWrapper = styled.div`
  margin-top: 2rem;
  padding-top: 3rem;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    height: 0.1rem;
    width: 100%;
    background-color: ${({ theme }) => theme.colours.gray4};
  }

  > ${Button} {
    width: 100%;
  }
`

const Item = styled(NavLink).attrs({ activeClassName })`
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .06em;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.darkPurpleGray};
  display: block;
  text-decoration: none;
  padding: 1rem 0;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  &.${activeClassName} {
    color: ${({ theme }) => theme.darkTheme ? theme.colours.primaryBlue : theme.colours.secondary};
  }
`

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-template-areas:
    'content nav';
  grid-gap: 3rem;

  ${media.lessThan('tablet')`
    grid-template-columns: 1fr;
    grid-template-areas:
      'nav'
      'content';
  `}
`

const Content = styled.div`
  grid-area: content;
`

const Nav = styled.div`
  grid-area: nav;
`

const StickyContentBox = styled(ContentBox)`
  ${media.greaterThan('tablet')`
    position: sticky;
    top: 2rem;
  `}
`

const ContentLoading = () => (
  <ContentLoader
    speed={2}
    width={100}
    height={185}
    backgroundColor={colours.contentLoadingColor3}
    foregroundColor={colours.contentLoadingColor4}
    style={{ width: '100%', height: '18.5rem' }}
  >
    <rect x='0' y='0' rx={radius.small} ry={radius.small} width='100%' height='3.5rem' />
    <rect x='0' y='50' rx={radius.small} ry={radius.small} width='100%' height='3.5rem' />
    <rect x='0' y='100' rx={radius.small} ry={radius.small} width='100%' height='3.5rem' />
    <rect x='0' y='150' rx={radius.small} ry={radius.small} width='100%' height='3.5rem' />
  </ContentLoader>
)
