import React from 'react'
import './style.css'

const HomeIcon = () => (
  <svg width='23px' height='22px' viewBox='0 0 23 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-25.000000, -194.000000)' stroke='#A6A6BE' strokeWidth='1.5'>
        <g>
          <g transform='translate(26.000000, 195.000000)'>
            <polyline points='0 8.57142857 10.7142857 0 21.4285714 8.57142857' />
            <polyline strokeLinecap='square' points='2.85714286 10 2.85714286 20 18.5714286 20 18.5714286 10' />
            <path d='M14.315,9.28 C13.8258205,8.76810479 13.1273595,8.51128247 12.4230908,8.58434982 C11.7188221,8.65741718 11.08796,9.05215577 10.7142857,9.65357143 C10.3406114,9.05215577 9.70974937,8.65741718 9.00548064,8.58434982 C8.30121191,8.51128247 7.60275092,8.76810479 7.11357143,9.28 C6.19982751,10.2374544 6.19982751,11.7439742 7.11357143,12.7014286 L10.7142857,16.4285714 L14.3157143,12.7014286 C15.2292583,11.7437834 15.2289438,10.2372636 14.315,9.28 Z' strokeLinecap='square' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const LessonsIcon = () => (
  <svg width='22px' height='18px' viewBox='0 0 22 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='square'>
      <g transform='translate(-26.000000, -254.000000)' stroke='#A6A6BE' strokeWidth='1.5'>
        <g>
          <g transform='translate(27.000000, 255.000000)'>
            <path d='M16.6666667,16 L3.33333333,16 C2.44927837,16 1.60143199,15.6488105 0.976310729,15.0236893 C0.351189468,14.398568 7.40148683e-17,13.5507216 0,12.6666667 L20,12.6666667 C20,14.5076158 18.5076158,16 16.6666667,16 L16.6666667,16 Z' />
            <path d='M2,10 L2,2 C2,0.8954305 2.8954305,-5.92118946e-16 4,-5.92118946e-16 L16,-5.92118946e-16 C17.1045695,-5.92118946e-16 18,0.8954305 18,2 L18,10' />
            <polygon points='8.66666667 4.66666667 8.66666667 8.66666667 12 6.66666667' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const EnemIcon = () => (
  <svg width='18px' height='18px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='square'>
      <g transform='translate(-28.000000, -310.000000)' stroke='#A6A6BE' strokeWidth='1.5'>
        <g>
          <g transform='translate(29.000000, 311.000000)'>
            <rect x='0' y='0' width='5.6' height='5.6' />
            <rect x='10.4' y='10.4' width='5.6' height='5.6' />
            <rect x='0' y='10.4' width='5.6' height='5.6' />
            <polyline points='10.4 3.2 12 4.8 16 0.8' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const StudyPlanIcon = () => (
  <svg width='15px' height='18px' viewBox='0 0 15 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-30.000000, -366.000000)' stroke='#A6A6BE' strokeWidth='1.5'>
        <g>
          <g transform='translate(31.000000, 367.000000)'>
            <polyline points='7.46666667 0 7.46666667 5.86666667 5.33333333 4.26666667 3.2 5.86666667 3.2 0' />
            <path d='M0,14.4 L0,14.4 C-4.73695157e-16,15.2836556 0.7163444,16 1.6,16 L12.8,16 L12.8,12.8' />
            <path d='M1.6,0 C0.7163444,-2.36847579e-16 0,0.7163444 0,1.6 L0,14.4 C0,13.5163444 0.7163444,12.8 1.6,12.8 L12.8,12.8 L12.8,0 L1.6,0 Z' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const SimulatedIcon = () => (
  <svg width='22px' height='22px' viewBox='0 0 22 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='square'>
      <g transform='translate(-26.000000, -422.000000)' stroke='#A6A6BE' strokeWidth='1.5'>
        <g>
          <g transform='translate(27.000000, 423.000000)'>
            <polygon points='14.6666667 19.3333333 9.96266667 20 5.33333333 19.3333333 5.33333333 12 9.96266667 12.6666667 14.6666667 12' />
            <path d='M9.96266667,6.66666667 L9.96266667,6.66666667 C8.1217175,6.66666667 6.62933333,5.1742825 6.62933333,3.33333333 L6.62933333,3.33333333 C6.62933333,1.49238417 8.1217175,1.6283271e-15 9.96266667,1.18423789e-15 L9.96266667,1.18423789e-15 C11.8036158,1.33226763e-15 13.296,1.49238417 13.296,3.33333333 L13.296,3.33333333 C13.296,5.1742825 11.8036158,6.66666667 9.96266667,6.66666667 Z' />
            <path d='M8,16 L1.35,17.6626667 C1.10098231,17.7248823 0.838362277,17.6386137 0.674745513,17.4408508 C0.511128749,17.243088 0.475576716,16.9689573 0.583333333,16.736 L3.64266667,10.108 C3.86059087,9.63575137 4.33322784,9.33332617 4.85333333,9.33333333 L15.1433333,9.33333333 C15.6650908,9.3332627 16.1389489,9.63753218 16.356,10.112 L19.386,16.738 C19.4926795,16.9710965 19.4562562,17.2447146 19.2923138,17.4417866 C19.1283715,17.6388586 18.8659527,17.7244732 18.6173333,17.662 L12,16' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const StatisticsIcon = () => (
  <svg width='18px' height='18px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='square'>
      <g transform='translate(-28.000000, -479.000000)' stroke='#A6A6BE' strokeWidth='1.5'>
        <g>
          <g transform='translate(29.000000, 480.000000)'>
            <path d='M6.93333333,2.13333333 C3.104,2.13333333 0,5.23733333 0,9.06666667 C0,12.896 3.104,16 6.93333333,16 C10.7626667,16 13.8666667,12.896 13.8666667,9.06666667 L6.93333333,9.06666667 L6.93333333,2.13333333 Z' />
            <path d='M9.06666667,0 L9.06666667,6.93333333 L16,6.93333333 C16,3.104 12.896,0 9.06666667,0 Z' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const QuestionsIcon = () => (
  <svg width='18px' height='16px' viewBox='0 0 18 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='square'>
      <g transform='translate(-28.000000, -536.000000)' stroke='#A6A6BE' strokeWidth='1.5'>
        <g>
          <g transform='translate(29.000000, 537.000000)'>
            <path d='M7.61904762,2.33333333 L16,2.33333333' />
            <rect x='0' y='0' width='4.57142857' height='4.66666667' />
            <path d='M7.61904762,11.6666667 L16,11.6666667' />
            <path d='M0,9.33333333 L4.57142857,14' />
            <path d='M4.57142857,9.33333333 L0,14' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const CommunityIcon = () => (
  <svg width='20' height='18' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' viewBox='0 0 20 18'>
    <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
      <g transform='translate(-26 -552)'>
        <g stroke='#A6A6BE' transform='translate(26.807 553.672)'>
          <path strokeWidth='1.5' d='M8.193 11.955v-5.12a1 1 0 011-1h7.862a1 1 0 011 1v5.277a.843.843 0 01-.844.843.843.843 0 00-.843.843v.557a.58.58 0 01-.99.41l-1.518-1.517a1 1 0 00-.707-.293h-3.96a1 1 0 01-1-1z' />
          <path fill='#FFF' strokeWidth='1.5' d='M0 1v7.808a1 1 0 001 1h.268a1 1 0 011 1v1.175a.74.74 0 001.327.452l1.723-2.238a1 1 0 01.792-.39h7.182a1 1 0 001-1V1a1 1 0 00-1-1H1a1 1 0 00-1 1z' />
          <circle cx='4.193' cy='5.328' r='1' />
          <circle cx='7.193' cy='5.328' r='1' />
          <circle cx='10.193' cy='5.328' r='1' />
        </g>
      </g>
    </g>
  </svg>
)

const StudentIcon = () => (
  <svg width='15px' height='18px' viewBox='0 0 15 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='square'>
      <g transform='translate(-30.000000, -702.000000)' stroke='#A6A6BE' strokeWidth='1.5'>
        <g>
          <g transform='translate(31.000000, 703.000000)'>
            <path d='M8,10.1333333 L4.8,10.1333333 C2.14906667,10.1333333 0,12.2824 0,14.9333333 L0,14.9333333 C0,14.9333333 2.4,16 6.4,16 C10.4,16 12.8,14.9333333 12.8,14.9333333 L12.8,14.9333333 C12.8,12.2824 10.6509333,10.1333333 8,10.1333333 Z' />
            <path d='M2.66666667,3.73333333 C2.66666667,1.67146667 4.33813333,0 6.4,0 C8.46186667,0 10.1333333,1.67146667 10.1333333,3.73333333 C10.1333333,5.7952 8.46186667,8 6.4,8 C4.33813333,8 2.66666667,5.7952 2.66666667,3.73333333 Z' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const HelpCenterIcon = () => (
  <svg width='19px' height='18px' viewBox='0 0 19 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-27.000000, -598.000000)'>
        <g transform='translate(28.000000, 599.000000)'>
          <path stroke='#A6A6BE' strokeWidth='1.5' d='M8.3894043,16 C12.8076823,16 16.3894043,12.418278 16.3894043,8 C16.3894043,3.581722 12.8076823,0 8.3894043,0 C3.9711263,0 0.389404297,3.581722 0.389404297,8 C0.389404297,12.418278 3.9711263,16 8.3894043,16 Z' />
          <path stroke='#A6A6BE' strokeWidth='1.1' fillRule='nonzero' d='M7.89257285,10.6479706 C7.81427547,10.7112108 7.7924043,10.8075868 7.7924043,10.919 C7.7924043,11.0304132 7.81427547,11.1267892 7.89257285,11.1900294 C7.99946124,11.2763623 8.13551317,11.31 8.2934043,11.31 C8.45129543,11.31 8.58734736,11.2763623 8.69423574,11.1900294 C8.77253313,11.1267892 8.7944043,11.0304132 8.7944043,10.919 C8.7944043,10.8075868 8.77253313,10.7112108 8.69423574,10.6479706 C8.58734736,10.5616377 8.45129543,10.528 7.89257285,10.6479706 Z M8.3704043,4.5 C7.94087012,4.5 7.56192615,4.55314368 7.23468499,4.66543232 C6.93023812,4.76989938 6.67456138,4.91033682 6.47128142,5.0926909 C6.27986216,5.26440523 6.13634887,5.46992977 6.42522149,6.041 C6.50647659,5.66803503 6.68314848,5.3688587 6.94058069,5.13611177 C7.29492088,4.81574941 7.76799385,4.64666217 8.37552494,4.66625994 C8.9544337,4.68622108 9.3671949,4.84849692 9.64206913,5.09882881 C9.95960619,5.38801434 10.1134043,5.73815804 10.1134043,6.145 C10.1134043,6.55382711 9.99916568,6.88126077 9.79642721,7.13735147 C9.62461526,7.35437709 9.3430176,7.5760756 8.93585415,7.78517287 C8.73108211,7.89705417 8.56244467,8.00764714 8.06718627,8.9707679 L8.61387546,8.97472942 C8.66070114,8.76257346 8.73775508,8.59167577 8.83503625,8.45791415 C8.98329429,8.25405936 9.22264311,8.04290548 9.5668666,7.83823206 C9.89792395,7.64457977 10.1656816,7.41571295 10.3700587,7.14630676 C10.5539953,6.90384479 10.6314043,6.5809958 10.6314043,6.189 C10.6314043,5.97610003 10.5888783,5.76893763 10.5048658,5.56730769 C10.4225945,5.36985643 10.2950902,5.19544531 10.1252612,5.04205132 C9.94315417,4.87756758 9.71239104,4.75002883 9.43678204,4.65327248 C9.13845017,4.54853895 8.78249657,4.5 8.3704043,4.5 Z' />
        </g>
      </g>
    </g>
  </svg>
)

const LogoutIcon = () => (
  <svg width='17px' height='22px' viewBox='0 0 17 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-29.000000, -757.000000)' stroke='#A6A6BE' strokeWidth='1.5'>
        <g>
          <g transform='translate(30.000000, 758.000000)'>
            <path d='M4.54545455,10 L14.5454545,10' id='Path' />
            <polyline strokeLinecap='square' points='10.9090909 13.6363636 14.5454545 10 10.9090909 6.36363636' />
            <polyline strokeLinecap='square' points='12.7272727 2.72727273 12.7272727 0 0 0 0 20 12.7272727 20 12.7272727 17.2727273' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const QuestionsBookIcon = () => (
  <svg width='15px' height='20px' viewBox='0 0 15 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-27.000000, -561.000000)'>
        <g transform='translate(28.000000, 562.000000)' stroke='#A6A6BE' strokeLinejoin='round' strokeWidth='1.5'>
          <path d='M0,3.14526367 L0,13.2861328 C2.705415e-16,15.4952718 1.790861,17.2861328 4,17.2861328 L13,17.2861328 L13,17.2861328 L13,4.49781261' strokeLinecap='round' />
          <path d='M13,0.582346915 L0,0.582346915 L0,4.58234692 L13,4.58234692 C12.2131022,4.28335836 11.8196533,3.61669169 11.8196533,2.58234692 C11.8196533,1.54800214 12.2131022,0.881335469 13,0.582346915 Z' />
          <line x1='3' y1='10.5' x2='10.1' y2='10.5' strokeLinecap='round' />
          <line x1='3' y1='7.5' x2='10.1' y2='7.5' strokeLinecap='round' />
          <line x1='3' y1='13.5' x2='10.1' y2='13.5' strokeLinecap='round' />
        </g>
      </g>
    </g>
  </svg>
)

const SupportIcon = () => (
  <svg width='18px' height='20px' viewBox='0 0 18 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-27.000000, -597.000000)'>
        <g transform='translate(28.000000, 598.647989)' stroke='#A6A6BE' strokeWidth='1.5'>
          <path d='M8,16.4749189 C12.418278,16.4749189 16,12.7868827 16,8.23745943 C16,3.68803621 12.418278,1.97175609e-13 8,1.97175609e-13 C3.581722,1.97175609e-13 0,3.68803621 0,8.23745943 C0,9.49186212 0.272303452,10.6807771 0.759364969,11.744951 C1.00695349,12.2859037 1.31003561,12.7946232 1.66105256,13.2633264 C2.05011169,13.7828262 0.974526674,16.5819318 1.47106934,16.9925138 C1.85271971,17.3080935 5.00311847,15.8909189 5.41884987,16.0367511 C6.22864632,16.3208151 7.09684551,16.4749189 8,16.4749189 Z' />
          <line x1='5' y1='8.53999918' x2='12' y2='8.53999918' strokeLinecap='round' strokeLinejoin='round' />
          <line x1='5' y1='5.8520109' x2='10' y2='5.8520109' strokeLinecap='round' strokeLinejoin='round' />
          <line x1='5' y1='11.2279875' x2='9' y2='11.2279875' strokeLinecap='round' strokeLinejoin='round' />
        </g>
      </g>
    </g>
  </svg>
)

const LiveIcon = () => (
  <svg
    width='28px' height='28px'
    version8='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 22 22' xmlSpace='preserve'
    style={{ marginTop: '-8px', marginBottom: '-8px' }}
  >
    <circle cx='11' cy='11' r='6.6' fill='none' stroke='#A6A6BE' strokeMiterlimit={10} />
    <polygon points='9.8,11 9.8,9.1 11.4,10.1 13,11 11.4,11.9 9.8,12.9 ' fill='none' stroke='#A6A6BE' strokeWidth={1.2} strokeMiterlimit={10} />
  </svg>
)

const EssayIcon = () => (
  <svg
    width='15px' height='18px'
    version8='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
    viewBox='0 0 15 18' style={{ enableBackground: 'new 0 0 15 18', marginTop: '-8px', marginBottom: '-8px' }} xmlSpace='preserve'
  >
    <g>
      <g transform='translate(-30.000000, -366.000000)'>
        <g>
          <g transform='translate(31.000000, 367.000000)'>
            <path className='st0' d='M12.8,16V3.4L9.4,0H0v16H12.8z' />
          </g>
        </g>
      </g>
    </g>
    <polyline className='st0' points='10,1 10,4.8 13.8,4.8 ' />
    <line className='st1' x1='3' y1='4.9' x2='8.2' y2='4.9' />
    <line className='st1' x1='3' y1='7.9' x2='11.8' y2='7.9' />
    <line className='st1' x1='3' y1='10.8' x2='9.6' y2='10.8' />
    <line className='st1' x1='3' y1='13.7' x2='11.3' y2='13.7' />
  </svg>
)

const QuestionCourseIcon = () => (
  <svg
    width='15px' height='18px'
    version8='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
    viewBox='0 0 15 18' style={{ enableBackground: 'new 0 0 15 18', marginTop: '-8px', marginBottom: '-8px' }} xmlSpace='preserve'
  >
    <g>
      <g transform='translate(-30.000000, -366.000000)'>
        <g>
          <g transform='translate(31.000000, 367.000000)'>
            <path className='st0' d='M0,14.4L0,14.4C0,15.3,0.7,16,1.6,16h11.2v-3.2' />
            <path className='st0' d='M1.6,0C0.7,0,0,0.7,0,1.6v12.8c0-0.9,0.7-1.6,1.6-1.6h11.2V0H1.6z' />
          </g>
        </g>
      </g>
    </g>
    <g>
      <g transform='translate(-28.000000, -536.000000)'>
        <g>
          <g transform='translate(29.000000, 537.000000)'>
            <path className='st0' d='M6.9,4h4' />
            <rect x='2.7' y='2.6' className='st0' width='2.7' height='2.8' />
            <path className='st0' d='M6.9,9.1h4' />
            <path className='st0' d='M2.7,7.7l2.8,2.8' />
            <path className='st0' d='M5.5,7.7l-2.8,2.8' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const DiscursiveCourseIcon = () => (
  <svg
    version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
    viewBox='0 0 16.1 16.5' style={{ enableBackground: 'new 0 0 16.1 16.5', marginTop: '-8px', marginBottom: '-8px', width: '18px' }} xmlSpace='preserve'
  >
    <g>
      <polygon className='st0' points='3.8,12 4.2,15.5 7.4,14.2 13.7,4 10.1,1.8' />
      <polyline className='st0' points='12.4,10.5 15,6.3 13,5.1' />
      <path className='st0' d='M11.3,1.8l0.3-0.5c0.3-0.5,0.9-0.6,1.4-0.3l0,0c0.5,0.3,0.6,0.9,0.3,1.4l-0.3,0.5' />
      <line className='st0' x1='3.8' y1='12' x2='7.4' y2='14.2' />
    </g>
    <path className='st0' d='M0,2.6h7.4' />
    <path className='st0' d='M0,5.1h5.8' />
    <path className='st0' d='M0,7.6h4.3' />
  </svg>
)

const NotificationBell = (count) => (
  <svg
    version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsxxlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
    viewBox='0 0 800 800' style={{ width: '27px', position: 'absolute', top: '20%', left: '88%' }} xmlSpace='preserve'
  >

    <path className='st02' d='M72.8,683H800V117H72.8c-29,0-52.5,23.5-52.5,52.4v461C20.4,659.5,43.9,683,72.8,683z' />
    <g>
      <g>
        <path
          className='st12' d='M575.8,478.2c-18.7-13.8-32.3-33.1-39.2-54.8H511c4.9,19.2,14.1,37.1,26.8,52.4H252.7 c19.7-23.8,31-54,31-85.5v-57.1c0-46,28-85.6,67.8-102.7v-26.2c-53.7,18.3-92.4,69.2-92.4,128.9v57.1c0,34.5-16.6,67.4-44.4,87.8 l-5,3.7v80.8h131.8c0.5,29.2,24.4,52.8,53.8,52.8c29.3,0,53.2-23.6,53.8-52.8h131.8v-80.8L575.8,478.2z M395.2,590.9 c-15.8,0-28.8-12.6-29.2-28.3h58.5C424,578.3,411,590.9,395.2,590.9z M556.2,538.1h-322v-37.8h322V538.1z'
        />
      </g>
      <path
        className='st1' d='M620,190.2v145.4c0,6.6-2.6,12.9-7.3,17.6l-53.1,53.1c-4.7,4.7-11,7.3-17.6,7.3H396.7 c-19.5,0-35.3-15.8-35.3-35.3v-188c0-19.5,15.8-35.3,35.3-35.3h188C604.2,154.9,620,170.7,620,190.2z'
      />
    </g>
    <g id='Layer_2_00000054259869149639411280000006026376526101386898_' />
    <text x='49%' y='47%' fontSize='300' className='text'>{count.count}</text>
    <g id='Layer_3' />
  </svg>
)

const RegionalsIcon = () => (
  <svg
    version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsxxlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
    viewBox='0 0 16.9 17.8' style={{ width: '18px', enableBackground: 'new 0 0 16.9 17.8' }} xmlSpace='preserve'
  >

    <path
      className='stRegionals' d='M7.4,15.2l1.3-1.5l-1.1-1.8L6.3,8.5L4.2,6.9L2.8,7.8l-2-1.4l1.6-1.5V2.2l3.3-1.5l0.9,1.4l2.8-1.4l1,2.1l5.5,2l0.2,1.5L14.5,8l-1.1,4.1l-2.3,1v1.2l-1.9,2.7L7.4,15.2z'
    />
  </svg>
)

const BookAnalysisIcon = () => (
  <svg width='19' height='17' viewBox='0 0 19 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M7 6H1.875C1.875 6 1 5.39052 1 5C1 3.82843 1 3.17157 1 2C1 1.60948 1.875 1 1.875 1C7.00063 1 9.87437 1 15 1C15 1 13.6875 1.83333 13.6875 3.5C13.6875 5.16667 15 6 15 6H10' stroke='#A6A6BE' strokeWidth='1.5' strokeLinejoin='round' />
    <path d='M13.6875 13.5C13.6875 11.8333 15 11 15 11H1.875C1.875 11 1 11.6095 1 12V15C1 15.3905 1.875 16 1.875 16H15C15 16 13.6875 15.1667 13.6875 13.5Z' stroke='#A6A6BE' strokeWidth='1.5' strokeLinejoin='round' />
    <path d='M7 6C5.27261 6 4.875 6 4.875 6C4.875 6 4 6.60948 4 7C4 8.17157 4 8.82843 4 10C4 10.3905 4.875 11 4.875 11H18C18 11 16.6875 10.1667 16.6875 8.5C16.6875 6.83333 18 6 18 6C16.2624 6 12.6278 6 10 6' stroke='#A6A6BE' strokeWidth='1.5' strokeLinejoin='round' />
    <path d='M8.5 3L7 3L7 8L8.5 6.80952L10 8L10 3L8.5 3Z' stroke='#A6A6BE' strokeWidth='1.5' strokeLinejoin='round' />
  </svg>
)

export {
  HomeIcon,
  LessonsIcon,
  EnemIcon,
  StudyPlanIcon,
  QuestionsIcon,
  StatisticsIcon,
  SimulatedIcon,
  CommunityIcon,
  StudentIcon,
  QuestionsBookIcon,
  HelpCenterIcon,
  LogoutIcon,
  SupportIcon,
  LiveIcon,
  EssayIcon,
  QuestionCourseIcon,
  DiscursiveCourseIcon,
  NotificationBell,
  RegionalsIcon,
  BookAnalysisIcon,
}
