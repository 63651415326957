import { setContext } from '@apollo/client/link/context'
import { getToken } from 'resources'
import { isPublicOperation } from './commons'

const authLink = setContext(async ({ operationName }, { headers }) => {
  if (isPublicOperation(operationName)) return { headers }

  const { token } = await getToken()

  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
})

export { authLink }
