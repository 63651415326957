import React from 'react'
import t from 'prop-types'

import { useEssayFetcher } from './use-essay-fetcher'
import { Select } from 'ui'

export const EssaySelect = ({ disabled = false, ...props }) => {
  const { essaysDetailed, loading } = useEssayFetcher()

  return (
    <Select
      items={essaysDetailed}
      disabled={false}
      loading={loading}
      {...props}
    />
  )
}

EssaySelect.propTypes = {
  disabled: t.bool,
}
