import { keys, pipe, reduce } from 'ramda'

const replaceKey = values => (currentRoute, key) => currentRoute.replace(`:${key}`, values[key])

const withParams = (route, values) =>
  pipe(
    keys,
    reduce(replaceKey(values), route)
  )(values)

export { withParams }
