import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { media, colours, coloursDark } from 'ui'

const ClassClassification = ({ watched, classification }) => {
  const level = {
    0: 'Baixíssima',
    1: 'Baixa',
    2: 'Média',
    3: 'Alta',
  }

  return (
    ((classification != null) && (process.env.REACT_APP_CLASS_INDEX === '1')) &&
      <Wrapper>
        <Bars classification={classification}>
          <Bar watched={watched} />
          <Bar watched={watched} />
          <Bar watched={watched} />
        </Bars>
        <Content1 watched={watched}>
          Relevância Enem
        </Content1>
        <Text classification={classification}>
          {level[classification]}
        </Text>
      </Wrapper>
  )
}

ClassClassification.propTypes = {
  watched: t.bool,
  classification: t.number,
}

const Wrapper = styled.div`
    padding-right: 50px;
  ${media.lessThan('phone')`
    padding-top: 20px;
  `}
`

const Content1 = styled.div`
    padding-top: 8px;
    font-size: 11px;
    font-weight: 600;
    color: ${colours.veryLow} !important;

    ${({ watched }) => watched && `
    color: ${colours.watched} !important;
    `}

    ${media.greaterThan('phone')`
    display: grid;
    justify-content: end;
  `}

`

const Bars = styled.div`
    display: flex;
    justify-content: space-between;
    ${media.lessThan('phone')`
    justify-content: initial;
  `}
    div:nth-of-type(${({ classification }) => classification}){
        ${({ classification }) => classification === 1 && `
        background-color: ${colours.low} !important;
        opacity: 1 !important;
        `}
        ${({ classification }) => classification === 2 && `
        background-color: ${colours.average} !important;
        opacity: 1 !important;
        `}
        ${({ classification }) => classification === 3 && `
        background-color: ${colours.high} !important;
        opacity: 1 !important;
        `}
    }

`
const Bar = styled.div`
    width: 30px;
    height: 3px;
    margin-left: 10px;
    background-color: ${({ theme }) => theme.darkTheme ? coloursDark.backBar : colours.backBar} !important;
    border-radius: 3px;
    ${({ watched, theme }) => watched && `
    background-color: ${theme.darkTheme ? coloursDark.watched : colours.primary} !important;
    opacity: ${theme.darkTheme ? 1 : 0.2};
    `}
    ${media.lessThan('phone')`
    margin-left: 0px;
    margin-right: 10px;
  `}
`

const Text = styled.div`
    padding-top: 3px;
    font-size: 12px;
    font-weight: 900;
    color: ${colours.veryLow} !important;
    ${({ classification }) => classification === 1 && `
    color: ${colours.low} !important;
    `}
    ${({ classification }) => classification === 2 && `
    color: ${colours.average} !important;
    `}
    ${({ classification }) => classification === 3 && `
    color: ${colours.high} !important;
    `}

    ${media.greaterThan('phone')`
    display: grid;
    justify-content: end;
  `}
`

export { ClassClassification }
