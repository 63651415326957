import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { useWindowSize } from '@react-hook/window-size/throttled'
import ContentLoader from 'react-content-loader'
import { NavLink } from 'react-router-dom'

import { ContentBox, ErrorMessage, colours, radius, media } from 'ui'
import { errorMessages, useDisciplines, useStates } from 'resources'

export const Disciplines = ({ context, children }) => {
  const [width] = useWindowSize()
  const showCollapsedFilter = width < 900
  if (context === '/regionais') {
    return <RegionaisDisciplines context={context} children={children} showCollapsedFilter={showCollapsedFilter} />
  } else {
    return <MateriasDisciplines context={context} children={children} showCollapsedFilter={showCollapsedFilter} />
  }
}

const RegionaisDisciplines = ({ context, children, showCollapsedFilter }) => {
  const { states, loading, error } = useStates()

  if (error) {
    return (
      <ContentBox>
        <ErrorMessage message={errorMessages.DISCIPLINES_ERROR} hasRefresh />
      </ContentBox>
    )
  }

  return (
    <Wrapper>
      <Content>{children}</Content>
      <Nav>
        <StickyContentBox
          title='Estados'
          collapsable={showCollapsedFilter}
          collapsed={showCollapsedFilter}
        >
          {loading
            ? <ContentLoading />
            : states.map(({ name, id, slug }) => (
              <Item key={id} to={`${context}/${slug}`}>{name}</Item>
            ))}
        </StickyContentBox>
      </Nav>
    </Wrapper>
  )
}

const MateriasDisciplines = ({ context, children, showCollapsedFilter }) => {
  const { disciplines, loading, error } = useDisciplines()

  if (error) {
    return (
      <ContentBox>
        <ErrorMessage message={errorMessages.DISCIPLINES_ERROR} hasRefresh />
      </ContentBox>
    )
  }
  const disciplinesToFilter = ['Redação', 'Análise de Temas de Redação']
  const disciplinesWithoutEssay = disciplines.filter((discipline) => !disciplinesToFilter.includes(discipline.name))

  return (
    <Wrapper>
      <Content>{children}</Content>
      <Nav>
        <StickyContentBox
          title='Matérias'
          collapsable={showCollapsedFilter}
          collapsed={showCollapsedFilter}
        >
          {loading
            ? <ContentLoading />
            : disciplinesWithoutEssay.map(({ name, id, slug }) => (
              <Item key={id} to={`${context}/${slug}`}>{name}</Item>
            ))}
        </StickyContentBox>
      </Nav>
    </Wrapper>
  )
}

Disciplines.propTypes = {
  context: t.string.isRequired,
  children: t.oneOfType([
    t.arrayOf(t.node),
    t.node,
  ]).isRequired,
}

MateriasDisciplines.propTypes = {
  context: t.string.isRequired,
  children: t.node,
  showCollapsedFilter: t.bool,
}

RegionaisDisciplines.propTypes = {
  context: t.string.isRequired,
  children: t.node,
  showCollapsedFilter: t.bool,
}

const activeClassName = 'active-discipline'

const Item = styled(NavLink).attrs({ activeClassName })`
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .06em;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.darkPurpleGray};
  display: block;
  text-decoration: none;
  padding: 1rem 0;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  &.${activeClassName} {
    color: ${({ theme }) => theme.darkTheme ? theme.colours.primaryBlue : theme.colours.secondary};
  }
`

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-template-areas:
    'content nav';
  grid-gap: 3rem;

  ${media.lessThan('tablet')`
    grid-template-columns: 1fr;
    grid-template-areas:
      'nav'
      'content';
  `}
`

const Content = styled.div`
  grid-area: content;
`

const Nav = styled.div`
  grid-area: nav;
`

const StickyContentBox = styled(ContentBox)`
  ${media.greaterThan('tablet')`
    position: sticky;
    top: 2rem;
  `}
`

const ContentLoading = () => (
  <ContentLoader
    speed={2}
    width={100}
    height={185}
    backgroundColor={colours.contentLoadingColor3}
    foregroundColor={colours.contentLoadingColor4}
    style={{ width: '100%', height: '18.5rem' }}
  >
    <rect x='0' y='0' rx={radius.small} ry={radius.small} width='100%' height='3.5rem' />
    <rect x='0' y='50' rx={radius.small} ry={radius.small} width='100%' height='3.5rem' />
    <rect x='0' y='100' rx={radius.small} ry={radius.small} width='100%' height='3.5rem' />
    <rect x='0' y='150' rx={radius.small} ry={radius.small} width='100%' height='3.5rem' />
  </ContentLoader>
)
