import { gql } from '@apollo/client'

export const DISCURSIVE_COURSE_DISCIPLINES_QUERY = gql`
  query {
    listDissertiveQuestionsDisciplines {
      id
      name
      slug
      order
      isPublished
      }
  }
`
