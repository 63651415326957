import { useQuery, gql } from '@apollo/client'
import { pipe, defaultTo, path, map } from 'ramda'

import { renameKeys } from 'resources/composable'

export const useSupportDisciplineSelect = () => {
  const { data, loading } = useQuery(SUPPORT_OPTIONS_QUERY, {
    fetchPolicy: 'no-cache',
  })

  return {
    supportDisciplines: toOptions(data),
    loading,
  }
}

const toOptions = pipe(
  path(['supportOptions', 'disciplines']),
  defaultTo([]),
  map(renameKeys({
    id: 'value',
    name: 'label',
  }))
)

const SUPPORT_OPTIONS_QUERY = gql`
  query GetSupportOptions {
    supportOptions {
      disciplines {
        id
        name
      }
    }
  }
`
