import styled from 'styled-components'
import { theme, media } from 'ui'

export const Dimmer = styled.div`
  z-index: ${theme.zIndex.aboveAll};

  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-items: center;

  overflow: hidden;

  position: fixed;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);

  backdrop-filter: blur(2px);
`

export const Content = styled.div`
  background-color: #fff;
  border-radius: 20px;
  width: 500px;
  min-height: 230px;
  margin: 20px;
  text-align: center;
  padding: 2rem;

  ${media.greaterThan('smallPhone')`
    padding: 2.5rem;
  `}
`

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  padding: 0.5rem;
  min-height: 50px;

  color: gray;
`

export const HeaderIcon = styled.div`
  cursor: pointer;
`
