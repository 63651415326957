import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import styledBy from 'styled-by'
import { rgba } from 'polished'

import RefreshIcon from './refresh-icon.svg'

export const ErrorMessage = ({ hasRefresh, hasBackground, message, textSize = 'small' }) => {
  const refreshPage = () => window.location.reload(true)

  return (
    <Wrapper hasBackground={hasBackground}>
      <Text size={textSize}>{message}</Text>
      {hasRefresh && <RefreshButton onClick={refreshPage}>Tentar novamente</RefreshButton>}
    </Wrapper>
  )
}

ErrorMessage.propTypes = {
  hasRefresh: t.bool,
  hasBackground: t.bool,
  message: t.string,
  textSize: t.oneOf(['small', 'medium', 'large']),
}

const Wrapper = styled.div`
  min-height: 10rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ hasBackground, theme }) => hasBackground && `
    padding: 3.5rem 1.5rem;
    background-color: ${rgba(theme.colours.purpleGray, 0.3)};
    border-radius: ${theme.radius.large};
    border: 1px solid ${theme.colours.purpleGray};

    & > ${Text} {
      color: ${theme.colours.secondary};
    }
  `}
`

const Text = styled.p`
  padding-bottom: 1.6rem;

  ${styledBy('size', {
    small: () => 'font-size: 1.2rem;',
    medium: () => 'font-size: 1.4rem;',
    large: () => 'font-size: 1.6rem;',
  })};
`

const RefreshButton = styled.button`
  cursor: pointer;
  appearance: none;
  border: 0;
  border-radius: ${({ theme }) => theme.radius.medium};
  padding: .8rem 1.92rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colours.background};
  background-color: ${({ theme }) => theme.colours.secondary};
  font-weight: 700;
  white-space: nowrap;

  &:before {
    content: '';
    background: url(${RefreshIcon}) no-repeat 100%/auto;
    display: inline-block;
    width: 1.7rem;
    height: 1.7rem;
    vertical-align: middle;
    margin-right: 1rem;
    margin-top: -.3rem;
  }
`
