import { gql } from '@apollo/client'

export const STUDY_PLAN_DISCIPLINES_QUERY = gql`
  query GetStudyPlanDisciplines($slug: String!) {
    studyPlanDisciplines(slug: $slug) {
      id
      name
      slug
    }
  }
`
