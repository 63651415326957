import { useCallback, useState } from 'react'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { getNodes, withParams } from 'resources'
import { pipe, map, prepend } from 'ramda'
import { useHistory } from 'react-router'
import { SINGLE_SIMULATED } from 'routes'

export const useSimulatedNew = () => {
  const [showNewSimulatedModal, setShowNewSimulatedModal] = useState(false)
  const [simulatedTypePlaylist, setSimulatedTypePlaylist] = useState(false)
  const [simulatedTypeEnem, setSimulatedTypeEnem] = useState(false)
  const [discipline, setDiscipline] = useState()
  const [disciplineEnem, setDisciplineEnem] = useState()
  const [allSubjects, setAllSubjects] = useState()
  const [subject, setSubject] = useState()
  const [status, setStatus] = useState(false)
  const history = useHistory()
  const [createUserSimulated, { loading: isStarting }] = useMutation(CREATE_USER_SIMULATED)
  const [errors, setErrors] = useState({})

  const openModalNewSimulated = () => {
    setShowNewSimulatedModal(true)
  }
  const {
    handleSubmit,
  } = useForm()
  const onSubmit = async () => {
    if (!simulatedTypePlaylist && !simulatedTypeEnem) {
      setErrors({ simulateType: { type: 'required' } })
      return
    }
    if (simulatedTypePlaylist) {
      if (!discipline) {
        setErrors({ discipline: { type: 'required' } })
        return
      }
    }
    if (simulatedTypeEnem) {
      if (!disciplineEnem) {
        setErrors({ discipline: { type: 'required' } })
        return
      }
    }
    if (simulatedTypePlaylist) {
      if (!subject) {
        setErrors({ subject: { type: 'required' } })
        return
      }
    }
    try {
      await createUserSimulated({
        variables: {
          input: {
            code: '01',
            title: simulatedTypePlaylist ? 'PLAYLIST' : 'ENEM',
            timeInMinutes: 50,
            isPublished: true,
            disciplines: discipline || disciplineEnem,
            disciplineSubject: subject,
            isHidden: false,
          },
        },
      }).then(res => {
        history.push(withParams(SINGLE_SIMULATED, {
          slug: res.data.createUserSimulated.meta.slug,
        }))
        history.push(`/simulado/${res.data.createUserSimulated.meta.slug}`
        )
      }
      ).catch(() => {
        setStatus(true)
      })
    } catch (err) {
      setStatus(true)
    }
  }

  const onClose = () => {
    setShowNewSimulatedModal(false)
    setSimulatedTypeEnem(false)
    setSimulatedTypePlaylist(false)
  }

  const onChangeSimulated = formField => ({ currentTarget }) => {
    const [, value] = formField.cata({
      Toggle: () => [currentTarget.name, currentTarget.checked],
      Select: () => [currentTarget.name, currentTarget.value],
    })
    if (value === 'PLAYLIST') {
      setDisciplineEnem(null)
      setSimulatedTypePlaylist(true)
      setSimulatedTypeEnem(false)
    }

    if (value === 'ENEM') {
      setDiscipline(null)
      setSimulatedTypePlaylist(false)
      setSimulatedTypeEnem(true)
    }

    setErrors({})
    setAllSubjects()
  }

  const handleSubjects = useCallback(async subjects => {
    setAllSubjects(toOptions(subjects))
  }, [])

  const [getSubjects] = useLazyQuery(SUBJECTS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        disciplineSlug: discipline,
        onlyParents: true,
      },
    },
    onCompleted: handleSubjects,
  })

  const onChangeDiscipline = (e) => {
    setDiscipline(e.target.value)
    getSubjects({ discipline })
    setStatus(false)
    setErrors({})
  }
  const onChangeDisciplineEnem = (e) => {
    setDisciplineEnem(e.target.value)
    setStatus(false)
    setErrors({})
  }
  const onChangeSubject = (e) => {
    setSubject(e.target.value)
    setErrors({})
  }

  const createNewSimulatedFromGenericList = async (row) => {
    try {
      await createUserSimulated({
        variables: {
          input: {
            code: '01',
            title: 'PLAYLIST',
            timeInMinutes: 50,
            isPublished: true,
            disciplines: row.discipline[0].slug,
            disciplineSubject: row.subject[0].slug,
            isHidden: false,
          },
        },
      }).then(res => {
        history.push(withParams(SINGLE_SIMULATED, {
          slug: res.data.createUserSimulated.meta.slug,
        }))
        history.push(`/simulado/${res.data.createUserSimulated.meta.slug}`
        )
      }
      ).catch((err) => {
        console.error(err)
      })
    } catch (err) {
      console.error(err)
    }
  }

  return {
    showNewSimulatedModal,
    openModalNewSimulated,
    onClose,
    onChangeSimulated,
    onChangeDisciplineEnem,
    simulatedTypePlaylist,
    simulatedTypeEnem,
    onChangeDiscipline,
    discipline,
    onChangeSubject,
    onSubmit,
    handleSubmit,
    allSubjects,
    status,
    errors,
    createNewSimulatedFromGenericList,
    isStarting,
  }
}

export const CREATE_USER_SIMULATED = gql`
mutation ($input: SimulatedInput!) {
  createUserSimulated (input: $input) {
    meta{
    slug
    }
  }
}
`
const SUBJECTS_QUERY = gql`
  query GetSubjects($filter:FilterSubjectsInput!){
    subjects (filter: $filter){
     nodes {
       id
       slug
       name
      }
    }
  }
`

const toOption = ({
  slug: value,
  name: label,
  id,
}) => ({ label, value, id })

const toOptions = pipe(
  getNodes('subjects'),
  map(toOption),
  prepend({
    value: '',
    label: 'Selecione um assunto',
  })
)
