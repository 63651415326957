import React, { createContext, useContext, useState, useCallback } from 'react'
import t from 'prop-types'
import { useLiveStatusObserver, useDoIHaveNewMesssage, useUpdateDoIHaveNewMesssage } from 'resources'

const Context = createContext({ livesWithStatus: [], liveClassname: 'NOT_TODAY', liveStreamNow: {} })

const NotificationStatusProvider = ({ children }) => {
  const [live, setLive] = useState({})
  const [notifications, setNotifications] = useState({})

  const onCompleted = useCallback((data) => {
    if (data?.doIHaveNewMessage) {
      // eslint-disable-next-line array-callback-return
      data.doIHaveNewMessage.map((item) => {
        if (item.message === 'LIVE') {
          setLive(item.payload)
        }
        setNotifications(item.message === 'COMMUNITY_NOTIFICATION' ? item.payload : 0)
      })
    }
  }, [setLive, setNotifications])

  useDoIHaveNewMesssage({
    onCompleted,
  })

  const liveWithStatus = useLiveStatusObserver(live)
  const hasLiveToday = ((liveWithStatus.status === 'IS_TODAY') || (liveWithStatus.status === 'IS_NOW'))
  const hasLiveOnGoing = liveWithStatus.status === 'IS_NOW'
  const liveClassname = !hasLiveToday ? 'NOT_TODAY' : (
    hasLiveOnGoing ? 'IS_NOW' : 'IS_TODAY'
  )
  return (
    <Context.Provider value={{ liveWithStatus, liveClassname, notifications, setNotifications, setLive }}>
      {children}
    </Context.Provider>
  )
}

const useNotificationStatus = () => {
  const context = useContext(Context)

  return context
}

export const useUpdateNotifications = () => {
  const { setNotifications, setLive } = useNotificationStatus()

  const onCompleted = useCallback((data) => {
    if (data?.doIHaveNewMessage) {
      // eslint-disable-next-line array-callback-return
      data.doIHaveNewMessage.map((item) => {
        if (item.message === 'LIVE') {
          setLive(item.payload)
        }
        setNotifications(item.message === 'COMMUNITY_NOTIFICATION' ? item.payload : 0)
      })
    }
  }, [setLive, setNotifications])

  useUpdateDoIHaveNewMesssage({
    onCompleted,
  })
}

NotificationStatusProvider.propTypes = {
  children: t.node.isRequired,
}

export { NotificationStatusProvider, useNotificationStatus }
