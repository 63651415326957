import styled, { css } from 'styled-components'
import { ContentBox, Button, Link, media } from 'ui'

export const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: auto auto;
  grid-gap: 2rem;
  grid-template-areas:
    'questions-list info'
    'question question';

  ${media.lessThan('phone')`
    grid-template-columns: 1fr;
    grid-template-areas:
      'info'
      'questions-list'
      'question';
  `}

  .content-box {
    height: 100%;
  }
  
  .simulatedButton {
    float: right;
    margin-top: -60px;
  }
`

export const QuestionsList = styled.div`
  grid-area: questions-list;
`

export const Info = styled.div`
  grid-area: info;
`

export const QuestionWrapper = styled.div`
  grid-area: question;
`

export const ListOfQuestions = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
`

export const QuestionItemContainer = styled.button`
  border: 0;
  background: 0;
  box-shadow: none;
  padding: 0;
  margin: 0;
  outline: 0;
`

export const QuestionItem = styled.li`
  display: inline-block;
  width: 7rem;
  padding: .5em;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: ${({ theme }) => theme.radius.small};
  border: 1px solid ${({ theme }) => theme.colours.gray3};
  color: ${({ theme }) => theme.darkTheme ? theme.colours.textSecondary : theme.colours.text};
  margin-bottom: .8rem;
  margin-right: .8rem;
  cursor: ${props => props.onClick ? 'pointer;' : 'default;'};



  ${props => props.answered && css`
    border-color: ${({ theme }) => theme.colours.third};
    background: ${({ theme }) => theme.colours.third};
    color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.background};
  `};

  ${props => props.selected && !props.answered && css`
    border-color: ${({ theme }) => theme.colours.third};
    color: ${({ theme }) => theme.colours.primary};
  `};
`

export const InfoBox = styled(ContentBox)`
  padding-top: 0;
  text-align: center;
`

export const TimeLeftWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  flex-direction: column;
`

export const SeeTime = styled.div`
  color: ${({ theme }) => theme.colours.primary};
  width: 100%;
  padding: .4rem 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: .8rem;

  svg {
    transition: fill .4s ease;
  }
`

export const SeeTimeText = styled.span`
  font-size: 1.1rem;
  flex-basis: 12rem;
  padding-left: 1rem;
  font-weight: 600;
  color: ${({ active, theme }) => active ? theme.colours.text : theme.colours.gray3};
  transition: color .2s ease;
  text-align: left;
`

export const TimeLeft = styled.span`
  position: relative;
  display: block;
  margin: 0;
  color: ${({ theme }) => theme.colours.primary};
  padding: 0;
  font-size: 3rem;
  font-weight: bold;
  padding-left: .1em;
  text-align: center;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: ${props => props.active ? '100%' : '0'};
    background: ${({ theme }) => theme.darkTheme ? theme.colours.background2 : theme.colours.gray4};
    border-radius: ${({ theme }) => theme.radius.small};
    transition: width .4s ease;
  }
`

export const ButtonSend = styled(Button)`
  padding: 1em 0;
  font-size: 1.2rem;
   margin-top: 1rem;
`

export const LinkDownloadPDF = styled(Link.External)`
  margin-top: 1.5rem;
  font-size: 1.1rem;
  padding-left: 1.6rem;
`

export const Text = styled.p`
  margin: 0;
  font-size: 1.4rem;
  padding-bottom: 1.5rem;
  font-weight: 600;
`
