import { useQuery } from '@apollo/client'
import { safelyGetFields, formatSecondsToMinutes } from 'resources'
import { pipe, over, lensProp, evolve, path, prop } from 'ramda'
import { PLAY } from 'routes'

import { LATEST_CLASSES_QUERY } from './graphql'

export const useLatestClasses = () => {
  const { data, loading, error } = useQuery(LATEST_CLASSES_QUERY)

  const latestClasses = safelyGetFields(['stats', 'latestClasses'], data)
    .map(pipe(
      over(
        lensProp('mainVideo'),
        evolve({ timeInSeconds: formatSecondsToMinutes })
      ),
      buildClassUrl
    ))

  return {
    latestClasses,
    loading,
    error,
  }
}

const buildUrl = ({ classGroupSlug, classSlug, discipline, state }) => {
  const path = PLAY
    .replace(/:parentSlug/, classGroupSlug)
    .replace(/:discipline/, discipline)
    .replace(/:type/, state ? '2' : '1')

  return `${path}?aula=${classSlug}`
}

const buildClassUrl = item => {
  const classGroupSlug = path(['classGroup', 'meta', 'slug'], item)
  const classSlug = prop('slug', item)
  const discipline = item.classGroup.meta.state ? path(['classGroup', 'meta', 'state', 'slug'], item) : path(['discipline', 'slug'], item)
  const state = path(['classGroup', 'meta', 'state', 'slug'], item)
  return {
    ...item,
    url: buildUrl({ classGroupSlug, classSlug, discipline, state }),
  }
}
