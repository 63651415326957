import React from 'react'
import t from 'prop-types'

import { useSupportCategorySelect } from './use-support-category-select'
import { Select } from 'ui/form/form'

export const SupportCategorySelect = ({ disabled, ...props }) => {
  const { supportCategories, loading } = useSupportCategorySelect()

  return <Select {...props} items={supportCategories} loading={loading} disabled={loading || disabled} />
}

SupportCategorySelect.propTypes = {
  disabled: t.bool,
}
