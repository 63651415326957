import { useQuery } from '@apollo/client'
import { getNodes } from 'resources'

import { DISCIPLINES_QUERY } from './graphql'

export const useDisciplines = () => {
  const { data, loading, error } = useQuery(DISCIPLINES_QUERY, {
    fetchPolicy: 'no-cache',
  })

  const disciplines = getNodes('disciplines', data).map(toDiscipline)

  return {
    disciplines,
    loading,
    error,
  }
}

const toDiscipline = ({ id, name, slug }) => ({
  id,
  name,
  slug,
})
