import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

const useLiveStatusObserver = (live = {}) => {
  const [liveWithStatus, setLiveWithStatus] = useState([])
  const nowFormatted = dayjs().format()

  useEffect(() => {
    const getStatus = (live) => {
      let status = 'NOT_TODAY'

      if (!live.liveDate) return status

      dayjs.extend(isBetween)

      const isToday = dayjs().isBetween(live.liveDate, live.endDate, 'day')
      const isNow = dayjs().isBetween(live.liveDate, live.endDate)

      if (isToday) status = 'IS_TODAY'
      if (isNow) status = 'IS_NOW'

      return status
    }

    const updateStatus = () => {
      const updatedLives = Object.assign({}, live, { status: getStatus(live) })
      setLiveWithStatus(updatedLives)
    }

    const statusUpdaterInterval = window.setInterval(updateStatus, 5000)

    return () => window.clearInterval(statusUpdaterInterval)
  }, [live, nowFormatted])

  return liveWithStatus
}

export { useLiveStatusObserver }
