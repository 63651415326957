import { gql } from '@apollo/client'

export const LATEST_CLASSES_QUERY = gql`
  query GetLatestClasses {
    stats {
      latestClasses {
        id
        title
        slug
        discipline {
          slug
        }
        classGroup {
          meta {
            id
            slug
            discipline {
              id
              slug
              name
            }
            state {
              id
              name
              slug
            }
          }
        }
        mainVideo {
          id
          title
          timeInSeconds
          thumbnail
        }
      }
    }
  }
`
