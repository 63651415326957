import React from 'react'
import t from 'prop-types'
import { FormControl, FormLabel, FormField, FormError } from 'ui'

const InputEmail = ({
  label,
  name,
  id,
  placeholder = 'E-mail',
  error,
  inputRef,
  disabled,
  ...rest
}) => {
  return (
    <FormControl>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <FormField
        type='email'
        name={name}
        id={id}
        placeholder={placeholder}
        ref={inputRef}
        error={error}
        disabled={disabled}
        {...rest}
      />
      {error && <FormError>E-mail inválido</FormError>}
    </FormControl>
  )
}

InputEmail.propTypes = {
  label: t.string.isRequired,
  name: t.string.isRequired,
  id: t.string.isRequired,
  placeholder: t.string,
  error: t.object,
  required: t.bool,
  disabled: t.bool,
  inputRef: t.func.isRequired,
}

export { InputEmail }
