import { useState, useEffect, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useMe } from 'resources'

import { MARK_NOTIFICATION_AS_READ_MUTATION } from './graphql'

export const useNotificationCollapse = (handleMarkAsRead) => {
  const [isOpen, setOpen] = useState(false)
  const [notificationData, setNotificationData] = useState({})
  const { id: userId } = useMe()

  const [markNotificationAsRead] = useMutation(MARK_NOTIFICATION_AS_READ_MUTATION)

  const handleOpen = useCallback((id, read) => {
    setOpen(isOpen => !isOpen)
    setNotificationData({ id, read })
  }, [])

  const readNotification = useCallback(async () => {
    await markNotificationAsRead({
      variables: {
        id: notificationData.id,
      },
    })

    handleMarkAsRead()
  }, [handleMarkAsRead, markNotificationAsRead, notificationData.id])

  useEffect(() => {
    if (isOpen && !notificationData.read) {
      readNotification()
    }
  }, [notificationData, markNotificationAsRead, userId, isOpen, handleMarkAsRead, readNotification])

  return {
    isOpen,
    handleOpen,
  }
}
