import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { safelyGetFields } from 'resources'

import { STUDY_PLAN_DISCIPLINES_QUERY } from './graphql'

export const useStudyPlanDisciplines = () => {
  const { slug } = useParams()

  const { data, loading, error } = useQuery(STUDY_PLAN_DISCIPLINES_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      slug,
    },
  })

  const disciplines = safelyGetFields(['studyPlanDisciplines'], data).map(toDiscipline)

  return {
    disciplines,
    loading,
    error,
  }
}

const toDiscipline = ({ id, name, slug }) => ({
  id,
  name,
  slug,
})
