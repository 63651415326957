import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'

const PlayerYoutube = ({ title, linkYoutube }) => {
  return (
    <Wrapper>
      <PlayerWrapper>
        <iframe
          allow='accelerometer; encrypted-media; gyroscope; picture-in-picture'
          src={`https://www.youtube.com/embed/${linkYoutube}?autoplay=0&enable_js=1`}
          allowFullScreen='allowfullscreen'
          scrolling='no'
          frameBorder='0'
          title={title}
        />
      </PlayerWrapper>
    </Wrapper>
  )
}

PlayerYoutube.propTypes = {
  title: t.string,
  linkYoutube: t.string,
}

const Wrapper = styled.div`
  position: relative;
  background: ${({ theme }) => rgba(theme.colours.gray4, 0.6)};
  width: 100%;
  height: auto;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.radius.small};
`

const PlayerWrapper = styled.div`
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0;
    left: 0;
    top: 0;
  }
`

export { PlayerYoutube }
