import React from 'react'
import t from 'prop-types'
import { colours } from 'ui'
import styled from 'styled-components'
import { PieChart, Pie, Cell, Label } from 'recharts'

const PlaylistChart = ({ data }) => {
  const dataPie = [
    { name: 'Realizado', value: data.resolved, color: colours.primary },
    { name: 'Restante', value: (data.total - data.resolved), color: 'transparent' },
  ]

  const renderLabel = ({ viewBox }) => {
    const { cx, cy } = viewBox
    return (
      <foreignObject x={cx - 70} y={cy - 70} width='140' height='140'>
        <CenterBackground>
          <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
            <path d='M20.494 7.96763L10.954 0.967629C10.2089 0.422014 9.32722 0.093423 8.40677 0.018292C7.48631 -0.056839 6.56302 0.124425 5.7393 0.541986C4.91557 0.959547 4.22358 1.59709 3.74006 2.38391C3.25654 3.17074 3.00039 4.07611 3 4.99963V18.9996C3.00016 19.9236 3.25633 20.8294 3.74007 21.6166C4.22382 22.4037 4.91621 23.0415 5.74042 23.459C6.56463 23.8766 7.48841 24.0576 8.40925 23.982C9.33008 23.9063 10.212 23.577 10.957 23.0306L20.497 16.0306C21.1303 15.5662 21.6452 14.9592 22.0002 14.2587C22.3552 13.5582 22.5401 12.7839 22.5401 11.9986C22.5401 11.2133 22.3552 10.4391 22.0002 9.73857C21.6452 9.03807 21.1303 8.43103 20.497 7.96663L20.494 7.96763ZM19.31 14.4176L9.77 21.4176C9.32302 21.7443 8.79437 21.9408 8.24257 21.9856C7.69078 22.0304 7.13736 21.9216 6.64357 21.6713C6.14978 21.4209 5.73488 21.0389 5.4448 20.5674C5.15472 20.0958 5.00078 19.5532 5 18.9996V4.99963C4.99445 4.44498 5.1455 3.90001 5.43578 3.42735C5.72606 2.95469 6.1438 2.57352 6.641 2.32763C7.06389 2.11252 7.53154 2.00015 8.006 1.99963C8.64189 2.00206 9.26022 2.20852 9.77 2.58863L19.31 9.58863C19.6895 9.86732 19.9981 10.2314 20.2107 10.6515C20.4234 11.0716 20.5343 11.5358 20.5343 12.0066C20.5343 12.4775 20.4234 12.9417 20.2107 13.3618C19.9981 13.7818 19.6895 14.1459 19.31 14.4246V14.4176Z' fill='#0049FF' />
          </svg>
        </CenterBackground>
      </foreignObject>
    )
  }

  renderLabel.propTypes = {
    viewBox: t.shape({
      cx: t.number.isRequired,
      cy: t.number.isRequired,
    }).isRequired,
  }

  return (
    <>
      <PieChart width={260} height={200}>
        <Pie
          dataKey='value'
          data={dataPie}
          cx='50%'
          cy='50%'
          innerRadius={70}
          outerRadius={90}
          startAngle={90}
          endAngle={-270}
          paddingAngle={0}
          stroke='none'
        >
          <Label content={renderLabel} position='center' />
          {dataPie.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </>
  )
}

PlaylistChart.propTypes = {
  data: t.object.isRequired,
}

export { PlaylistChart }

const CenterBackground = styled.div`
  background-color: #E0EDFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
`
