import React from 'react'
import t from 'prop-types'

import {
  Wrapper,
  Box,
  Poster,
  Content,
  Title,
  Weeks,
  ButtonDetails,
  Row,
  Item,
  Label,
  Value,
  Description,
  FutureDate,
  ReleaseDate,
} from './components'

const StudyPlanList = ({ items = [] }) => (
  <Wrapper>
    {items.map(({ id, title, poster, description, url, weeks, releaseDate, audience, endsOn, startable }) => {
      return (
        <Box key={id} futureDate={!startable}>
          {!startable && (<FutureDate futureDate={!startable} to={url} variant='ghost'><Value>INÍCIO: </Value><ReleaseDate>{releaseDate}</ReleaseDate></FutureDate>)}
          <Poster futureDate={!startable}>
            <img src={poster} alt={title} />
          </Poster>
          <Content futureDate={!startable}>
            <div>
              <Title>{title}</Title>
              <Weeks>{weeks} semanas</Weeks>
              <Row>
                <Item>
                  <Label>Início</Label>
                  <Value>{releaseDate}</Value>
                </Item>
                <Item>
                  <Label>Término</Label>
                  <Value>{endsOn}</Value>
                </Item>
              </Row>
              <Description>{description}</Description>
            </div>
            <ButtonDetails to={url} variant='ghost'>Mais detalhes</ButtonDetails>
          </Content>
        </Box>
      )
    })}
  </Wrapper>
)

StudyPlanList.propTypes = {
  items: t.arrayOf(t.shape({
    id: t.string,
    title: t.string,
    poster: t.string,
    description: t.string,
    url: t.string,
    weeks: t.number,
  })),
}

export { StudyPlanList }
