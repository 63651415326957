import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'

const ShirtIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width='25px' height='25px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px' y='0px' viewBox='0 0 32 32' style={{ enableBackground: 'new 0 0 32 32' }} xmlSpace='preserve'
    >
      <path
        fill='currentColor' d='M29.9,12.5l-4-7c-0.1-0.2-0.3-0.3-0.5-0.4l-5-2c-0.3-0.1-0.7-0.1-0.9,0.1S19,3.7,19,4c0,1.7-1.3,3-3,3s-3-1.3-3-3
 c0-0.3-0.2-0.6-0.4-0.8c-0.3-0.2-0.6-0.2-0.9-0.1l-5,2C6.4,5.2,6.2,5.3,6.1,5.5l-4,7C2,12.7,2,13,2,13.3c0.1,0.3,0.3,0.5,0.5,0.6
 l3.7,1.9l0,0c0.1,0.1,0.3,0.1,0.4,0.1c0.4,0,0.7-0.2,0.9-0.6l0,0L7.7,15C7.8,14.9,8,14.9,8,15.1l0,1.5l0,0v8c0,1.3,0.7,2.4,1.8,2.9
 c2.4,1.2,4.7,1.4,6.2,1.4c2.1,0,4.2-0.5,6.2-1.4c1.1-0.5,1.8-1.7,1.8-2.9v-8l0,0l0-1.6c0-0.1,0.2-0.2,0.3-0.1l0.2,0.3l0,0
 c0.2,0.3,0.5,0.6,0.9,0.6c0.1,0,0.3,0,0.4-0.1l0,0l3.7-1.9c0.2-0.1,0.4-0.3,0.5-0.6C30,13,30,12.7,29.9,12.5z'
      />
    </svg>
  )
}

const Icon = styled(ShirtIcon)`
  fill: ${({ theme }) => theme.darkTheme ? '#CACACA' : '#000'};
`

ShirtIcon.propTypes = { className: t.string }
export { Icon }
