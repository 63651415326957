import { useState, useEffect, useMemo } from 'react'
import { gql, useMutation } from '@apollo/client'

const TOGGLE_BETA_VERSION_MUTATION = gql`
  mutation ToggleBetaVersion($betaVersion: Boolean!) {
    toggleBetaVersion(betaVersion: $betaVersion)
  }
`

const useBetaReleaseNotice = () => {
  const betaVersion = useMemo(() => JSON.parse(localStorage.getItem('betaVersion')), [])
  const [isVisible, setIsVisible] = useState(false)
  const [toggleBetaVersion] = useMutation(TOGGLE_BETA_VERSION_MUTATION)

  useEffect(() => {
    setTimeout(() => setIsVisible(true), 100)
  }, [])

  const handleClose = () => {
    setIsVisible(false)
  }

  const changeVersion = async () => {
    const newBetaVersion = !betaVersion
    localStorage.setItem('betaVersion', newBetaVersion)

    try {
      await toggleBetaVersion({ variables: { betaVersion: newBetaVersion } })
      window.location.reload()
    } catch (error) {
      console.error('Error toggling beta version:', error)
    }
  }

  return {
    betaVersion,
    isVisible,
    handleClose,
    changeVersion,
  }
}

export { useBetaReleaseNotice }
