import { gql } from '@apollo/client'
import { map, pipe, prepend } from 'ramda'
import { usePaginatedQuery } from 'resources'

const useDisciplinesFetcher = ({ valueName, emptyLabel, withQuestions }) => {
  const filter = {}
  if (withQuestions) {
    filter.withQuestions = true
  }
  const { disciplines, loading } = usePaginatedQuery(DISCIPLINES_QUERY, {
    nodeName: 'disciplines',
    variables: { filter },
  })

  return {
    disciplines: transformOptions(emptyLabel, valueName, disciplines),
    loading,
  }
}

const DISCIPLINES_QUERY = gql`
  query GetDisciplines ($filter: FilterDisciplinesInput){
    disciplines(filter: $filter) {
      nodes {
        slug
        id
        name
      }
    }
  }
`
const toOption = valueName => ({ name, ...data }) => ({
  label: name,
  value: data[valueName],
  id: data.id,
})

const transformOptions = (emptyLabel, valueName, items) => pipe(
  map(toOption(valueName)),
  prepend({
    label: emptyLabel,
    value: '',
    id: '',
  })
)(items)

export { useDisciplinesFetcher }
