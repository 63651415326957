import { useQuery } from '@apollo/client'

import { ESSAY_CREDITS } from './graphql'

export const useMyEssaysCredits = () => {
  const { data: essayCredits, loading: essayLoading, refetch } = useQuery(ESSAY_CREDITS)
  return {
    essayCredits: !essayLoading && essayCredits?.essayCredits,
    refetch,
  }
}
