import React, { useState, useCallback } from 'react'
import t from 'prop-types'
import styled, { css } from 'styled-components'
import { rgba, darken } from 'polished'
import { media, FreePlanMessageModal, Button, IconVideo, PlayerModal, showCheckedIcon } from 'ui'
import { PlayIcon, QuestionIcon, SimulatedIcon, WatchedIcon, GrapphicSummariesIcon } from './generic-icons'
import { Link } from 'react-router-dom'
import { resourcesTypes, useMe } from 'resources'
import { useSimulatedNew } from '../../pages/simulated/hooks/use-simulated-new/use-simulated-new'
import { ClassClassification } from 'ui/class-classification/class-classification'
import { useWindowWidth } from '@react-hook/window-size'
import { ButtonLabel } from 'ui/question/components'
import CHECKED_ICON from 'ui/form/radio/checked-icon.svg'

const GenericList = ({ items, live = false, setKeepOpen, markAsWatched }) => {
  const { isFree } = useMe()
  const [openFreePlanModal, setOpenFreePlanModal] = useState(false)
  const [showResolution, setShowResolution] = useState(false)
  const [video, setVideo] = useState()
  const { createNewSimulatedFromGenericList } = useSimulatedNew()
  const width = useWindowWidth()
  const handleCloseFreePlanModal = () => {
    setOpenFreePlanModal(false)
  }

  const handleClick = (type, evt) => () => {
    const linkDisabled = isFree && type !== resourcesTypes.CLASS

    if (linkDisabled) {
      evt.preventDefault()

      setOpenFreePlanModal(true)
    }
  }

  const toggleResolutionModal = useCallback(() => setShowResolution(!showResolution), [showResolution])

  const openVideoResolution = useCallback((video) => {
    setVideo(video)
    toggleResolutionModal()
  }, [toggleResolutionModal])
  return (
    <Wrapper>
      {items.map((row, i) => {
        return (
          row.type !== resourcesTypes.SIMULATED ? (
            <Item
              key={i} to={row.url} watched={row.watched} active={row.active} type={row.type} live={live}
              className={isFree && row.type !== resourcesTypes.CLASS ? 'disabled' : null}
              onClick={(e) => {
                if (row.type === resourcesTypes.VIDEO_GROUP) {
                  e.preventDefault()
                }
                if (row.type === resourcesTypes.PDFS && !isFree) {
                  window.open(row.url, '_blank', 'noopener noreferrer')
                  e.preventDefault()
                } else {
                  handleClick(row.type, e)()
                }
              }}
            >
              {row.watched && <WatchedIconWrapper><WatchedIcon /></WatchedIconWrapper>}
              <Content1>
                <Label watched={row.watched} type={row.type} pdfType={row.pdfType} live={live}>
                  <Icon type={row.type}>
                    {row.type === resourcesTypes.CLASS && <PlayIcon />}
                    {row.type === resourcesTypes.SUBJECT && <QuestionIcon />}
                    {row.type === resourcesTypes.SIMULATED && <SimulatedIcon />}
                    {row.type === resourcesTypes.PDFS ? row.pdfType === 'GRAPHIC_SUMMARIES' ? <GrapphicSummariesIcon /> : <QuestionIcon /> : null}
                  </Icon>
                  <LabelText live={live} type={row.type}>
                    {renderLabel(row, live)}
                  </LabelText>
                </Label>
                {(width >= 563 && row.type === resourcesTypes.CLASS) && <ClassClassification watched={row.watched} classification={row.enemRelevance} />}
              </Content1>
              <Content type={row.type}>
                <ContentTitle type={row.type}>
                  {!live && row.title}
                  <TagGroup>
                    {row.tags?.map(({ id, title, color }) => (
                      <Tag key={id}>{title}</Tag>
                    ))}
                    {row.videos && (
                      <>

                        <VideoWrapper>
                          {row.videos.map(({ id, title }, i) => {
                            return (
                              <ButtonR
                                key={i}
                                variant='success'
                                onClick={() => {
                                  openVideoResolution(id)
                                  setKeepOpen(true)
                                }}
                                as='button'
                              >
                                <div>
                                  <ButtonLabel>Ver resolução {title}</ButtonLabel>
                                </div>
                                <IconVideo size={18} />
                              </ButtonR>
                            )
                          }
                          )}
                        </VideoWrapper>
                      </>
                    )}
                  </TagGroup>

                </ContentTitle>

              </Content>
              {renderWatchedCheckBox(row, markAsWatched, setKeepOpen)}
              {(width < 563 && row.type === resourcesTypes.CLASS) && <ClassClassification watched={row.watched} classification={row.enemRelevance} />}
            </Item>
          ) : (

            <Item key={i} watched={row.watched} active={row.active} type={row.type} onClick={() => createNewSimulatedFromGenericList(row)}>
              {row.watched && <WatchedIconWrapper><WatchedIcon /></WatchedIconWrapper>}
              <Label watched={row.watched} type={row.type} pdfType={row.pdfType}>
                <Icon type={row.type}>
                  {row.type === resourcesTypes.CLASS && <PlayIcon />}
                  {row.type === resourcesTypes.SUBJECT && <QuestionIcon />}
                  {row.type === resourcesTypes.SIMULATED && <SimulatedIcon />}
                  {row.type === resourcesTypes.PDFS && <QuestionIcon />}
                </Icon>
                <LabelText>
                  SIMULADO
                </LabelText>
              </Label>
              <Content type={row.type}>
                <ContentTitle type={row.type}>
                  {row.discipline ? `Simulado de ${row.discipline[0].name} - ${row.subject[0].name}` : `Simulado especifico ${row.title}`}
                  <TagGroup>
                    {row.tags?.map(({ id, title, color }) => (
                      <Tag key={id}>{title}</Tag>
                    ))}
                  </TagGroup>
                </ContentTitle>
              </Content>

              {renderWatchedCheckBox(row, markAsWatched, setKeepOpen)}
            </Item>
          ))
      })}

      {openFreePlanModal && (
        <FreePlanMessageModal handleClose={handleCloseFreePlanModal} />
      )}
      <PlayerModal
        id={video}
        visible={showResolution}
        onClose={toggleResolutionModal}
      />
    </Wrapper>

  )
}

GenericList.propTypes = {
  items: t.arrayOf(t.shape({
    id: t.string,
    url: t.string,
    watched: t.bool,
    type: t.oneOf([
      resourcesTypes.CLASS,
      resourcesTypes.SUBJECT,
      resourcesTypes.SIMULATED,
      resourcesTypes.PDFS,
      resourcesTypes.VIDEO_GROUP,
    ]),
    label: t.string,
    title: t.string,
    tags: t.arrayOf(t.shape({
      id: t.string.isRequired,
      title: t.string.isRequired,
      color: t.string,
    })),
    active: t.bool,
  })),
  live: t.bool,
  setKeepOpen: t.func,
  markAsWatched: t.func,
}

const Item = styled(({ watched, ...props }) => <Link {...props} />)`
  position: relative;
  display: block;
  text-decoration: none;
  background: ${({ theme }) => theme.colours.background};
  padding: 1.6rem 2rem;
  border-radius: ${({ theme }) => theme.radius.small};
  will-change: box-shadow;
  transition: box-shadow .1s ease;
  overflow: hidden;

  ${({ watched, theme }) => !watched && `
    &:hover {
      box-shadow: 0 0 0 1px ${rgba(theme.colours.third, 0.5)};
    }
  `}

  ${({ active, theme }) => active && `
    background: ${rgba(theme.colours.third, 0.1)};
  `}

  ${({ watched, theme }) => watched && `
    background: ${rgba(theme.colours.primary, 0.05)};
    border: 1px solid ${theme.colours.primary};

    & * {
      color: ${theme.colours.primary};
    }

    svg {
      opacity: 1;
    }
  `}

  ${({ type }) => (type === resourcesTypes.SUBJECT || type === resourcesTypes.SIMULATED || type === resourcesTypes.PDFS) && `
    margin-top: 2.4rem;
  `}


  ${({ live }) => live && `
    display: flex;
    `}

&.disabled {
    color: currentColor;
    opacity: 0.5;
    text-decoration: none;
  }
  :not(:last-child) {
     margin-bottom: ${({ type }) => (type === resourcesTypes.SUBJECT || type === resourcesTypes.SIMULATED || type === resourcesTypes.PDFS) ? '2.4rem' : '0.8rem'};
    }

`

const WatchedIconWrapper = styled.div`
  position: absolute;
  top: 1.6rem;
  right: 2rem;
  display: block;
  width: 2rem;
  height: 2rem;
`

const Label = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${({ type, theme }) => (type === resourcesTypes.SUBJECT || type === resourcesTypes.SIMULATED || type === resourcesTypes.PDFS) && `
    padding-bottom: 1.4rem;
    margin-top: -3px;

    span {
      font-weight: bold !important;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: -.3rem;
      width: 5.2rem;
      height: 17rem;
      border-radius: 10px;
      left: -4rem;
      top: -2rem;
      background: ${theme.colours.primary};
      z-index: 1;
    }
  `}
`

const Icon = styled.div`
  position: relative;
  width: 2rem;
  height: 2rem;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ type, theme }) => (type === resourcesTypes.SUBJECT || type === resourcesTypes.SIMULATED || type === resourcesTypes.PDFS) && `
    position: relative;
    width: 2rem;
    height: 2rem;
    z-index: 2;
    right: 1.4rem;
    top: 2.4rem;
  `};

  ${({ type, theme }) => (type === resourcesTypes.SUBJECT || type === resourcesTypes.SIMULATED) && `
    top: 4rem;
    right: 1.4rem;
  `};

  svg {
    height: 100%;
  }
`

const LabelText = styled.span`
  position: relative;
  z-index: 2;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.primary};
  padding-left: 1rem;
  font-weight: ${({ type }) => type === resourcesTypes.VIDEO_GROUP ? '900' : '600'};
  font-size: ${({ type }) => type === resourcesTypes.VIDEO_GROUP ? '1.4rem' : '1.1rem'};
  text-transform: uppercase;
  letter-spacing: .05em;

  ${({ live, theme }) => live && `
    display: flex;
    color: ${theme.colours.text};
    `}


`

const Content = styled.div`
  padding-top: 1.2rem;
  padding-left: ${({ type }) => (type === resourcesTypes.SUBJECT || type === resourcesTypes.SIMULATED || type === resourcesTypes.PDFS) ? '3.1rem' : '.1rem'};
`

const Content1 = styled.div`
  display: flex;
  justify-content: space-between;
`

const ContentTitle = styled.h3`
  ${({ type }) => (type === resourcesTypes.SUBJECT || type === resourcesTypes.SIMULATED || type === resourcesTypes.PDFS) && `
  position: relative;
  bottom: 1.4rem;
  right: 0.2rem;
  `};
  margin: 0;
  padding: 0;
  line-height: 1.4;
  font-size: 1.4rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colours.text};
  font-weight: bold;

  ${media.lessThan('phone')`
    line-height: 1.6;
  `}
`

const TagGroup = styled.div`
  display: inline-block;
  vertical-align: middle;

  ${media.lessThan('phone')`
    display: block;
    padding-top: .6rem;
  `}
`

const Tag = styled.span`
  display: inline-block;
  margin-left: 1.2rem;
  padding: .4rem 1rem;
  border-radius: ${({ theme }) => theme.radius.large};
  font-size: 1.1rem;
  text-transform: lowercase;
  font-weight: 600;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.textSecondary : darken(0.4, theme.colours.purpleGray)};
  background-color: ${({ theme }) => theme.darkTheme ? theme.colours.background2 : darken(0.02, theme.colours.purpleGray)};


  ${media.lessThan('phone')`
    font-size: 1rem;
    margin-left: .6rem;

    &:first-child {
      margin-left: 0;
    }
  `}
`

const Wrapper = styled.div`

 `
const VideoWrapper = styled.div`
  display: flex;
`
const ButtonR = styled(Button)`
  margin-right: 1.2rem;
  display: flex;
  padding: 1em 2em 1.1em;
  font-size: 1.2rem;
  margin-top: 1rem;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
`

const ToggleWrapper = styled.div`
  ${media.greaterThan('smallDesktop')`
    padding-top: 1rem;
    ${({ type }) => (type === resourcesTypes.SUBJECT || type === resourcesTypes.SIMULATED || type === resourcesTypes.PDFS) && `
      padding-left: 2.8rem;
    `};
  `}
  ${media.lessThan('smallDesktop')`
      ${({ type }) => (type === resourcesTypes.SUBJECT || type === resourcesTypes.SIMULATED || type === resourcesTypes.PDFS) && `
      padding-left: 3.1rem;
    `};
  `}


`

const Check = styled.div`
  min-width: 1.8rem;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 5px;
  border: 1px solid #bbb;
`

const Text = styled.span`
  font-size: 1.2rem;
  padding-left: .7em;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.primary};
  ${({ isChecked }) => isChecked && css`
    color: ${({ theme }) => theme.colours.primary};
    font-weight: 600;
  `}
`

const LabelCheckBox = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

   > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    z-index: 99;

    &:checked + ${Check} {
      opacity: 1;
      background: url(${CHECKED_ICON}) no-repeat center ${({ theme }) => theme.colours.primary};
      background-size: 10px auto;
      border-color: ${({ theme }) => theme.colours.primary};
      animation: ${showCheckedIcon} .3s ease forwards;
    }
  }
`

const Checkbox = styled.input`
    width: 20px;
    height: 20px;
`

const renderLabel = (value, live) => {
  if (!live) {
    if (value.label) {
      return value.label
    }
    if (value.title) {
      return value.title
    }
    if (value.videoGroupTitle) {
      return value.videoGroupTitle
    }
  }
}

const renderWatchedCheckBox = (row, markAsWatched, setKeepOpen) => {
  if (row.type === resourcesTypes.SIMULATED || row.type === resourcesTypes.SUBJECT || row.type === resourcesTypes.VIDEO_GROUP) {
    return (
      <ToggleWrapper onClick={(e) => e.stopPropagation()} type={row.type}>
        <LabelCheckBox>
          <Checkbox
            type='checkbox'
            onChange={(e) => {
              markAsWatched(row)
              setKeepOpen(true)
            }}
            checked={row.watched}
          />
          <Check />
          <Text isChecked={row.watched}>
            {row.watched ? `Desmarcar como ${row.type === resourcesTypes.VIDEO_GROUP ? 'assistida' : 'concluído'}` : `Marcar como ${row.type === resourcesTypes.VIDEO_GROUP ? 'assistida' : 'concluído'}`}
          </Text>
        </LabelCheckBox>
      </ToggleWrapper>
    )
  }
}
export { GenericList }
