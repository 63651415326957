import React, { useContext } from 'react'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'

import { ContentBox, AvatarPicture, colours, radius } from 'ui'

import { ThemeContext } from 'providers'

import darkModeImg from './img/Ranking-Camiseta-Dark.png'
import lightModeImg from './img/Ranking-Camiseta-Light.png'

import { useCommunityRanking } from './use-community-ranking'

import { Icon } from './img/shirt'

export const CommunityRanking = () => {
  const { data, loading } = useCommunityRanking()

  const themeContext = useContext(ThemeContext)
  if (loading) return <ContentLoading />

  const { darkMode } = themeContext

  return (
    <ContentBox title='Alunos mais ativos'>
      <ShirtImage src={darkMode ? darkModeImg : lightModeImg} alt='Camiseta' />
      <Content>
        {data.map(item => (
          <Item key={item.color}>
            <Position color={item.color} position={item.position}>{item.position}º</Position>
            <Avatar color={item.color} position={item.position}>
              <AvatarPicture name={item.name} photo={item.photo} size={item.position < 4 ? '4rem' : '3rem'} />
              {item.position < 4 ? (
                <IconWrapper>
                  <item.icon />
                </IconWrapper>
              ) : null}
            </Avatar>
            <div>
              <ItemTitle position={item.position}><ShirtIcon>{item.position < 4 && <Icon />}</ShirtIcon>{item.name}</ItemTitle>
              <TotalAnswers position={item.position}>{item.quantityOfAnswers} respostas</TotalAnswers>
            </div>
          </Item>
        ))}
      </Content>
      <About>
        <AboutHeader>
          <AboutTitle>Como funciona o ranking?</AboutTitle>
        </AboutHeader>
        <AboutText>A classificação é baseada somente  na quantidade de respostas e não considera criação de tópicos. Quanto mais respostas, melhor a posição no ranking.</AboutText>
        <AboutText>A classificação do ranking é baseada na quantidade de respostas relativas ao mês imediatamente anterior.</AboutText>
      </About>
    </ContentBox>
  )
}
const ShirtImage = styled.img`
  width: 100%;
  margin-bottom: 20px;
`
const Content = styled.div``

const Item = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }
`

const Position = styled.p`
  color: ${({ color, theme }) => theme.colours[color]};
  font-weight: 700;
  font-size: ${({ position }) => position < 4 ? '1.6rem' : '0.9rem'};
  margin-right: 1.4rem;
  text-align: center;
  min-width: 2rem;
`

const Avatar = styled.div`
  width: ${({ position }) => position < 4 ? '4.2rem' : '3.2rem'};
  height: ${({ position }) => position < 4 ? '4.2rem' : '3.2rem'};
  border-radius: 50%;
  border: 0.2rem solid ${({ color, theme }) => theme.colours[color]};
  margin-right: 1.6rem;
  position: relative;
`

const IconWrapper = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  bottom: -1rem;
  left: 32%;
`
const ShirtIcon = styled.p`
  color: ${({ theme }) => theme.darkTheme ? '#CACACA' : '#000'};
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`

const ItemTitle = styled.p`
  display: flex;
  color: ${({ theme }) => theme.colours.primary};
  font-size: ${({ position }) => position < 4 ? '1.4rem' : '1rem'};
  font-weight: 700;
  margin-bottom: 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TotalAnswers = styled.p`
  font-size: ${({ position }) => position < 4 ? '1.2rem' : '0.9'};
  font-weight: 600;
`

const About = styled.div`
  margin-top: 3.8rem;
`

const AboutHeader = styled.div`
  display: flex;
  margin-bottom: 1rem;
  position: relative;
  padding-left: 2rem;

  &:before {
    content: '!';
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: ${({ theme }) => theme.colours.background};
    position: absolute;
    left: 0;
    width: 1.5rem;
    height: 1.5rem;
    background-color: ${({ theme }) => theme.colours.primary};
    border-radius: 50%;
  }
`

const AboutTitle = styled.p`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colours.text};
  font-size: 1.2rem;
  font-weight: 600;
`

const AboutText = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colours.gray2};
  text-align: justify;
  margin-top: 10px;
`

const ContentLoading = () => (
  <ContentLoader
    speed={2}
    width={100}
    height={281}
    backgroundColor={colours.contentLoadingColor3}
    foregroundColor={colours.contentLoadingColor4}
    style={{ width: '100%', height: '40rem' }}
  >
    <rect x='0' y='1.6rem' rx={radius.medium} ry={radius.medium} width='100%' height='40rem' />
  </ContentLoader>
)
