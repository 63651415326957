import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { animated, useSpring } from 'react-spring'

export const NotificationNotice = ({ trigger }) => {
  const props = useSpring({
    config: { friction: 10 },
    from: { transform: 'scale(0)' },
    to: { transform: trigger ? 'scale(1)' : 'scale(0)' },
    delay: 1000,
  })

  return <Dot style={props} />
}

const Dot = styled(animated.span)`
  width: 1.2rem;
  height: 1.2rem;
  border: 2px solid ${({ theme }) => theme.colours.mainBackground};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colours.error};
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.top};
`

NotificationNotice.propTypes = {
  trigger: t.bool.isRequired,
}
