import React from 'react'
import { Button } from 'ui'
import styled from 'styled-components'
import t from 'prop-types'
import { useMe } from 'resources'

const SimulatedNew = (props) => {
  const { openModalNewSimulated } = props
  const { isFree } = useMe()

  return (
    <ButtonWrapper>
      <Button as='button' onClick={openModalNewSimulated} disabled={isFree}>
        GERAR SIMULADO
      </Button>
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.div`
  flex-basis: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin: 2rem;
`
export { SimulatedNew }

SimulatedNew.propTypes = {
  openModalNewSimulated: t.func,
}
