import { useQuery } from '@apollo/client'
import { FEATURED_STATS_QUERY } from './graphql'
import { safelyGetFields, featuredTypes } from 'resources'
import { evolve } from 'ramda'

export const useFeaturedStats = () => {
  const { data, loading, error, refetch } = useQuery(FEATURED_STATS_QUERY)

  const featuredStats = safelyGetFields(['stats', 'featuredStats'], data)
    .map(evolve({ type: type => featuredTypes[type] }))

  return {
    featuredStats,
    loading,
    error,
    refetch,
  }
}
