import { usePaginatedQuery } from 'resources'

import { USER_NOTIFICATIONS_QUERY } from './graphql'

export const useNotifications = ({ itemsToFetch = 3 } = {}) => {
  const { userNotifications, loading, error, refetch } = usePaginatedQuery(USER_NOTIFICATIONS_QUERY, {
    fetchPolicy: 'network-only',
    nodeName: 'userNotifications',
    page: 1,
    perPage: itemsToFetch,
  })

  return {
    userNotifications,
    loading,
    error,
    refetch,
  }
}
