import React, { useEffect } from 'react'
import { hot } from 'react-hot-loader'
import { GlobalStyle } from 'ui'
import ReactGA from 'react-ga'
import { ApolloProvider } from '@apollo/client'
import { AuthProvider } from 'resources'
import { BrowserRouter } from 'react-router-dom'
import { Providers } from './providers'
import App from './app'
import client from './apollo-client'

const Root = () => {
  useEffect(() => {
    ReactGA.initialize('UA-69485120-1')

    ReactGA.pageview(window.location.pathname)
  }, [])

  return (
    <Providers>
      <BrowserRouter getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt */
      }}
      >
        <ApolloProvider client={client}>
          <AuthProvider>
            <GlobalStyle />
            <App />
          </AuthProvider>
        </ApolloProvider>
      </BrowserRouter>
    </Providers>
  )
}

export default hot(module)(Root)
