import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react'
import t from 'prop-types'
import { useMutation } from '@apollo/client'
import mitt from 'mitt'

import { setToken, removeToken, getToken, setEmail } from './store'
import { LOGIN_MUTATION } from './login-mutation'
import { useHistory, useLocation } from 'react-router'
import { logoutDialog } from 'ui'
import {
  LOGIN,
  REGISTER,
  RECOVER_PASSWORD,
  NEW_PASSWORD,
} from 'routes'

const AuthContext = React.createContext()
const PUBLIC_PAGES = [LOGIN, REGISTER, RECOVER_PASSWORD, NEW_PASSWORD]

export const authEmitter = mitt()

function AuthProvider ({ children }) {
  const { pathname, search } = useLocation()
  const history = useHistory()
  const [loginMutation, { loading }] = useMutation(LOGIN_MUTATION)
  const [didCheckUserIn, setDidCheckUserIn] = useState(false)
  const [userInfo, setUserInfo] = useState({
    isUserLoggedIn: false,
    user: null,
  })

  const checkUser = useCallback(async () => {
    try {
      const { token } = await getToken()

      setUserInfo({ isUserLoggedIn: !!token, user: null })
    } catch (_) {
      setUserInfo({ isUserLoggedIn: false, user: null })
    }

    setDidCheckUserIn(true)
  }, [setUserInfo, setDidCheckUserIn])

  const login = useCallback(async loginData => {
    const input = {
      ...loginData,
      origin: 'WEB',
      params: {
        urlParams: search,
        formType: 'login',
      },
    }

    const { data } = await loginMutation({ variables: { input } })

    await setToken(data.login)
    await setEmail(loginData.email)

    setUserInfo({
      isUserLoggedIn: true,
      user: {},
    })
  }, [loginMutation, search])

  const logout = useCallback(async () => {
    history.push('/')
    await removeToken()
    setUserInfo({
      isUserLoggedIn: false,
      user: null,
    })
  }, [history])

  useEffect(() => {
    checkUser()
  }, [checkUser, logout])

  useEffect(() => {
    authEmitter.on(LOGOUT_EVENT, () => {
      if (!PUBLIC_PAGES.includes(pathname)) {
        logoutDialog(logout)
      }
    })
    return () => authEmitter.all.clear()
  }, [history, logout, pathname])

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        userInfo,
        isLogging: loading,
        didCheckUserIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: t.node.isRequired,
}

function useAuth () {
  return useContext(AuthContext)
}

export const LOGOUT_EVENT = 'LOGOUT_EVENT'

export const dispatchLogout = () => authEmitter.emit(LOGOUT_EVENT)

export { AuthProvider, AuthContext, useAuth }
