import React, { forwardRef } from 'react'
import t from 'prop-types'
import styled, { css } from 'styled-components'
import { showCheckedIcon } from 'ui'

import CHECKED_ICON from 'ui/form/radio/checked-icon.svg'

const Toggle = forwardRef(({
  label,
  checkedText = label,
  checked,
  name,
  onChange,
  disableTabbing,
}, ref) => (
  <Label>
    <input
      type='checkbox'
      ref={ref}
      name={name}
      onChange={onChange}
      checked={checked}
      tabIndex={disableTabbing ? '-1' : undefined}
    />
    <Check />
    <Text isChecked={checked}>
      {checked ? checkedText : label}
    </Text>
  </Label>
))

Toggle.propTypes = {
  label: t.string.isRequired,
  checkedText: t.string,
  checked: t.bool,
  name: t.string,
  onChange: t.func,
  disableTabbing: t.bool,
}

const Check = styled.div`
  min-width: 1.8rem;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 5px;
  border: 1px solid #bbb;
`

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked + ${Check} {
      opacity: 1;
      background: url(${CHECKED_ICON}) no-repeat center ${({ theme }) => theme.colours.primary};
      background-size: 10px auto;
      border-color: ${({ theme }) => theme.colours.primary};
      animation: ${showCheckedIcon} .3s ease forwards;
    }
  }
`

const Text = styled.span`
  font-size: 1.2rem;
  padding-left: .7em;

  ${({ isChecked }) => isChecked && css`
    color: ${({ theme }) => theme.colours.primary};
    font-weight: 600;
  `}
`

export { Toggle }
