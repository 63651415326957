import { drop, pipe, prop, replace } from 'ramda'
import qs from 'qs'

export const setTextMaxLength = (text, maxLength = 40, ending = '…') => {
  if (!text) return ''

  if (text.length <= maxLength) return text

  return `${text.substring(0, maxLength)}${ending}`
}

export const getInitials = name => {
  if (!name) return ''
  const initials = name.match(/\b\w/g) || []
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
}

export const formatDate = (timestamp) => {
  if (!timestamp) return 'Data indisponível'

  const date = new Date(timestamp)

  return new Intl.DateTimeFormat('pt-BR', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }).format(date)
}

export const formatDateWithTime = timestamp => {
  if (!timestamp) return 'Data indisponível'

  const date = new Date(timestamp)

  return new Intl.DateTimeFormat('pt-BR', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }).format(date)
}

export const formatSecondsToMinutes = (time) => {
  if (!time) return ''

  const h = Math.floor(time / 3600)
  const m = Math.floor(time % 3600 / 60)
  const s = Math.floor(time % 3600 % 60)

  const paddedTime = [h, m, s].map(item => item.toString().padStart(2, '0'))

  if (h > 0) return paddedTime.join(':')

  return drop(1, paddedTime).join(':')
}

export const diffInMinutes = (startDate, endDate) => {
  const milliseconds = startDate - endDate
  return Math.round(((milliseconds % 86400000) % 3600000) / 60000)
}

export const letterFromNumber = value => {
  const letters = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
  ]

  return letters[value - 1]
}

export const msToTime = time => new Date(time).toISOString().slice(11, -5).split(':')

export const getPageParam = pipe(
  replace('?', ''),
  qs.parse,
  prop('pagina'),
  Number
)
