import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import t from 'prop-types'
import { LogoIcon, Menu as BaseMenu, MenuStudent as BaseMenuStudent, Avatar, Switch } from 'ui'
import Hamburguer from './hamburguer'
import { HOME } from 'routes'

const MobileMenu = ({ className }) => {
  const { pathname } = useLocation()
  const [isActive, setIsActive] = useState()
  const [isChecked, setIsChecked] = useState(false)

  const toggleMenu = () => setIsActive(!isActive)

  useEffect(() => {
    setIsActive(false)
    const storedValue = localStorage.getItem('darkMode')
    if (storedValue !== null) {
      setIsChecked(storedValue === 'true')
    }
  }, [pathname])

  return (
    <Wrapper className={className}>
      <Header>
        <LogoIcon width='36px' url={HOME} />
        <Hamburguer handleClick={toggleMenu} active={isActive} />
      </Header>
      <MenuWrapper active={isActive}>
        <DarkMode>
          Modo escuro <Switch checked={isChecked} isSidebarOpened />
        </DarkMode>
        <Menu textIsVisible />
        <MenuStudent textIsVisible />
        <MenuAvatar
          name='Rafael Silva'
          email='rafael@domain.com'
          pic='https://pbs.twimg.com/profile_images/974736784906248192/gPZwCbdS.jpg'
          showDrop={false}
        />
      </MenuWrapper>
    </Wrapper>
  )
}

MobileMenu.propTypes = {
  className: t.string,
}

const Wrapper = styled.div`
  width: 100%;
`

const Header = styled.header`
  width: 100%;
  display: flex;
  height: 6.8rem;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  position: relative;
  background-color: ${({ theme }) => theme.colours.background};
  border-bottom: 1px solid ${({ theme }) => theme.colours.gray3};
  z-index: ${({ theme }) => theme.zIndex.base};
`

const MenuWrapper = styled.div`
  position: sticky;
  top: 0;
  right: 0;
  width: 100%;
  height: calc(100vh - 6.8rem);
  transition: transform .2s ease-in;
  transform: translateX(100%);
  overflow: scroll;
  background-color: ${({ theme }) => theme.colours.background};

  ${({ active }) => active && `
    transform: translateX(0);
  `}
`

const Menu = styled(BaseMenu)`
  padding: 4rem 2rem;
`

const MenuStudent = styled(BaseMenuStudent)`
  padding: 4rem 2rem;
  border-top: 1px solid ${({ theme }) => theme.colours.gray4};
  border-bottom: 1px solid ${({ theme }) => theme.colours.gray4};
`

const MenuAvatar = styled(Avatar)`
  padding: 4.5rem 2rem;
`

const DarkMode = styled.div`
  margin-top: 2rem;
  position: absolute;
  font-size: 1.7rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  padding-left: 0.6rem;
  color: ${({ theme }) => theme.colours.darkPurpleGray};
  ${({ right }) => right && 'padding-right: .6rem;'};
  white-space: nowrap;
  transition: opacity 0.3s ease, visibility 0.3s ease;
`

export { MobileMenu }
