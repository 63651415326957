import { useCallback } from 'react'
import { useQuery, useMutation } from '@apollo/client'

import { useError } from 'resources'

import { ANNOUNCEMENT_QUERY, CLOSE_ANNOUNCEMENT_MUTATION } from './graphql'

export const useAnnouncement = () => {
  const [dispatchError] = useError()

  const { data, loading, error } = useQuery(ANNOUNCEMENT_QUERY)
  const [closeAnnouncement, { error: mutationError }] = useMutation(CLOSE_ANNOUNCEMENT_MUTATION)

  if (mutationError) dispatchError(mutationError)

  const announcement = data?.announcement

  const dismissAnnouncement = useCallback(() => {
    closeAnnouncement()
  }, [closeAnnouncement])

  return {
    dismissAnnouncement,
    announcement,
    loading,
    error,
  }
}
