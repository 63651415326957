import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { ProgressBar } from 'ui'
import { isEmpty } from 'ramda'

const Progress = ({ items = [] }) => {
  if (isEmpty(items)) {
    return (
      <EmptyBox>
        <EmptyText size='1.2rem'>Não há nada aqui.</EmptyText>
        <EmptyText size='1rem'>Parece que você ainda não assistiu nenhuma aula.</EmptyText>
      </EmptyBox>
    )
  }

  return (
    <List>
      {items.map(({ discipline, resolved, total }) => (
        <Item key={discipline}>
          <ItemText isTitle>{discipline}</ItemText>
          <ProgressBar
            completed={resolved}
            total={total}
          />
          <ItemText isNumber>{resolved}/{total}</ItemText>
        </Item>
      ))}
    </List>
  )
}

Progress.propTypes = {
  items: t.arrayOf(t.shape({
    discipline: t.string,
    resolved: t.number,
    total: t.number,
  })),
}

const EmptyBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 30rem;
  font-size: 1.2rem;
`

const EmptyText = styled.p`
  font-size: ${({ size }) => size};
  margin: 0;

  &:last-child {
    margin-top: 1.2rem;
  }
`

const Item = styled.li`
  display: flex;
  align-items: center;
`

const ItemText = styled.span`
  color: ${({ theme }) => theme.colours.text};
  font-size: 1.4rem;

  ${({ isTitle }) => isTitle && `
    min-width: 12rem;
  `}

  ${({ isNumber }) => isNumber && `
    text-align: right;
    min-width: 6rem;
  `}
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 30rem;
  overflow-y: scroll;

  ${Item} {
    padding: 2.6rem 0;
    border-bottom: 1px solid ${({ theme }) => theme.colours.purpleGray};

    &:first-child {
      padding-top: 1rem;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
`

export { Progress }
