import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

import { ErrorMessage, media, colours, radius } from 'ui'
import { useMe, errorMessages } from 'resources'
import ContentLoader from 'react-content-loader'

const StudyPlanWarning = (questionCourse) => {
  const { loading, error, startedStudyPlan } = useMe()

  if (loading) {
    return (
      <Wrapper>
        <ContentLoading />
      </Wrapper>
    )
  }

  if (error) {
    return (
      <Wrapper>
        <ErrorMessage message={errorMessages.STARTED_STUDY_PLAN_ERROR} textSize='large' hasBackground hasRefresh />
      </Wrapper>
    )
  }
  if (startedStudyPlan) return <Warning>Ao iniciar este {`${questionCourse.questionCourse ? 'Curso por Questões' : 'Plano de Estudo'}`} você <b>perderá seu progresso</b> no {`${questionCourse.questionCourse ? 'curso por questões' : 'plano de estudo'}`} atual.</Warning>

  return null
}

const Warning = styled.div`
  background-color: ${({ theme }) => rgba(theme.colours.warning, 0.5)};
  border-radius: ${({ theme }) => theme.radius.small};
  font-size: 1.2rem;
  line-height: 1.4;
  margin: 1.3rem 0;
  padding: 1.3rem 2rem;

  ${media.between('phone', 'smallDesktop')`
    text-align: left;
  `}
`

const Wrapper = styled.div`
  padding: 3rem 0;
  width: 100%;
`

const ContentLoading = () => (
  <ContentLoader
    speed={2}
    width={100}
    height={75}
    backgroundColor={colours.contentLoadingColor3}
    foregroundColor={colours.contentLoadingColor4}
    style={{ width: '100%', height: '7.5rem' }}
  >
    <rect x='0' y='0' rx={radius.large} ry={radius.large} width='100%' height='7.5rem' />
  </ContentLoader>
)

export { StudyPlanWarning }
