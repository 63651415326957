import { useQuery, gql } from '@apollo/client'
import { safelyGetFields } from 'resources'

export const useSupportDisciplines = () => {
  const { data, loading, error } = useQuery(SUPPORT_OPTIONS_QUERY, {
    fetchPolicy: 'no-cache',
  })

  const supportDisciplines = safelyGetFields(['supportOptions', 'disciplines'], data).map(toDiscipline)

  return {
    supportDisciplines,
    loading,
    error,
  }
}

const toDiscipline = ({ id, name }) => ({
  id,
  name,
})

const SUPPORT_OPTIONS_QUERY = gql`
  query GetSupportOptions {
    supportOptions {
      disciplines {
        id
        name
      }
    }
  }
`
