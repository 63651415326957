import React from 'react'
import styled from 'styled-components'
import t from 'prop-types'

const Hamburguer = ({ handleClick, active }) => (
  <HamburguerBox onClick={handleClick}>
    <HamburguerInner active={active} />
  </HamburguerBox>
)

Hamburguer.propTypes = {
  handleClick: t.func.isRequired,
  active: t.bool,
}

const menuConfig = {
  lineWidth: '23px',
  lineHeight: '2px',
  lineSpacing: '-7px',
}

const HamburguerBox = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
`

const HamburguerInner = styled.div`
  display: block;
  margin-top: -2px;
  top: 50%;
  transition-delay: .15s;
  transition-timing-function: cubic-bezier(.4,.01,.165,.99);
  transition-duration: .15s;
  background-color: ${({ theme }) => theme.colours.primary};

  ${({ active }) => active && `
    transition: transform 75ms cubic-bezier(.4,.01,.165,.99) 0s, background-color 0s linear .15s;
    transform: rotate(90deg);
    background-color: transparent;
  `}

  &,
  &:before,
  &:after {
    position: absolute;
    width: ${menuConfig.lineWidth};
    height: ${menuConfig.lineHeight};
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
  }

  &:before,
  &:after {
    content: '';
    display: block;
  }

  &:before {
    background-color: ${({ theme }) => theme.colours.primary};
    top: ${menuConfig.lineSpacing};
    transition: top 75ms ease-in 75ms, transform 75ms cubic-bezier(.55,.055,.675,.19) 0s;

    ${({ active }) => active && `
      top: 0;
      transition: top 75ms ease-out .1s,transform 75ms cubic-bezier(.215,.61,.355,1) .15s;
      transform: rotate(-45deg);
    `}
  }

  &:after {
    background-color: ${({ theme }) => theme.colours.primary};
    width: 12px;
    bottom: ${menuConfig.lineSpacing};
    transition: bottom 75ms ease-in 75ms, transform 75ms cubic-bezier(.55,.055,.675,.19) 0s, width 75ms ease;

    ${({ active }) => active && `
      width: ${menuConfig.lineWidth};
      bottom: 0;
      transition: bottom 75ms ease-out .1s, transform 75ms cubic-bezier(.215,.61,.355,1) .15s, width 75ms ease;
      transform: rotate(45deg);
    `}
  }
`

export default Hamburguer
