import styled, { css } from 'styled-components'
import styledBy from 'styled-by'
import { Link as RouterLink } from 'react-router-dom'
import LinkArrow from './link-arrow.svg'
import LinkChange from './link-change.svg'
import LinkDownload from './link-download.svg'

const linkCommonStyles = css`
  background-color: transparent;
  appearance: none;
  border: none;
  position: relative;
  display: inline-block;
  text-decoration: ${({ theme }) => theme.darkTheme ? 'underline' : 'none'};
  font-size: 1.4rem;
  font-weight: bold;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.primary};
  will-change: opacity;
  transition: opacity .2s ease;
  cursor: pointer;
  ${styledBy('variant', {
    _: () => 'padding-right: 1.3em;',
    prev: () => 'padding-left: 1.3em;',
    next: () => 'padding-right: 1.3em;',
    change: () => 'padding-left: 1.5em;',
    download: () => 'padding-left: 1.3em;',
    clear: () => '',
  })}

  &:after {
    display: block;
    width: 12px;
    height: 8px;
    position: absolute;
    transition: all .2s ease;
    ${styledBy('variant', {
      _: () => `content: url(${LinkArrow}); right: 0; top: 0;`,
      prev: () => `content: url(${LinkArrow}); left: 0; transform: rotate(180deg); bottom: 1px;`,
      next: () => `content: url(${LinkArrow}); right: 0; top: 0;`,
      change: () => `content: url(${LinkChange}); left: 0; top: 0;`,
      download: () => `content: url(${LinkDownload}); left: 0; top: .1rem;`,
      clear: () => 'display: none;',
    })}
  }

  &:hover {
    opacity: .9;

    &:after {
      ${styledBy('variant', {
        _: () => 'right: -2px;',
        prev: () => 'left: -2px;',
        next: () => 'right: -2px;',
        change: () => '',
        download: () => '',
        clear: () => '',
      })}
    }
  }

  ${props => props.disabled && css`
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  `}
`

const Link = styled(RouterLink)`
 ${linkCommonStyles}
`

const External = styled.a`${linkCommonStyles}`

Link.defaultProps = {
  variant: '_',
}

External.defaultProps = {
  variant: '_',
}

Link.External = External

export { Link }
