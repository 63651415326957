import React, { useState } from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { NavLink, Switch, Route } from 'react-router-dom'
import { media, FreePlanMessageModal } from 'ui'
import { useMe } from 'resources'

const Tabs = ({ tabs, context }) => {
  const { isFree } = useMe()
  const [openFreePlanModal, setOpenFreePlanModal] = useState(false)

  const handleCloseFreePlanModal = () => {
    setOpenFreePlanModal(false)
  }

  const handleClick = (allowedToFree) => (evt) => {
    const linkDisabled = isFree && !allowedToFree

    if (linkDisabled) {
      evt.preventDefault()

      setOpenFreePlanModal(true)
    }
  }
  const freeTabs = tabs?.filter(({allowedToFree}) => allowedToFree)
  return (
    <>
      <Container>
        {tabs?.map(({ name, path , allowedToFree}) => (
          <Item key={name} to={`${context}/${path}`} onClick={handleClick(allowedToFree)} exact>{name}</Item>
        ))}
      </Container>

      <Switch>
        {isFree && freeTabs?.map((item) => (
          <Route key={item.name} path={`${context}/${item.path}`} children={<item.component />} exact />
        ))}

        {!isFree && tabs?.map((item) => (
          <Route key={item.name} path={`${context}/${item.path}`} children={<item.component type={item.path} />} exact />
        ))}
      </Switch>

      {openFreePlanModal && (
        <FreePlanMessageModal handleClose={handleCloseFreePlanModal} />
      )}
    </>
  )
}

const activeClassName = 'active'

const Container = styled.nav`
  background: ${({ theme }) => theme.darkTheme ? theme.colours.mainBackground : theme.colours.background};
  margin: 0 0 4rem;
  padding: 0 0 0 4rem;
  list-style: none;
  display: flex;
  border-radius: ${({ theme }) => theme.radius.large};
  overflow-x: auto;

  ${media.lessThan('phone')`
    padding-left: 2.5rem;
    box-shadow: inset -20px 0px 17px -10px rgba(0, 0, 0, .05);
  `}
`

const Item = styled(NavLink).attrs({ activeClassName })`
  position: relative;
  cursor: pointer;
  text-decoration: none;
  padding: 2em 0;
  font-size: 1.4rem;
  font-weight: bold;
  font-weight: bold;
  letter-spacing: -.01em;
  color: ${({ theme }) => theme.colours.text };
  margin-right: 3em;
  transition: color .3s ease;

  &.${activeClassName} {
    color: ${({ theme }) => theme.colours.text};;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 3px;
      border-radius: 2px 2px 0 0;
      background: ${({ theme }) => theme.colours.primary};
    }
  }

  &:last-child {
    margin-right: 0;
  }

  ${media.lessThan('phone')`
    margin-right: 2em;
    letter-spacing: 0;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 100%;
        width: 20px;
        height: 30px;
      }
    }
  `}
`

Tabs.propTypes = {
  tabs: t.arrayOf(t.shape({
    name: t.string,
    path: t.string,
    allowedToFree: t.bool,
    component: t.element,
  })),
  context: t.string.isRequired,
}

export { Tabs }
