import React from 'react'
import styled from 'styled-components'
import t from 'prop-types'
import { media } from 'ui'

export const Lives = ({ children }) => {
  return (
    <Wrapper>
      <Content>{children}</Content>
    </Wrapper>
  )
}

Lives.propTypes = {
  children: t.node.isRequired,
}

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "content";
  grid-gap: 3rem;

  ${media.lessThan('tablet')`
    grid-template-columns: 1fr;
    grid-template-areas:
      'content';
  `}
`

const Content = styled.div`
  grid-area: content;
`
