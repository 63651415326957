import React from 'react'
import styled, { css } from 'styled-components'
import ContentLoader from 'react-content-loader'
import { rgba } from 'polished'
import { media, colours, radius } from 'ui'
import CheckIcon from './check-icon.svg'

const SmallWrapper = styled.div`
  padding-top: 1rem;
`

const ItemSmallWrapper = styled.div`
  position: relative;
  padding-top: 2rem;
  display: flex;
  align-items: center;
  text-decoration: none;

  @media (max-height: 750px) {
    padding-top: 1.4rem;
  }
`

const Item = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colours.background};
  border-radius: ${({ theme }) => theme.radius.small};
  padding: 1.3rem;
  will-change: box-shadow;
  transition: box-shadow .15s ease;
  cursor: pointer;

  ${media.greaterThan('tablet')`
    padding: 1.7em 6em 1.7em 2.5em;
  `}

  &:hover,
  &:focus {
    ${({ theme }) => css`
      box-shadow: 0 0 0 1px ${rgba(theme.colours.third, 0.5)};
    `}
  }

  ${prop => prop.isOpen && css`
    ${({ theme }) => `
      box-shadow: 0 0 0 1px ${rgba(theme.colours.third, 0.5)};
    `}
  `}

  ${prop => prop.isRead && css`
    ${({ theme }) => `
      background: ${rgba(theme.colours.background, 0.5)};
    `}
  `}
`

const ReadLabel = styled.div`
  display: inline-block;
  position: absolute;
  top: 2.4rem;
  right: 3rem;
  color: ${({ theme }) => theme.colours.background};
  background: ${({ theme }) => theme.colours.success};
  font-size: 1.1rem;
  font-weight: bold;
  text-transform: uppercase;
  padding: .5rem .8rem .5rem 1.2rem;
  border-radius: ${({ theme }) => theme.radius.small};

  &:after {
    content: '';
    background: url(${CheckIcon}) no-repeat 100%/auto;
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    vertical-align: middle;
    margin-left: .6rem;
    margin-top: -.2rem;
  }

  ${media.lessThan('tablet')`
    top: 1.9rem;
    right: 2rem;
  `}
`

const Header = styled.div`
  display: flex;
`

const Content = styled.div`
  padding-left: 2em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const HiddenContent = styled.div`
  margin: 0;
  padding: 0;
  overflow: hidden;
`

const HiddenContentInner = styled.div`
  padding: 3em .2em 0 .2em;
  p {
    font-size: 1.4rem;
    line-height: 1.8;
    color: ${({ theme }) => theme.darkTheme ? theme.colours.textSecondary : theme.colours.gray1};
    margin-bottom: 3rem;
  }

  ul, ol {
    margin: 0 0 3rem;
    padding: 0;

    li {
      font-size: 1.4rem;
    }
  }

  h4, h5, h6 {
    font-size: 1.6rem;
    margin: 3rem 0;
  }

  img {
    max-width: 100%;
    display: inline-block;
    margin-bottom: 3rem;
  }
`

const TextHeader = styled.span`
  display: block;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.textSecondary : theme.colours.gray2};
  font-size: 1.1rem;
  padding-bottom: ${({ small }) => small ? '.33rem' : '.44rem'};
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colours.text};
  font-size: ${({ small }) => small ? '1.2rem' : '1.4rem'};
  font-weight: normal;

  ${media.lessThan('tablet')`
    max-width: 80%;
  `}

  ${({ small }) => small && `
    line-height: 1.5;
  `}
`

const Wrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  ${Item} {
    margin-bottom: 1.8em;
  }
`

const Empty = styled.p`
  font-size: ${({ small }) => small ? '1.2rem' : '1.4rem'};
  color: ${({ theme }) => theme.darkTheme ? theme.colours.textSecondary : theme.colours.gray1};
  text-align: center;
  padding: 1.5rem;

`

const ContentLoading = (props) => (
  <ContentLoader
    speed={2}
    width={100}
    height={288}
    backgroundColor={colours.contentLoadingColor1}
    foregroundColor={colours.contentLoadingColor2}
    style={{ width: '100%', height: '288px' }}
    {...props}
  >
    <rect x='0' y='0' rx={radius.small} ry={radius.small} width='100%' height='84px' />
    <rect x='0' y='102' rx={radius.small} ry={radius.small} width='100%' height='84px' />
    <rect x='0' y='204' rx={radius.small} ry={radius.small} width='100%' height='84px' />
  </ContentLoader>
)

export {
  SmallWrapper,
  ItemSmallWrapper,
  Item,
  ReadLabel,
  Header,
  Content,
  HiddenContentInner,
  HiddenContent,
  TextHeader,
  Title,
  Wrapper,
  Empty,
  ContentLoading,
}
