import React from 'react'
import styled from 'styled-components'
import t from 'prop-types'
import Cards from 'react-credit-cards'
import logoHipercard from './logo-hipercard.svg'
import logoVisa from './logo-visa.svg'
import logoAmex from './logo-amex.svg'
import logoElo from './logo-elo.svg'
import 'react-credit-cards/es/styles-compiled.css'

const CreditCard = ({ name, expiry, cardNumber, issuer }) => {
  return (
    <Container>
      <Cards
        preview
        cvc=''
        expiry={expiry}
        name={name}
        number={cardNumber}
        issuer={issuer}
      />
    </Container>
  )
}

const Container = styled.div`
  .rccs {
    margin: 0;
  }

  .rccs__card--front {
    border-radius: 14px;
    box-shadow: none;
    color: #555;
    border: 1px solid #eee;
  }

  .rccs__card--front
  .rccs__card__background {
    background: #f5f5f9 !important;
    transform: none;
  }

  .rccs__chip {
    display: none;
  }

  .rccs__card--hipercard .rccs__issuer {
    background-image: url('${logoHipercard}')
  }

  .rccs__card--visa .rccs__issuer {
    background-image: url('${logoVisa}')
  }

  .rccs__card--amex .rccs__issuer {
    background-image: url('${logoAmex}')
  }

  .rccs__card--elo .rccs__issuer {
    background-image: url('${logoElo}')
  }
`

CreditCard.propTypes = {
  name: t.string.isRequired,
  expiry: t.string.isRequired,
  cardNumber: t.string.isRequired,
  issuer: t.string.isRequired,
}

export { CreditCard }
