import React from 'react'
import styled from 'styled-components'
import { media, colours, radius } from 'ui'
import ContentLoader from 'react-content-loader'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 1.2rem;
  justify-content: space-between;

  ${media.greaterThan('tablet')`
    grid-gap: 2.6rem;
  `}

  ${media.lessThan('phone')`
    display: flex;
    overflow: auto;
    scroll-snap-type: x mandatory;
  `}
`

const Item = styled.div`
  border-radius: ${({ theme }) => theme.radius.large};
  background: ${({ theme }) => theme.colours.secondary};
  overflow: hidden;
  padding: 2.2rem 2.7rem 3rem 2.7rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.lessThan('phone')`
    min-width: 25rem;
    scroll-snap-align: start;
    margin-right: 1.6rem;
  `}
`

const Title = styled.h3`
  margin-bottom: 1rem;
  font-size: 1.3rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.textSecondary : theme.colours.background};
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 100%;
  text-transform: uppercase;
`

const Numbers = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const NumbersText = styled.p`
  margin: 0;
  padding: 0 0 .6rem 0;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.textSecondary : theme.colours.background};
  font-weight: normal;
  text-align: right;
`

const NumbersText2 = styled.p`
  margin: 0;
  padding: 0 0 0.6rem 0;
  font-size: 3.4rem;
  color: #fff;
  font-weight: bold;
  text-align: center;
`

const ContentLoading = (props) => (
  <Wrapper>
    <ContentLoader
      speed={2}
      width={100}
      height={100}
      backgroundColor={colours.contentLoadingColor1}
      foregroundColor={colours.contentLoadingColor2}
      style={{ width: '100%', height: '10rem' }}
      {...props}
    >
      <rect x='0' y='0' rx={radius.large} ry={radius.large} width='100%' height='10rem' />
    </ContentLoader>
  </Wrapper>
)

export { Wrapper, Item, Title, Numbers, NumbersText, NumbersText2, ContentLoading }
