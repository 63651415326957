import { gql } from '@apollo/client'

export const USER_NOTIFICATIONS_QUERY = gql`
  query GetUserNotifications($pagination: PaginationInput) {
    userNotifications(pagination: $pagination) {
      pagination {
        total
        lastPage
        perPage
        page
      }
      nodes {
        id
        author {
          name
          avatar
        }
        releaseDate
        title
        description
        read
      }
    }
  }
`
