import {
  HomeIcon,
  LessonsIcon,
  EnemIcon,
  StudyPlanIcon,
  QuestionsIcon,
  StatisticsIcon,
  SimulatedIcon,
  CommunityIcon,
  HelpCenterIcon,
  StudentIcon,
  QuestionsBookIcon,
  SupportIcon,
  LiveIcon,
  EssayIcon,
  QuestionCourseIcon,
  DiscursiveCourseIcon,
  RegionalsIcon,
  BookAnalysisIcon,
} from './menu-icons'
import {
  HOME,
  CLASSES,
  REGIONAL,
  ESSAYS,
  STUDY_PLAN,
  QUESTION_COURSE,
  QUESTIONS,
  SIMULATED,
  COMMENTED_TESTS,
  STATS,
  HELP_CENTER,
  STUDENT_AREA,
  QUESTIONS_BOOK,
  SUPPORT,
  LIVES,
  DISCURSIVE_COURSE,
  COMMUNITY_AREA,
  BOOK_ANALYSIS,
} from 'routes'

const menuItems = [
  {
    label: 'Início',
    url: HOME,
    exact: true,
    allowedToFree: false,
    icon: {
      component: HomeIcon,
    },
  },
  {
    label: 'Aulas',
    url: CLASSES,
    exact: false,
    allowedToFree: true,
    icon: {
      component: LessonsIcon,
    },
  },
  {
    label: 'Ferretto+',
    url: LIVES,
    exact: true,
    allowedToFree: true,
    icon: {
      component: LiveIcon,
    },
    options: {
      className: (liveStatus) => handleClassName[liveStatus],
    },
  },
  {
    label: 'Redação',
    url: ESSAYS,
    exact: false,
    allowedToFree: false,
    icon: {
      component: EssayIcon,
    },
  },
  {
    label: 'Provas comentadas',
    url: COMMENTED_TESTS,
    exact: false,
    allowedToFree: false,
    icon: {
      component: EnemIcon,
    },
  },
  {
    label: 'Curso de Discursivas',
    url: DISCURSIVE_COURSE,
    exact: false,
    allowedToFree: false,
    icon: {
      component: DiscursiveCourseIcon,
    },
  },
  {
    label: 'Cursos Regionais',
    url: REGIONAL,
    exact: false,
    allowedToFree: false,
    icon: {
      component: RegionalsIcon,
    },
  },
  {
    label: 'Análise de obras',
    url: BOOK_ANALYSIS,
    exact: true,
    allowedToFree: false,
    icon: {
      component: BookAnalysisIcon,
    },
  },
  {
    label: 'Planos de estudo',
    url: STUDY_PLAN,
    exact: false,
    allowedToFree: false,
    icon: {
      component: StudyPlanIcon,
    },
  },
  {
    label: 'Curso por questões',
    url: QUESTION_COURSE,
    exact: false,
    allowedToFree: false,
    icon: {
      component: QuestionCourseIcon,
    },
  },
  {
    label: 'Questões',
    url: QUESTIONS,
    exact: false,
    allowedToFree: false,
    icon: {
      component: QuestionsIcon,
    },
  },
  {
    label: 'Estatísticas',
    url: STATS,
    exact: false,
    allowedToFree: false,
    icon: {
      component: StatisticsIcon,
    },
  },
  {
    label: 'Simulados',
    url: SIMULATED,
    allowedToFree: true,
    icon: {
      component: SimulatedIcon,
    },
  },
  {
    label: 'Comunidade',
    url: COMMUNITY_AREA,
    allowedToFree: false,
    icon: {
      component: CommunityIcon,
    },
  },
  {
    label: 'Monitoria',
    url: SUPPORT,
    allowedToFree: false,
    icon: {
      component: SupportIcon,
    },
  },
]

const handleClassName = {
  IS_NOW: 'live-started',
  IS_TODAY: 'menu-item-live',
  NOT_TODAY: 'menu-item',
}

const menuStudentItems = [
  {
    label: 'Área do aluno',
    url: STUDENT_AREA,
    allowedToFree: true,
    exact: false,
    icon: {
      component: StudentIcon,
    },
  },
  {
    label: 'Caderno de questões',
    url: QUESTIONS_BOOK,
    allowedToFree: false,
    exact: false,
    icon: {
      component: QuestionsBookIcon,
    },
  },
  {
    label: 'Central de ajuda',
    url: HELP_CENTER,
    allowedToFree: false,
    exact: false,
    icon: {
      component: HelpCenterIcon,
    },
  },
]

export { menuItems, menuStudentItems }
