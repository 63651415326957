import { onError } from '@apollo/client/link/error'
import { dispatchLogout } from 'resources'
import { isNotAuthenticated, isPublicOperation } from './commons'

const hasAuthError = (errors = []) => errors.some(isNotAuthenticated)

export const unauthorizedErrorLink = onError(({ graphQLErrors, forward, operation }) => {
  if (hasAuthError(graphQLErrors) && !isPublicOperation(operation.operationName)) {
    dispatchLogout()
  }
})
