import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'
import { Link, colours, radius } from 'ui'
import { getInitials, useMe } from 'resources'

const ProfileDataItem = ({ label, text, linkLabel, linkUrl }) => (
  <ProfileDataItemStyled>
    <ProfileDataLabel>{label}</ProfileDataLabel>
    <ProfileDataText>{text}</ProfileDataText>
    {linkLabel && <Link href={linkUrl}>{linkLabel}</Link>}
  </ProfileDataItemStyled>
)

const ProfileAvatar = ({ sidebar, tempPicture }) => {
  const { name, email, photo, loading } = useMe()

  if (loading) return <ContentLoading />

  return (
    <AvatarWrapper>
      <AvatarPicture sidebar={sidebar}>
        {tempPicture && <img src={tempPicture} alt={name} />}
        {!tempPicture &&
          (photo ? <img src={photo} alt={name} /> : getInitials(name))}
      </AvatarPicture>
      {name && <AvatarName>{name}</AvatarName>}
      {email && <AvatarEmail sidebar={sidebar}>{email}</AvatarEmail>}
    </AvatarWrapper>
  )
}

ProfileAvatar.propTypes = {
  sidebar: t.bool,
  tempPicture: t.string,
}

ProfileDataItem.propTypes = {
  label: t.string.isRequired,
  text: t.string.isRequired,
  linkLabel: t.string,
  linkUrl: t.string,
}

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const AvatarPicture = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colours.primary};
  font-size: 3rem;
  color: #fff;
  font-weight: bold;

  ${(prop) =>
    prop.sidebar &&
    `
    @media (max-height: 750px) {
      width: 80px;
      height: 80px;
    }
  `}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const AvatarName = styled.h3`
  font-size: 1.8rem;
  font-weight: bold;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.secondary};
  padding: 0.5em 0 0;
  margin: 0 0 0.3em;
  letter-spacing: -0.02em;
`

const AvatarEmail = styled.p`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colours.gray2};
  padding: 0;
  margin: 0 0 1em;

  ${(prop) =>
    prop.sidebar &&
    `
    @media (max-height: 800px) {
      display: none;
    }
  `}
`

const ProfileDataItemStyled = styled.div`
  display: block;
`

const ProfileDataLabel = styled.p`
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  color: #a6a6be;
  margin-bottom: 0.5em;
`

const ProfileDataText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  background: ${({ theme }) => theme.colours.primary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  + a {
    margin-top: 2rem;
  }
`

const ProfileDataList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 3rem;
`

const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 1rem;
`

const ContentLoading = () => (
  <LoadingWrapper>
    <ContentLoader
      data-testid='loading'
      speed={2}
      width={150}
      height={164}
      backgroundColor={colours.contentLoadingColor3}
      foregroundColor={colours.contentLoadingColor4}
      style={{ margin: 'auto' }}
    >
      <circle cx='75' cy='50' r='50' />
      <rect
        x='0'
        y='109'
        rx={radius.small}
        ry={radius.small}
        width='15rem'
        height='3.1rem'
      />
      <rect
        x='10'
        y='146'
        rx={radius.small}
        ry={radius.small}
        width='13rem'
        height='1.5rem'
      />
    </ContentLoader>
  </LoadingWrapper>
)

export { ProfileDataItem, ProfileDataList, ProfileAvatar }
