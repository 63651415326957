import { gql, useQuery } from '@apollo/client'
import { pipe, defaultTo, prop, map } from 'ramda'
import { renameKeys } from 'resources/composable'

const HELP_SUBJECTS = gql`
  {
    helpCenterSubjects {
      id
     value
    }
  }
`

export const useHelpSubjects = () => {
  const { data, loading } = useQuery(HELP_SUBJECTS, {
    fetchPolicy: 'no-cache',
  })

  return {
    subjects: toOptions(data),
    loading,
  }
}

const toOptions = pipe(
  prop('helpCenterSubjects'),
  defaultTo([]),
  map(renameKeys({
    id: 'value',
    value: 'label',
  }))
)
