import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { SVGLogo, SVGLogoIcon, SVGLogoIconNew } from './logo-svg'

const Logo = ({ width, white, url, as }) => (
  <LogoWrapper width={width} to={url} as={as}>
    <SVGLogo white={white} />
  </LogoWrapper>
)

const LogoIcon = ({ width, white, url, as, register = false }) => (
  <LogoWrapper width={width} to={url} as={as}>
    {register ? (<SVGLogoIconNew white={white} />) : <SVGLogoIcon white={white} />}
  </LogoWrapper>
)

Logo.propTypes = {
  width: t.string,
  white: t.bool,
  url: t.string,
  as: t.string,
}

LogoIcon.propTypes = {
  width: t.string,
  white: t.bool,
  url: t.string,
  as: t.string,
  register: t.bool,
}

const LogoWrapper = styled(Link)`
  display: inline-block;
  text-decoration: none;
  width: ${props => props.width ? props.width : 'auto'};
  z-index: ${({ theme }) => theme.zIndex.top};
  will-change: width;
  transition: width .3s ease;

  svg {
    width: 100%;
    height: auto;
  }
`

export { Logo, LogoIcon }
