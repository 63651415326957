import React from 'react'
import t from 'prop-types'

import { PlayIcon, WatchedIcon, QuestionIcon, SimulatedIcon, GrapphicSummariesIcon } from '../generic/generic-icons'
import { Tag, Item, Wrapper, Icon, Label, LabelText, Content, ContentTitle, TagGroup, WatchedIconWrapper, ItemLink, LabelIcon, Content1 } from './components'
import { colours, radius } from 'ui/theme'
import ContentLoader from 'react-content-loader'
import { resourcesTypes } from 'resources'
import { useSimulatedNew } from '../../pages/simulated/hooks/use-simulated-new/use-simulated-new'

const Playlist = ({ onSelectVideo, items, loading }) => {
  const { createNewSimulatedFromGenericList } = useSimulatedNew()
  return (
    <Wrapper>
      {loading && <ContentLoading />}

      {!loading && items.map((row, index) => {
        if (row.type === resourcesTypes.CLASS) {
          return (
            <Item onClick={_ => onSelectVideo({ slug: row.slug, id: row.id, index: index })} key={row.id} watched={row.watched} active={row.active}>
              {row.watched && (
                <WatchedIconWrapper>
                  <WatchedIcon />
                </WatchedIconWrapper>
              )}

              <Label watched={row.watched}>

                <Icon>
                  <PlayIcon />
                </Icon>

                <LabelText>
                  {row.label}
                </LabelText>
              </Label>

              <Content>
                <ContentTitle>
                  {row.title}
                </ContentTitle>

                <TagGroup>
                  {row.audience && row.audience?.map(({ id, title }) => (
                    <Tag key={id}>{title}</Tag>
                  ))}
                </TagGroup>

              </Content>
            </Item>
          )
        }
        return row.type !== resourcesTypes.VIDEO_GROUP && (
          <ItemLink key={row.id} to={row.type !== resourcesTypes.PDFS ? row.url : undefined} watched={row.watched} active={row.active} onClick={row.type === resourcesTypes.SIMULATED ? () => createNewSimulatedFromGenericList(row) : row.type === resourcesTypes.PDFS ? () => window.open(row.url, '_blank', 'noopener noreferrer') : undefined}>
            {row.watched && (
              <WatchedIconWrapper>
                <WatchedIcon />
              </WatchedIconWrapper>
            )}
            <Content1>
              <LabelIcon watched={row.watched} type={row.type} pdfType={row.pdfType}>

                <Icon type={row.type} side>
                  {row.type === resourcesTypes.CLASS && <PlayIcon />}
                  {row.type === resourcesTypes.SUBJECT && <QuestionIcon />}
                  {row.type === resourcesTypes.SIMULATED && <SimulatedIcon />}
                  {row.type === resourcesTypes.PDFS ? row.pdfType === 'GRAPHIC_SUMMARIES' ? <GrapphicSummariesIcon /> : <QuestionIcon /> : null}
                </Icon>

                <LabelText>
                  {row.label}
                </LabelText>
              </LabelIcon>
            </Content1>
            <Content playlist>
              <ContentTitle>
                {row.title}
              </ContentTitle>

              <TagGroup>
                {row.audience && row.audience?.map(({ id, title }) => (
                  <Tag key={id}>{title}</Tag>
                ))}
              </TagGroup>

            </Content>
          </ItemLink>
        )
      })}
    </Wrapper>
  )
}

const ContentLoading = () => (
  <ContentLoader
    speed={2}
    width='100%'
    height={900}
    backgroundColor={colours.contentLoadingColor3}
    foregroundColor={colours.contentLoadingColor4}
  >
    <rect x='0' y='7' rx={radius.small} ry={radius.small} width='100%' height='130' />
    <rect x='0' y='148' rx={radius.small} ry={radius.small} width='100%' height='130' />
    <rect x='0' y='288' rx={radius.small} ry={radius.small} width='100%' height='130' />
    <rect x='0' y='431' rx={radius.small} ry={radius.small} width='100%' height='130' />
    <rect x='0' y='573' rx={radius.small} ry={radius.small} width='100%' height='130' />
    <rect x='0' y='718' rx={radius.small} ry={radius.small} width='100%' height='130' />
    <rect x='0' y='858' rx={radius.small} ry={radius.small} width='100%' height='130' />
  </ContentLoader>
)

Playlist.propTypes = {
  items: t.array.isRequired,
  onSelectVideo: t.func.isRequired,
  loading: t.bool.isRequired,
}

export { Playlist }
