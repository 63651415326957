import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { pathOr } from 'ramda'

export const useMyEssaysHeader = () => {
  const { disciplineId } = useParams()
  const { data, loading, error } = useQuery(SUPPORT_META_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      disciplineId,
    },
    skip: !disciplineId,
  })

  return {
    supportMeta: pathOr({}, ['supportMeta'], data),
    loading,
    error,
  }
}

const SUPPORT_META_QUERY = gql`
query GetSupportMeta($disciplineId: ID!) {
  supportMeta(disciplineId: $disciplineId) {
    openedTicketsThisWeek
    totalOfAllowedTicketsPerWeek
  }
}
`
