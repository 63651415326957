import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { safelyGetFields } from 'resources'

import { COMMENTED_TEST_DISCIPLINES_QUERY } from './graphql'

export const useCommentedTestDisciplines = () => {
  const { slug } = useParams()

  const { data, loading, error } = useQuery(COMMENTED_TEST_DISCIPLINES_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      slug,
    },
  })

  const disciplines = safelyGetFields(['commentedTestDisciplines'], data).map(toDiscipline)

  return {
    disciplines,
    loading,
    error,
  }
}

const toDiscipline = ({ id, name, slug }) => ({
  id,
  name,
  slug,
})
