import React from 'react'
import t from 'prop-types'

import { useCommunitySubjects } from './use-community-subject'
import { Select } from 'ui'

export const CommunitySubjectsSelect = ({ disabled = false, emptyLabel = 'Selecione um assunto', valueName = 'slug', ...props }) => {
  const { subjects, loading } = useCommunitySubjects({
    valueName,
    emptyLabel,
  })

  return (
    <Select
      items={subjects}
      disabled={disabled}
      loading={loading}
      {...props}
    />
  )
}

CommunitySubjectsSelect.propTypes = {
  disabled: t.bool,
  valueName: t.string,
  emptyLabel: t.string,
}
