import styled from 'styled-components'
import { media, Button } from 'ui'
import { rgba } from 'polished'
import { radius } from 'ui/theme'

export const AcessTheme = styled(Button)`
  padding: 1.2rem 2.3rem;
  ${media.lessThan('tablet')`
    padding: 1rem 2rem;
  `}
`

export const EssayThemeCardList = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;

  ${media.lessThan('phone')`
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  `}
`

export const Tag = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: -0.08em;
  border: solid 1px red;
  border-radius: ${radius.small};
  background: ${({ theme }) => rgba(theme.colours.primary, 0.05)};
  border: solid 1px ${({ theme }) => theme.colours.primary};
  color: ${({ theme }) => theme.colours.primary};
  padding: .25rem .75rem;
  text-transform: uppercase;
  margin-top: .35rem;
  margin-bottom: .75rem;
  display: inline-block;
`

export const Box = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  background: ${({ theme }) => theme.colours.background};
  border-radius: ${({ theme }) => theme.radius.small};
  padding: 2rem;
`

export const Title = styled.h3`
  margin: 0;
  padding: .4rem 0;
  color: ${({ theme }) => theme.colours.primary};
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.4;

  ${media.lessThan('tablet')`
    font-size: 1.5rem;
    padding-top: 0;
  `}

  ${media.lessThan('phone')`
    font-size: 1.4rem;
  `}
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 3em;

  ${Button} {
    flex-grow: 0;
    margin-top: 1rem;
  }

  ${media.greaterThan('tablet')`
    padding-left: 2.2rem;
  `}
`

export const Description = styled.p`
  p span {
    margin-top: .35rem;
    margin-bottom: .5rem;
    padding: 0;
    //line-height: 1.6;
    max-width: 93%;
    font-size: 1.5rem;
    letter-spacing: -0.03em;
    color: ${({ theme }) => theme.colours.gray1};

    ${media.lessThan('tablet')`
      max-width: 100%;
      font-size: 1.2rem;
      margin-bottom: 1.5em;
    `}
  }
`

export const Poster = styled.div`
  flex-basis: 18rem;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;
    border-radius: ${({ theme }) => theme.radius.small};
    display: block;

    ${media.lessThan('mediumDesktop')`
      height: auto;
    `}
  }

  ${media.lessThan('tablet')`
    flex-basis: 15rem;
  `}

  ${media.lessThan('phone')`
    flex-basis: 5rem;
  `}
`
