import { gql } from '@apollo/client'

export const LIST_ESSAYS_BY_USER = gql`
  query GetEssays($page: Int!) {
    listEssays(page: $page) {
      totalPages
      currentPage
      essays {
        id
        text
        status
        corrections {
          generalComment
          rate
          competences {
            rate
            comment
          }
        }
      }
   }
}
`
export const ESSAY_CREDITS = gql`
  query EssayCredits {
    essayCredits {
      total
      used
      }
    }
`
