import React from 'react'
import styled from 'styled-components'
import t from 'prop-types'
import styledBy from 'styled-by'

import { ReactComponent as DiamondIcon } from './plan-diamond.svg'
import { ReactComponent as GoldIcon } from './plan-gold.svg'
import { ReactComponent as BronzeIcon } from './plan-bronze.svg'

const PlanTag = styled.span`
  display: inline-block;
  padding: .6rem 1.6rem;
  font-size: .9rem;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: .1em;
  font-weight: 600;

  ${styledBy('plan', {
    diamond: () => 'color: #2171A3; background: #B7E3FF;',
    gold: () => 'color: #D3A423; background: #FFE8A9;',
    bronze: () => 'color: #DFC06C; background: #F7EED8;',
  })}
`

const PlanIcon = ({ plan, width, height }) => (
  <IconWrapper width={width} height={height}>
    {plan === 'diamond' && <DiamondIcon />}
    {plan === 'gold' && <GoldIcon />}
    {plan === 'bronze' && <BronzeIcon />}
  </IconWrapper>
)

const PLANS = ['diamond', 'gold', 'bronze']

PlanTag.defaultProps = {
  plan: 'diamond',
}

PlanTag.propTypes = {
  plan: t.oneOf(PLANS),
}

PlanIcon.propTypes = {
  plan: t.oneOf(PLANS),
  width: t.string,
  height: t.string,
}

const IconWrapper = styled.div`
  display: inline-block;
  width: ${props => props.width ? props.width : 'auto'};
  height: ${props => props.height ? props.height : 'auto'};

  svg {
    width: 100%;
    height: auto;
  }
`

export { PlanTag, PlanIcon }
