import React, { useContext } from 'react'
import { useRouteMatch } from 'react-router-dom'
import t from 'prop-types'
import { ThemeContext } from '../../providers'

import {
  AcessTheme,
  EssayThemeCardList,
  Title,
  Poster,
  Box,
  Content,
  Description,
  Tag,
} from './styles'

const EssayThemeCard = ({ title, url, description, image, correctionType }) => {
  const match = useRouteMatch()
  const pathTo = `${match.url}/${url}`
  const { theme } = useContext(ThemeContext)

  return (
    <Box style={{ flexDirection: 'column' }}>
      <Title>{title}</Title>
      <Box>
        <Poster>
          <img src={image} alt={title} />
        </Poster>
        <Content>
          <div>
            <Tag>{correctionType}</Tag>
            <Description>{renderText(theme, description)}</Description>
          </div>
          <AcessTheme to={pathTo} variant='ghost'>Acessar tema</AcessTheme>
        </Content>
      </Box>
    </Box>
  )
}

EssayThemeCard.propTypes = {
  title: t.string.isRequired,
  url: t.string.isRequired,
  description: t.string.isRequired,
  image: t.string.isRequired,
  correctionType: t.string.isRequired,
}

export { EssayThemeCard, EssayThemeCardList }

const renderText = (theme, text) => {
  const storedValue = localStorage.getItem('darkMode')
  const darkMode = storedValue ? storedValue.toLowerCase() === 'true' : false
  if (!darkMode) {
    return React.createElement('p', { dangerouslySetInnerHTML: { __html: text } })
  }

  const color = /color:\s*#[a-zA-Z0-9]+/gi
  const background = /background:\s*#[a-zA-Z0-9]+/gi
  const backgroundColor = /background-color:\s*#[a-zA-Z0-9]+/gi
  const newColor = text.replace(color, `color: ${theme.colours.text}`)
  const newBackground = newColor.replace(background, `background: ${theme.colours.mainBackground}`)
  const newBackgroundColor = newBackground.replace(backgroundColor, `background-color: ${theme.colours.background}`)

  return (
    <div dangerouslySetInnerHTML={{ __html: newBackgroundColor }} />
  )
}
