import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'

import SelectIcon from './select-icon.svg'

export const BasicSelect = ({ options, onChange }) => {
  return (
    <Wrapper>
      <Select onChange={onChange}>
        {options.map(({ value, label }, index) => (
          <option key={index} value={value}>{label}</option>
        ))}
      </Select>
    </Wrapper>
  )
}

BasicSelect.propTypes = {
  onChange: t.func,
  options: t.arrayOf(t.shape({
    label: t.string.isRequired,
    value: t.string.isRequired,
  })),
}

const Wrapper = styled.div`
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: .8rem;
    height: 1.6rem;
    right: 1rem;
    top: calc(50% - -1rem);
    margin-top: -1.7rem;
    background: url(${SelectIcon}) center no-repeat;
    background-size: 100% auto;
  }
`

const Select = styled.select`
  padding: 0.8rem 2.5rem 0.8rem 1rem;
  border: 1px solid ${({ theme }) => theme.colours.gray3};
  border-radius: ${({ theme }) => theme.radius.small};
  font-size: 1.4rem;
  width: 100%;
  appearance: none;
  background: ${({ theme }) => theme.colours.background};
  color: ${({ theme }) => theme.colours.text};
  box-shadow: 0 2px 4px rgba(230, 230, 230, .4);
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: ${({ theme }) => theme.font.default};
  max-width: 14rem;
  min-width: 14rem;
  width: 100%;
  text-overflow: ellipsis;
`
