import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { getInitials } from 'resources'

const AvatarPicture = ({ name, photo, size, fontSize }) => {
  const abbrName = getInitials(name)

  if (!photo) {
    return <AbbrName size={size} fontSize={fontSize} data-testid='avatar-picture'>{abbrName}</AbbrName>
  }

  return (
    <Picture size={size}>
      <img src={photo} alt={name} />
    </Picture>
  )
}

const AbbrName = styled.div`
  ${({ size }) => `
    width: ${size};
    height: ${size};
  `}

  background: ${({ theme }) => theme.colours.primary};
  border-radius: 50%;
  overflow: hidden;
  font-size: ${({ fontSize }) => fontSize || '1.6rem'};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Picture = styled.div`
  ${({ size }) => `
    width: ${size};
    height: ${size};
  `}

  border-radius: 50%;
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
  background: ${({ theme }) => theme.colours.gray3};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

AvatarPicture.propTypes = {
  name: t.string.isRequired,
  photo: t.string,
  size: t.string.isRequired,
  fontSize: t.string,
}

export { AvatarPicture }
