import React from 'react'
import t from 'prop-types'
import { isEmpty } from 'ramda'

import { ErrorMessage } from 'ui'
import { errorMessages } from 'resources'

import { useNotifications } from './hooks'
import { Wrapper, Empty, ContentLoading } from './components'
import { DefaultNotification } from './default-notification'

export const Notifications = ({ quantity }) => {
  const { userNotifications, loading, error, refetch } = useNotifications({ itemsToFetch: quantity })

  if (loading) return <ContentLoading />

  if (error) return <ErrorMessage message={errorMessages.NOTIFICATIONS_ERROR} textSize='medium' hasRefresh />

  if (isEmpty(userNotifications) || !userNotifications) {
    return <Empty>Você não tem novas notificações.</Empty>
  }

  return (
    <Wrapper>
      {userNotifications.map(({ id, ...rest }) =>
        <DefaultNotification onMarkAsRead={refetch} key={id} id={id} {...rest} />)}
    </Wrapper>
  )
}

Notifications.propTypes = {
  quantity: t.number,
}
