import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import t from 'prop-types'
import { SimulatedModal } from 'ui'
import { letterFromNumber } from 'resources'
import {
  QuestionItem,
  ListOfQuestions,
} from '../../pages/single-simulated-started/components'

export const RouterPrompt = (props) => {
  const { when, onOK, onCancel, questions = [], onFinish, status, disabled } = props

  const history = useHistory()

  const [showPrompt, setShowPrompt] = useState(false)
  const [currentPath, setCurrentPath] = useState('')

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname)
        if (status === 'OPEN') {
          history.block(() => { })
          history.push('/simulados')
          return 'true'
        }
        if (prompt.pathname.startsWith('/estatisticas-simulados')) {
          history.block(() => { })
          history.push(prompt.pathname)
          return 'true'
        }
        setShowPrompt(true)
        return 'true'
      })
    } else {
      history.block(() => { })
    }

    return () => {
      history.block(() => { })
    }
  }, [currentPath, history, onCancel, onFinish, onOK, status, when])

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK())
      if (canRoute) {
        history.block(() => { })
        onFinish()
      }
    }
  }, [history, onFinish, onOK])

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel())
      if (canRoute) {
        history.block(() => { })
        history.push(currentPath)
      }
    }
    setShowPrompt(false)
  }, [currentPath, history, onCancel])

  return showPrompt ? (

    <LeaveSimulatedModal
      visible
      title='ATENÇÃO!'
      subtitle='Ao sair da página, o simulado será finalizado de forma automática.'
      onFinish={handleOK}
      onCancel={handleCancel}
      questions={questions}
      status={disabled}
    />
  ) : null
}
const LeaveSimulatedModal = ({ title, subtitle, onFinish, onCancel, questions = [], status, ...props }) => {
  return (
    <SimulatedModal
      title='ATENÇÃO!'
      description='Ao sair da página, o simulado será finalizado de forma automática.'
      buttonText='Finalizar simulado'
      buttonAction={onFinish}
      hasCancel
      buttonTextCancel='Ficar e terminar'
      onClose={onCancel}
      status={status}
      questions={(
        <ListOfQuestions>
          {questions.map(({ status, order, choosenAlternative }) => (
            <QuestionItem
              key={order}
              selected
              answered={status === QuestionStatus.ANSWERED}
            >
              {choosenAlternative && status === QuestionStatus.ANSWERED ? `${order} \\ ${letterFromNumber(choosenAlternative.order)}` : order}
            </QuestionItem>
          ))}
        </ListOfQuestions>

      )}
      {...props}
    />
  )
}

const QuestionStatus = {
  ANSWERED: 0,
  NOT_ANSWERED: 1,
}

LeaveSimulatedModal.propTypes = {
  title: t.string,
  subtitle: t.string,
  onFinish: t.func,
  onCancel: t.func,
  questions: t.array,
  status: t.bool,
}

RouterPrompt.propTypes = {
  when: t.bool,
  onFinish: t.func,
  onCancel: t.func,
  questions: t.array,
  onOK: t.func,
  status: t.string,
  disabled: t.bool,
}
