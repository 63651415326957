import React from 'react'
import { Select } from '..'

export const QuestionInstitution = props => (
  <Select
    items={options}
    {...props}
  />
)

const options = [
  {
    label: 'Todas',
    value: '',
  },
  {
    label: 'ENEM',
    value: 'true',
  },
  {
    label: 'Vestibulares',
    value: 'false',
  },

]
