import { useQuery } from '@apollo/client'
import { safelyGetFields } from 'resources'

import { DISCURSIVE_COURSE_DISCIPLINES_QUERY } from './graphql'

export const useDiscursiveCourseDisciplines = () => {
  const { data, loading, error } = useQuery(DISCURSIVE_COURSE_DISCIPLINES_QUERY, {
    fetchPolicy: 'no-cache',
  })

  const disciplines = safelyGetFields(['listDissertiveQuestionsDisciplines'], data).map(toDiscipline)

  return {
    disciplines,
    loading,
    error,
  }
}

const toDiscipline = ({ id, name, slug }) => ({
  id,
  name,
  slug,
})
