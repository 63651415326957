import React from 'react'
import t from 'prop-types'

export const IconVideo = ({ color = '#fff', size = 24 }) => (
  <svg xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink' width={size} height={size} viewBox='0 0 329 320'>
    <defs>
      <clipPath id='clip-path'>
        <rect id='Rectangle_522' data-name='Rectangle 522' width='329' height='274' transform='translate(0.314 -0.43)' fill='#fff' />
      </clipPath>
    </defs>
    <g id='Group_1108' data-name='Group 1108' transform='translate(-20123.748 -818.211)'>
      <g id='Group_1107' data-name='Group 1107' transform='translate(20123.434 864.641)' clipPath='url(#clip-path)'>
        <path id='Path_2627' data-name='Path 2627' d='M95.793,7.258,16.92,57.07l78.873,49.812Z' transform='translate(209.949 79.266)' fill='none' stroke='#f8f8f8' strokeLinecap='round' strokeLinejoin='round' strokeWidth='30' />
        <path id='Path_2628' data-name='Path 2628' d='M189.387,5.388H30.764A26.352,26.352,0,0,0,1.919,28.154V141.985a26.352,26.352,0,0,0,28.845,22.766H189.387a26.352,26.352,0,0,0,28.845-22.766V28.154A26.352,26.352,0,0,0,189.387,5.388Z' transform='translate(8.638 51.266)' fill='none' stroke='#f8f8f8' strokeLinecap='round' strokeLinejoin='round' strokeWidth='30' />
      </g>
      <path id='Path_2629' data-name='Path 2629' d='M57.177,0,0,35.7l57.177,35.7Z' transform='translate(20277.131 1036.674) rotate(180)' fill='none' stroke='#f8f8f8' strokeLinecap='round' strokeLinejoin='round' strokeWidth='30' />
      <g id='Ellipse_255' data-name='Ellipse 255' transform='translate(20156.748 818.211)' fill='none' stroke='#f8f8f8' strokeWidth='30'>
        <circle cx='51.5' cy='51.5' r='51.5' stroke='none' />
        <circle cx='51.5' cy='51.5' r='36.5' fill='none' />
      </g>
      <g id='Ellipse_256' data-name='Ellipse 256' transform='translate(20248.748 841.211)' fill='none' stroke='#f8f8f8' strokeWidth='30'>
        <ellipse cx='39.5' cy='40' rx='39.5' ry='40' stroke='none' />
        <ellipse cx='39.5' cy='40' rx='24.5' ry='25' fill='none' />
      </g>
    </g>
  </svg>
)

IconVideo.propTypes = {
  color: t.string,
  size: t.number,
}
