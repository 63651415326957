import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import {
  Button,
  InputText,
  DisciplineSelect,
  QuestionStatus,
  QuestionInstitution,
  SubjectSelect,
} from 'ui'

import { useWatch, Controller } from 'react-hook-form'
import { useQuestionsFilter } from './use-questions-filter'

const QuestionsFilter = ({ isSubmitting, onFilter, fields = defaultFormFields }) => {
  const {
    errors,
    register,
    handleSubmit,
    onSubmit,
    control,
    isFetchingDisciplines,
  } = useQuestionsFilter(onFilter)
  return (

    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldWrapper visible={fields.includes(Fields.disciplineSlug)}>
        <Controller
          name='disciplineSlug'
          control={control}
          defaultValue=''
          render={({ ref, ...props }) => (
            <DisciplineSelect
              label='Disciplina'
              placeholder='Escolha uma disciplina'
              inputRef={ref}
              error={errors.disciplineSlug}
              {...props}
              withQuestions
            />
          )}
        />
      </FieldWrapper>

      <FieldWrapper visible={fields.includes(Fields.status)}>
        <Controller
          name='status'
          control={control}
          defaultValue=''
          render={({ ref, ...props }) => (
            <QuestionStatus
              label='Tipo'
              inputRef={ref}
              {...props}
            />
          )}
        />
      </FieldWrapper>

      <FieldWrapper visible={fields.includes(Fields.enem)}>
        <Controller
          name='enem'
          control={control}
          defaultValue=''
          render={({ ref, ...props }) => (
            <QuestionInstitution
              label='Banca'
              inputRef={ref}
              {...props}
            />
          )}
        />
      </FieldWrapper>

      <FieldWrapper visible={fields.includes(Fields.parentSubject)}>
        <PrimarySubject
          control={control}
        />
      </FieldWrapper>

      <FieldWrapper visible={fields.includes(Fields.subjectSlug)}>
        <SecondarySubject
          control={control}
        />
      </FieldWrapper>

      <FieldWrapper visible={fields.includes(Fields.code)}>
        <InputText
          id='code'
          name='code'
          defaultValue=''
          label='Código'
          error={errors.code}
          inputRef={register}
        />
      </FieldWrapper>

      <Button
        disabled={isFetchingDisciplines || isSubmitting}
        size='full'
        as='button'
      >
        Filtrar
      </Button>

    </form>
  )
}

const PrimarySubject = ({ control }) => {
  const disciplineSlug = useWatch({
    control,
    name: 'disciplineSlug',
  })

  return (
    <Controller
      name='parentSubjectSlug'
      control={control}
      defaultValue=''
      render={({ ref, ...props }) => (
        <SubjectSelect
          label='Assunto primário'
          inputRef={ref}
          disciplineSlug={disciplineSlug}
          disabled={!disciplineSlug}
          onlyParents
          {...props}
        />
      )}
    />
  )
}

const SecondarySubject = ({ control }) => {
  const parentSlug = useWatch({
    control,
    name: 'parentSubjectSlug',
  })

  return (
    <Controller
      name='subjectSlug'
      control={control}
      defaultValue=''
      render={({ ref, ...props }) => (
        <SubjectSelect
          label='Assunto secundário'
          parentSlug={parentSlug}
          disabled={!parentSlug}
          inputRef={ref}
          onlyParents={false}
          {...props}
        />
      )}
    />
  )
}

export const FieldWrapper = styled.div`
  display: ${p => p.visible ? 'block' : 'none'};
`

export const Fields = {
  disciplineSlug: 0,
  parentSubject: 1,
  subjectSlug: 2,
  code: 3,
  status: 4,
  enem: 5,
}

export const defaultFormFields = [
  Fields.disciplineSlug,
  Fields.parentSubject,
  Fields.subjectSlug,
  Fields.code,
  Fields.status,
  Fields.enem,
]

SecondarySubject.propTypes = {
  control: t.object,
}

PrimarySubject.propTypes = {
  control: t.object,
}

QuestionsFilter.propTypes = {
  onFilter: t.func.isRequired,
  isSubmitting: t.bool.isRequired,
  fields: t.array,
}

QuestionsFilter.Fields = Fields

export { QuestionsFilter }
