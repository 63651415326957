import { useQuery } from '@apollo/client'

import { DISCIPLINE_STATS_QUERY } from './graphql'
import { safelyGetFields } from 'resources'

export const useDisciplineStats = () => {
  const { data, loading, error, refetch } = useQuery(DISCIPLINE_STATS_QUERY, {
    fetchPolicy: 'no-cache',
  })

  return {
    disciplineStats: safelyGetFields(['stats', 'disciplineStats'], data),
    loading,
    error,
    refetch,
  }
}
