import React from 'react'
import styled from 'styled-components'
import t from 'prop-types'
import { redirectToEventsPlatform } from 'resources/simulateds/event-simulateds'
import { Button, media } from 'ui'

const SimuladaoSimulatedCard = ({ title, data, url, description, status }) => {
  const action = statusMap[status]

  return (
    <Wrapper>
      <MainContent>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        <Data>
          {data.map(({ title, value }) => {
            return (
              <DataRow key={title}>
                <DataTitle>{title}</DataTitle>
                <DataValue>{value}</DataValue>
              </DataRow>
            )
          })}
        </Data>
        <ButtonWrapper>
          <Button as='button' onClick={() => action.action(url)}>
            {action.label}
          </Button>
        </ButtonWrapper>
      </MainContent>
    </Wrapper>
  )
}

const statusMap = {
  NOT_FOUND: {
    label: 'Participar',
    action: (url) => redirectToEventsPlatform(url),
  },
  STARTED: {
    label: 'Continuar',
    action: (url) => redirectToEventsPlatform(url),
  },
  ENDED: {
    label: 'Resultado',
    action: (url) => redirectToEventsPlatform(`${url}/resultado`),
  },
}

SimuladaoSimulatedCard.propTypes = {
  title: t.string.isRequired,
  url: t.string.isRequired,
  description: t.string.isRequired,
  status: t.string,
  data: t.arrayOf(
    t.shape({
      title: t.oneOfType([t.number, t.string]).isRequired,
      value: t.oneOfType([t.number, t.string]).isRequired,
    })
  ),
}

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const Wrapper = ({ children, active }) => {
  return <StyledWrapper active={active}>{children}</StyledWrapper>
}

Wrapper.propTypes = {
  children: t.node.isRequired,
  active: t.bool,
}

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 2rem 3rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  background: ${({ theme }) => theme.colours.background};
  border-radius: ${({ theme }) => theme.radius.small};
  z-index: ${({ theme }) => theme.zIndex.base};

  ${({ active, theme }) =>
    active &&
    `
    border: 1px solid ${theme.colours.success};
  `}

  ${Button} {
    font-size: 1.2rem;
    padding: 1rem 2rem;

    &.cancel-button {
      padding: 0.7em 1.4em 0.75em;
      margin-top: -3px;
    }
  }
`

const Title = styled.h3`
  padding: 0.4rem 0;
  color: ${({ theme }) => theme.colours.primary};
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.4;
  position: relative;

  ${media.lessThan('phone')`
    font-size: 1.4rem;
    padding: 0;
  `}
`

const Description = styled.p`
  padding: 1rem 0;
`

const Data = styled.div`
  padding: 1rem 0;
`

const DataRow = styled.div`
  padding-bottom: 1.2rem;
`

const DataTitle = styled.h6`
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: normal;
  color: ${({ theme }) =>
    theme.darkTheme ? theme.colours.textSecondary : theme.colours.gray1};
`

const DataValue = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
  padding-top: 0.5rem;
  color: ${({ theme }) => theme.colours.text};
`

const ButtonWrapper = styled.div`
  flex-basis: auto;
`

const SimuladaoSimulatedList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  grid-gap: 3rem;
`

export { SimuladaoSimulatedList, SimuladaoSimulatedCard }
