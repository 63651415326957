import { useState, useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'

import { queryStringParser } from 'resources'

const useQueryString = (key) => {
  const history = useHistory()
  const location = useLocation()

  const setQueryStringValue = useCallback(value => {
    const values = qs.parse(location.search)

    const newQsValue = qs.stringify({ ...values, [key]: value }, { arrayFormat: 'comma' })
    history.push({
      search: decodeURIComponent(newQsValue),
    })
  }, [history, location.search, key])

  const getQueryStringValue = useCallback(key => {
    const values = qs.parse(location.search, { arrayFormat: 'comma' })

    return queryStringParser(values, key)
  }, [location.search])

  const [value, setValue] = useState(getQueryStringValue(key))

  useEffect(() => {
    setQueryStringValue(value)
  }, [value, setQueryStringValue])

  const onSetValue = useCallback(
    name => {
      setValue(value => value.includes(name) ? value.filter(item => item !== name) : value.concat(name))
    },
    []
  )

  return [value, onSetValue]
}

export { useQueryString }
