import React from 'react'
import { Select } from '../'
import styled from 'styled-components'

export const DateFilter = props => (
  <Wrapper>
    <Select
      items={options}
      {...props}
    />
  </Wrapper>
)

const options = [
  {
    label: 'Todo o período',
    value: null,
  },
  {
    label: 'Última semana',
    value: 7,
  },
  {
    label: 'Último mês',
    value: 30,
  },
  // {
  //   label: 'Últimos 3 meses',
  //   value: 90,
  // },
  // {
  //   label: 'Último ano',
  //   value: 365,
  // },
]

const Wrapper = styled.div`
  width: 200px;
`
