import React from 'react'
import t from 'prop-types'

const IconArrowDown = ({ color = '#C9C9C9' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='26.902' height='15.034' viewBox='0 0 26.902 15.034'>
    <path fill={color} d='M16.538,1.7a3,3,0,0,1,3.925,0l10.445,9.034A3,3,0,0,1,28.945,16H8.055a3,3,0,0,1-1.962-5.269Z' transform='translate(31.951 16) rotate(180)' />
  </svg>
)

IconArrowDown.propTypes = {
  color: t.string,
}

export { IconArrowDown }
