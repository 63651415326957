import { gql } from '@apollo/client'

export const DISCIPLINE_STATS_QUERY = gql`
  query GetDisciplineStats($timeInDays: Int) {
    stats {
      disciplineStats(timeInDays: $timeInDays) {
        id
        discipline
        resolved
        total
        percentage
      }
    }
  }
`
