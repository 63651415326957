import React from 'react'
import t from 'prop-types'
import { animated, useTransition } from 'react-spring'

export const FadeOutHide = ({ children, trigger }) => {
  const transitions = useTransition(trigger, null, {
    from: { opacity: 0, maxHeight: 500 },
    enter: { opacity: 1 },
    leave: {
      maxHeight: 0,
      opacity: 0,
      overflow: 'hidden',
    },
  })

  return transitions.map(({ item, key, props }) =>
    item && <animated.div key={key} style={props}>{children}</animated.div>
  )
}

FadeOutHide.propTypes = {
  children: t.node.isRequired,
  trigger: t.bool.isRequired,
}
