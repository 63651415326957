import React from 'react'
import t from 'prop-types'
import styled, { css } from 'styled-components'
import { Spinner, FormLabel, FormError, FormControl, media } from 'ui'
import SelectIcon from './select-icon.svg'

const Option = ({ value, label, isDisabled, id }) => (
  <option
    id={id}
    value={value}
    disabled={isDisabled}
  >
    {label}
  </option>
)

const Select = ({ items, name, label, disabled, inputRef, loading, error, ...props }) => {
  return (
    <FormControl horizontal>
      {label && <FormLabel>{label}</FormLabel>}
      <SelectWrapper className={`${disabled ? 'disabled' : ''}`}>

        {loading
          ? (
            <SpinnerContainer>
              <Spinner size='1.5rem' />
            </SpinnerContainer>
          ) : null}

        <SelectElement
          {...props}
          error={error}
          name={name}
          disabled={disabled || loading}
          ref={inputRef}
        >
          {items.map((item) => (
            <Option
              key={item.value}
              label={item.label}
              value={item.value}
              isDisabled={item.disabled}
              id={item.id}
            />
          ))}
        </SelectElement>
      </SelectWrapper>
      {error && <FormError> {error?.message || 'Campo obrigatório'}</FormError>}
    </FormControl>
  )
}

Option.propTypes = {
  label: t.node.isRequired,
  value: t.string,
  isDisabled: t.bool,
  id: t.string,
}

Select.propTypes = {
  items: t.array.isRequired,
  name: t.string.isRequired,
  label: t.string,
  disabled: t.bool,
  inputRef: t.func,
  loading: t.bool,
  error: t.object,
}

const SelectWrapper = styled.div`
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: .8rem;
    height: 1.6rem;
    right: 1.5rem;
    top: 50%;
    margin-top: -1.7rem;
    background: url(${SelectIcon}) center no-repeat;
    background-size: 100% auto;
  }

  ${props => props.loading && css`
     cursor: progress;
  `}

  &.disabled {
    opacity: .9;
    cursor: not-allowed;

    select {
      cursor: not-allowed;
    }

    &:after {
      opacity: .3;
    }
  }
`

const SelectElement = styled.select`
  padding: 1.7rem 2rem 1.7rem 1.7rem;
  border: 1px solid ${({ error, theme }) => error ? theme.colours.error : theme.colours.gray3};
  border-radius: ${({ theme }) => theme.radius.small};
  font-size: 1.4rem;
  width: 100%;
  appearance: none;
  background: ${({ theme }) => theme.colours.background} ;
  color: ${({ theme }) => theme.colours.text} ;
  box-shadow: 0 2px 4px rgba(230, 230, 230, .4);
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: ${({ theme }) => theme.font.default};
  margin-bottom: 2rem;

  &[disabled] {
    box-shadow: 0 3px 3px rgba(230, 230, 230, .5) inset;
  }

  &:focus {
    box-shadow: 0 0 20px rgba(0, 0, 0, .05);
    border-color: ${({ theme }) => theme.colours.primary};
  }

  ${media.lessThan('phone')`
    font-size: 1.6rem;
  `}
`

const SpinnerContainer = styled.div`
  position: absolute;
  top: -0.65rem;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 3.5rem;
`

export { Select }
