import React from 'react'
import ContentLoader from 'react-content-loader'
import { useLatestClasses } from './hooks'
import { ContentBox, VideoList, colours, radius, ErrorMessage } from 'ui'
import { errorMessages } from 'resources'

export const LatestClasses = () => {
  const { latestClasses, loading, error } = useLatestClasses()

  if (loading) return <ContentLoading />

  if (error) {
    return (
      <ContentBox title='Últimas aulas'>
        <ErrorMessage message={errorMessages.LATEST_CLASSES_ERROR} hasRefresh textSize='medium' />
      </ContentBox>
    )
  }

  return (
    <ContentBox title='Últimas aulas'>
      <VideoList items={latestClasses} />
    </ContentBox>
  )
}

const ContentLoading = () => (
  <ContentLoader
    speed={2}
    width={100}
    height={425}
    backgroundColor={colours.contentLoadingColor1}
    foregroundColor={colours.contentLoadingColor2}
    style={{ width: '100%', height: '42.5rem' }}
  >
    <rect x='0' y='0' rx={radius.large} ry={radius.large} width='100%' height='42.5rem' />
  </ContentLoader>
)
