import React from 'react'
import t from 'prop-types'
import { PieChart, Pie, Cell, Label } from 'recharts'
import styled from 'styled-components'
import { colours } from 'ui'

const DonutChart = ({ data }) => {
  const dataPie = [
    { name: 'Acertadas', value: data.answeredQuestions.rightAnswers, color: colours.success },
    { name: 'Erradas', value: (data.answeredQuestions.answered - data.answeredQuestions.rightAnswers), color: colours.gray3 },
  ]

  const rateOfRightAnswers = Math.round(100 * (data.answeredQuestions.rightAnswers / data.answeredQuestions.answered))

  const renderLabel = ({ viewBox }) => {
    const { cx, cy } = viewBox
    return (
      <>
        <LabelValue x={cx} y={cy - 10}>
          {rateOfRightAnswers}%
        </LabelValue>
        <LabelText x={cx} y={cy + 10}>
          de acertos
        </LabelText>
      </>
    )
  }

  return (
    <Wrapper>
      <PieChart width={260} height={180}>
        <Pie
          dataKey='value'
          data={dataPie}
          cx='50%'
          cy='50%'
          innerRadius={65}
          outerRadius={80}
          startAngle={90}
          endAngle={-270}
          stroke='none'
        >
          <Label content={renderLabel} position='center' />
          {dataPie.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </Wrapper>
  )
}

DonutChart.propTypes = {
  data: t.object.isRequired,
}

export { DonutChart }

const LabelValue = styled.text`
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-anchor: middle;
  dominant-baseline: middle;
  fill: ${({ theme }) => theme.colours.text};
`

const LabelText = styled.text`
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-anchor: middle;
  dominant-baseline: middle;
  fill: ${({ theme }) => theme.colours.text};
`

const Wrapper = styled.div`
  margin-top: -.8rem;
`
