import { gql } from '@apollo/client'

export const COMMENTED_TEST_DISCIPLINES_QUERY = gql`
  query GetCommentedTestDisciplines($slug: String!) {
    commentedTestDisciplines(slug: $slug) {
      id
      name
      slug
    }
  }
`
