import { useCallback, useMemo } from 'react'
import { mergeRight } from 'ramda'
import { store } from 'react-notifications-component'

const notificationConfig = {
  title: 'Algo deu errado',
  type: 'warning',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

export const Errors = {
  GRAPHQL_ERROR: 'GRAPHQL_ERROR',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
  SERVER_ERROR: 'SERVER_ERROR',
  I_DONT_KNOW_WHAT_THAT_ERROR_MEANS: 'I_DONT_KNOW_WHAT_THAT_ERROR_MEANS',
}

export const Messages = {
  UNEXPECTED_ERROR: 'Ocorreu um erro inesperado, por favor tente novamente em alguns minutos.',
  NOT_AUTHENTICATED: 'Você não está logado na plataforma. Faça login para continuar',
}

export const useError = ({ messages: customMessages } = {}) => {
  const messages = useMemo(() => mergeMessages(customMessages), [customMessages])

  const showError = useCallback(description => store.addNotification({
    message: description || Messages.UNEXPECTED_ERROR,
    ...notificationConfig,
  }), [])

  const dispatchError = useCallback(({ networkError, graphQLErrors }) => {
    if (hasError(networkError)) {
      const errors = networkError.result.errors

      errors.forEach(error => {
        const message = getError(messages, error.code)
        showError(message)
      })
      return
    }

    if (graphQLErrors && graphQLErrors.length > 0) {
      graphQLErrors.forEach(error => {
        const message = getError(messages, error.code)
        showError(message)
      })
      return
    }

    showError(Messages.UNEXPECTED_ERROR)
  }, [messages, showError])

  return [dispatchError]
}

const hasError = networkError => networkError?.result?.errors?.length > 0

const mappedErrors = {
  [Errors.GRAPHQL_ERROR]: Messages.UNEXPECTED_ERROR,
  [Errors.SERVER_ERROR]: Messages.UNEXPECTED_ERROR,
  [Errors.I_DONT_KNOW_WHAT_THAT_ERROR_MEANS]: Messages.UNEXPECTED_ERROR,
  [Errors.NOT_AUTHENTICATED]: Messages.NOT_AUTHENTICATED,
}

const mergeMessages = (messages = {}) => mergeRight(mappedErrors, messages)
const getError = (messages, code) => messages[code] || Errors.UNEXPECTED_ERROR
