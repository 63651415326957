import React from 'react'
import t from 'prop-types'
import { animated, useSpring } from 'react-spring'

export const Collapse = ({ children, trigger, size, customStyles = {} }) => {
  const props = useSpring({
    to: {
      height: trigger ? size : 0,
      overflow: 'hidden',
    },
  })

  const styles = {
    ...customStyles,
    ...props,
  }

  return <animated.div style={styles}>{children}</animated.div>
}

Collapse.propTypes = {
  children: t.node.isRequired,
  trigger: t.bool.isRequired,
  size: t.number.isRequired,
  customStyles: t.object,
}
