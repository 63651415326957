import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { TitleMedium, media } from 'ui'

const Timeline = ({ title, items }) => {
  return (
    <>
      <TitleMedium>{title}</TitleMedium>
      <List>
        {items.map(({ weekNumber, description, startsAt, totalTimeInSeconds }) => (
          <Item key={weekNumber}>
            <ItemTitle>Semana {weekNumber + 1} - {startsAt} </ItemTitle>
            <ItemContent dangerouslySetInnerHTML={{ __html: description }} />
          </Item>
        ))}
      </List>
    </>
  )
}

Timeline.propTypes = {
  title: t.string.isRequired,
  items: t.arrayOf(t.shape({
    weekNumber: t.number.isRequired,
    description: t.string.isRequired,
  })),
}

const List = styled.ul`
  padding-top: 2rem;

  ${media.greaterThan('tablet')`
    padding-right: 3rem;
  `}
`

const Item = styled.li`
  position: relative;
  padding-top: 3rem;
  list-style: none;

  &:before {
    content: '';
    width: 0.3rem;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -3rem;
    background: ${({ theme }) => theme.colours.gray4};
  }

  &:after {
    content: '';
    position: absolute;
    left: -3.35rem;
    top: 3.35rem;
    background: ${({ theme }) => theme.colours.secondary};
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
  }

  &:first-child {
    &:before {
      ${({ theme }) => `
        background: linear-gradient(to bottom, ${theme.colours.background}, ${theme.colours.gray4} 15%);
      `}
    }
  }

  &:last-child {
    padding-bottom: 3rem;

    ${({ theme }) => `
      &:before {
        background: linear-gradient(to bottom, ${theme.colours.gray4}, ${theme.colours.background} 100%);
      }
    `};
  }
`

const ItemTitle = styled.h3`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.secondary};
  margin-bottom: 1.4rem;
`

const ItemContent = styled.p`
  white-space: pre-line;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.textSecondary : theme.colours.gray1};
`

export { Timeline }
