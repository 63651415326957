import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import Paginate from 'react-paginate'

import arrowIcon from './arrow.svg'

import { media } from 'ui'

export const Pagination = ({
  page,
  total,
  onChange,
}) => {
  return (
    <Wrapper>
      <Paginate
        pageCount={total}
        initialPage={page - 1}
        onPageChange={data => onChange(data.selected + 1)}
        nextLabel={<Arrow left src={arrowIcon} />}
        previousLabel={<Arrow src={arrowIcon} />}
        containerClassName='pagination-container'
        pageClassName='page'
        pageLinkClassName='page-link'
        nextClassName='page-button'
        previousClassName='page-button'
        disabledClassName='page-button-inactive'
        breakClassName='break'
        activeClassName='active-page'
      />
    </Wrapper>
  )
}

Pagination.propTypes = {
  total: t.number.isRequired,
  page: t.number.isRequired,
  onChange: t.func.isRequired,
}

export const Arrow = styled.img`
  transform: ${p => p.left ? 'rotate(0)' : 'rotate(180deg)'};
  width: 8px;
`

const Wrapper = styled.div`
  .pagination-container {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 1rem 0;
  }

  .break {
    padding: 0.85rem;
    margin: 0 0.5rem;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colours.darkPurpleGray};
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .page {
    margin: 0 0.5rem;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colours.darkPurpleGray};
    font-weight: 600;
    transition: color 200ms;

    > a {
      padding: 1rem;
      outline: none;
      cursor: pointer;

      ${media.lessThan('phone')`
        padding: 1rem .6rem;
      `}
    }

    &:hover {
      color: ${({ theme }) => theme.colours.primary};
    }
  }

  .active-page {
    color: ${({ theme }) => theme.colours.primary};
    border-radius: 2px;
  }

  .page-button {
    display: flex;
    align-items: center;

    > a {
      display: flex;
      align-items: center;
      padding: 1rem;
      outline: none;
      cursor: pointer;
    }

    &:first-child {
      margin-right: 0.75rem;
    }

    &:last-child {
      margin-left: 0.75rem;
    }
  }

  .page-button-inactive {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
