import { useQuery, gql } from '@apollo/client'
import { defaultTo, map, pipe, prop } from 'ramda'

export const useDisciplinesFilter = () => {
  const { data, loading, error } = useQuery(DISCIPLINES_QUERY, {
    fetchPolicy: 'no-cache',
  })

  return {
    disciplines: transformDisciplines(data),
    loading,
    error,
  }
}

const toDiscipline = ({ id, name, slug }) => ({
  id,
  name,
  slug,
})

const transformDisciplines = pipe(
  prop('disciplines'),
  defaultTo([]),
  map(toDiscipline)
)

export const DISCIPLINES_QUERY = gql`
  query GetDisciplines {
    disciplines: simulatedsDisciplines {
      id
      name
      slug
    }
  }
`
