import React from 'react'
import styled, { css } from 'styled-components'
import { darken } from 'polished'
import styledBy from 'styled-by'
import t from 'prop-types'
import WarningIcon from './warning-icon.svg'
import { Link } from 'react-router-dom'

const GRADIENT_OVERLAY = theme => `
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(45deg, ${theme.colours.third} 0%, ${theme.colours.primary} 100%);
    z-index: ${theme.zIndex.hidden};
    transition: opacity .2s linear;
    opacity: 1;
  }
`

const GRADIENT_OVERLAY_SUCCESS = theme => `
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(45deg, ${theme.colours.success} 0%, ${darken(0.05, theme.colours.success)} 100%);
    z-index: ${theme.zIndex.hidden};
    transition: opacity .2s linear;
    opacity: 1;
  }
`

const GRADIENT_OVERLAY_HIDDEN = `
  &:before {
    opacity: 0;
  }
`

const capitalizedTextButton = css`
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: .1rem;
  padding: 1.2rem 4rem;
`

const defaultTextButton = css`
  font-size: 1.4rem;
  padding: 1.4rem 2.8rem;
  letter-spacing: 0;
  text-transform: none;
`

const defaultButton = css`
  ${({ theme }) => `
    background: ${theme.colours.primary};
    color: ${theme.darkTheme ? theme.colours.text : theme.colours.background};
    ${GRADIENT_OVERLAY(theme)};

    &:focus,
    &:hover {
      ${GRADIENT_OVERLAY_HIDDEN};
    }
  `}
`

const successButton = css`
  ${({ theme }) => `
    background: ${theme.colours.success};
    color: ${theme.colours.background};
    ${GRADIENT_OVERLAY_SUCCESS(theme)};

    &:focus,
    &:hover {
      ${GRADIENT_OVERLAY_HIDDEN};
    }
  `}
`

const ghostButton = css`
  background-color: transparent;

  ${({ theme }) => `
    color: ${theme.colours.primary};
    border: 2px solid ${theme.colours.primary};

    &:focus,
    &:hover {
      ${GRADIENT_OVERLAY(theme)};
      color: #fff;
    }
  `}
`
const renewNotificationButton = css`
  font-size: 1.5rem;
    padding: 10px;
    width: 100%;
  ${({ theme }) => `
    background: ${theme.colours.success};
    color: ${theme.colours.background};
    ${GRADIENT_OVERLAY_SUCCESS(theme)};

    &:focus,
    &:hover {
      ${GRADIENT_OVERLAY_HIDDEN};
    }
  `}
`
const smallButton = css`
  ${defaultButton};
  padding: .6rem 1.92rem;
`

const smallGhostButton = css`
  ${ghostButton};
  font-size: 1.2rem;
  padding: .6rem 1.92rem;
`

const warningButton = css`
  ${({ theme }) => `
    color: ${theme.colours.error};
    background-color: ${theme.colours.background};
    border: 2px solid ${theme.colours.error};

    &:focus,
    &:hover {
      color: ${theme.colours.background};
      background-color: ${theme.colours.error};

      &:before {
        filter: invert(0) sepia(0) saturate(1) hue-rotate(0deg) brightness(100);
      }
    }
  `}

  ${({ disabled }) => disabled && css`
    border: 1px solid #ccc;
  `}

  &:before {
    content: '';
    background: url(${WarningIcon}) no-repeat 100%/auto;
    display: inline-block;
    width: 1.7rem;
    height: 1.7rem;
    vertical-align: middle;
    margin-right: 1.4rem;
    margin-top: -.3rem;
  }
`

const taggedButton = css`
  background-color: transparent;
  font-size: 1.2rem;
  padding: 1.2rem 0;

  ${({ theme }) => `
    color: ${theme.colours.primary};
    border: 1px solid ${theme.colours.primary};

    &:focus,
    &:hover {
      color: ${theme.colours.background};
      background-color: ${theme.colours.primary};

      &:before {
        color: ${theme.colours.background};
        border-color: ${theme.colours.background};
      }
    }
  `}

  &:before {
    content: 'pdf';
    display: inline-block;
    border: 1px solid ${({ theme }) => theme.colours.primary};
    padding: 0.1rem 0.4rem;
    font-size: .84rem;
    text-transform: uppercase;
    border-radius: 3px;
    margin-right: .96rem;
    margin-top: -0.1rem;
    vertical-align: middle;
  }
`

export const Button = styled(({ capitalized, futureDate, ...props }) => <Link {...props} />)`
  border: none;
  position: relative;
  display: inline-block;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  transition: all .25s ease;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.radius.medium};
  width: ${({ size }) => size === 'full' ? '100%' : 'auto'};
  z-index: ${({ theme }) => theme.zIndex.base};

  ${({ capitalized }) => capitalized ? capitalizedTextButton : defaultTextButton}

  &:disabled {
    opacity: .5;
    cursor: default;
    pointer-events: none;
  }

  ${({ as }) => as === 'button' && `
    appearance: none;
  `}
  margin: ${({ withMargin }) => withMargin ? '.3rem' : '0'};

  opacity: ${({ futureDate }) => futureDate ? '.4' : '1'};

  ${styledBy('variant', {
    _: () => defaultButton,
    small: () => smallButton,
    smallGhost: () => smallGhostButton,
    ghost: () => ghostButton,
    warning: () => warningButton,
    tagged: () => taggedButton,
    success: () => successButton,
    renewNotification: () => renewNotificationButton,
  })}
`

Button.defaultProps = {
  variant: '_',
  size: 'auto',
  capitalized: false,
  disabled: false,
}

Button.propTypes = {
  variant: t.oneOf(['_', 'ghost', 'small', 'smallGhost', 'warning', 'tagged', 'success']),
  size: t.string,
  capitalized: t.bool,
  disabled: t.bool,
}
