import React from 'react'
import t from 'prop-types'
import styled, { css } from 'styled-components'

import playIcon from './icons/play-icon.svg'
import { formatSecondsToMinutes } from 'resources'
import { radius, zIndex } from 'ui/theme'
import { media } from 'ui/device'

const VideoTimeline = ({ items, activeItem, onSelect }) => {
  return (
    <Wrapper>
      {items.map((item, index) => {
        const isActive = index <= activeItem

        return (
          <>
            <Line active={isActive} />
            <Circle onClick={_ => onSelect(item, index)} active={isActive}>
              <Title>{item.title}</Title>
              {!isActive && <Icon src={playIcon} />}
              <Time>{formatSecondsToMinutes(item.timeInSeconds)}</Time>
            </Circle>
            <Line active={activeItem > index} />
          </>
        )
      })}
    </Wrapper>
  )
}

VideoTimeline.propTypes = {
  items: t.array,
  activeItem: t.number,
  onSelect: t.func,
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 2rem 4rem 2rem;

  ${media.lessThan('tablet')`
    overflow: auto;
  `}
`

const Line = styled.div`
  height: 0.6rem;
  flex-grow: 1;
  background: ${({ theme }) => theme.colours.darkPurpleGray};

  &:last-child {
    border-radius: 0 2px 2px 0;
  }

  &:first-child {
    border-radius: 2px 0 0 2px;
  }

  ${({ active, theme }) => active && css`
    background: ${theme.colours.primary};
  `}

  ${media.lessThan('tablet')`
    min-width: 2.4rem;
  `}
`

const Title = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colours.darkPurpleGray};
  color: white;
  padding: 1rem;
  border-radius: ${radius.small};
  bottom: 4.0rem;
  display: none;
  z-index: ${zIndex.aboveAll};
  font-size: 1.4rem;
  font-weight: 700;
  width: 15.0rem;
  left: 50%;
  transform: translateX(-50%);

  &:after {
    content: ' ';
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${({ theme }) => theme.colours.darkPurpleGray};
    bottom: -8px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
 `

const Circle = styled.button`
  border: 0;
  background: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.8rem;
  width: 1.8rem;
  cursor: pointer;

  &:after {
    cursor: pointer;
    content: ' ';
    height: 2.2rem;
    width: 2.2rem;
    background: ${({ theme }) => theme.colours.darkPurpleGray};
    position: absolute;
    top: -2px;
    bottom: 0;
    left: -1px;
    right: 0;
    border-radius: 50%;
    z-index: ${zIndex.base};
  }

  ${media.greaterThan('tablet')`
    &:hover > ${Title} {
      display: block;
    }
  `}


  ${({ active, theme }) => active && css`
    &:after {
      background: white;
      border: solid 4px ${theme.colours.primary};
    }
  `}
`

const Icon = styled.img`
  position: absolute;
  width: 0.6rem;
  left: .8rem;
  z-index: ${zIndex.top};
  cursor: pointer;
`

const Time = styled.span`
  position: absolute;
  color: ${({ theme }) => theme.colours.text};
  bottom: -2.6rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: -0.02em;
`

export { VideoTimeline }
