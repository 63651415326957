import { getToken } from 'resources'

export async function redirectToEventsPlatform (path) {
  const { token } = await getToken()
  // const darkMode = JSON.parse(localStorage.getItem('darkMode') || 'false')

  const url = new URL(path, getBaseUrl())

  url.searchParams.append('token', token)

  // if (darkMode) {
  //   url.searchParams.append('darkMode', darkMode)
  // }

  window.location.href = url
}

function getBaseUrl () {
  if (process.env.REACT_APP_SIMULADO_URL) {
    return process.env.REACT_APP_SIMULADO_URL
  }

  if (window.location.host.split('--')[0] === 'qa') {
    return 'https://qa--ferretto-simuladao.netlify.app'
  }

  return 'https://simuladaoenem.professorferretto.com.br'
}
