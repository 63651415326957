import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { darken, rgba } from 'polished'
import { ReactComponent as Icon } from './empty-icon.svg'
import { SimulatedNew } from 'ui'

export const EmptyMessage = ({ text = 'Escolha uma matéria', showIcon = true, bgColorWhite = false, simulated, openModalNewSimulated }) => {
  return (
    <Wrapper bgColorWhite={bgColorWhite}>
      {showIcon && <Icon />}
      <Text>{text}</Text>
      {simulated && (<SimulatedNew openModalNewSimulated={openModalNewSimulated} />)}
    </Wrapper>
  )
}

EmptyMessage.propTypes = {
  text: t.string,
  showIcon: t.bool,
  bgColorWhite: t.bool,
  simulated: t.bool,
  openModalNewSimulated: t.func,
}

const Wrapper = styled.div`
   background-color: ${({ theme, bgColorWhite }) => bgColorWhite ? theme.colours.background : theme.darkTheme ? theme.colours.background2 : rgba(theme.colours.purpleGray, 0.3)} ;
  border: 1px solid ${({ theme }) => darken(0.02, theme.colours.purpleGray)};
  border-radius: ${({ theme }) => theme.radius.large};
  min-height: 30rem;
  padding: 3rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Text = styled.p`
  margin: 0;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.secondary};
  padding-top: 1.5rem;
  font-weight: 600;
`
