import React, { useContext } from 'react'
import t from 'prop-types'
import { Radio } from 'ui/form/form'
import { letterFromNumber } from 'resources'
import {
  Button,
  TitleSmall,
  Toggle,
  ProgressBar,
  QuestionLevel,
  IconPDF,
  IconVideo,
} from 'ui'

import {
  Wrapper,
  Header,
  Title,
  Content,
  Answers,
  Stats,
  StatsColumn,
  Blank,
  SendButton,
  AddToNotebook,
  ResolutionButton,
  ProgressWrapper,
  SendArea,
  SpacedSpinner,
  AnswerWrapper,
  AlternativeStatsWrapper,
  AlternativeStatsItem,
  AlternativeStatsLabel,
  AlternativeStatsValue,
  AlternativeStatsList,
  ButtonLabel,
  Enunciated,
  WrapperButtonTitle,
  TitleLabel,
} from './components'
import { isNil, isEmpty } from 'ramda'
import ContentLoader from 'react-content-loader'
import { colours, radius } from 'ui/theme'
import { ThemeContext } from '../../providers'

const Question = ({
  code,
  institution,
  enunciated,
  answers,
  isSaved,
  onSelectAnswer,
  onAnswer,
  hasBookButton = true,
  checkedAnswer,
  isAnswering,
  onSeeVideoResolutionClick,
  onSeePDFResolutionClick,
  hasPDFResolution,
  hasVideoResolution,
  onToggleQuestionsBook,
  percentageOfRightAnswers,
  dificultyLevel,
  isAnswered,
  moreMarkedAlternatives,
  videoLinks,
}) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Wrapper>

      <Header>
        <Title>
          {institution && `${institution} | `}
          {code}
        </Title>
      </Header>

      <Content>
        <Enunciated>
          {renderText(theme, enunciated)}
        </Enunciated>
        <Answers>
          {answers.map(answer => {
            return (
              <AnswerWrapper key={answer.id}>
                <Radio
                  key={answer.id}
                  disabled={isAnswered}
                  value={answer.id}
                  name={answer.id}
                  label={renderText(theme, answer.content)}
                  isSelected={checkedAnswer === answer.id}
                  onChange={_ => onSelectAnswer(answer)}
                  isCorrect={isAnswered && answer.rightAnswer}
                  isWrong={isAnswered && !answer.rightAnswer && checkedAnswer === answer.id}
                  prefix={letterFromNumber(answer.order)}
                />
              </AnswerWrapper>
            )
          })}
        </Answers>
      </Content>

      <SendArea>
        {hasBookButton && (
          <AddToNotebook>
            <Toggle
              label='Adicionar ao meu caderno'
              checkedText='Remover do caderno'
              checked={isSaved}
              onChange={onToggleQuestionsBook}
            />
          </AddToNotebook>
        )}
        <SendButton>
          <Button
            disabled={getButtonStatus(isAnswered, checkedAnswer, isAnswering)}
            onClick={onAnswer}
            as='button'
          >
            Responder
          </Button>
        </SendButton>
        <SpacedSpinner visible={isAnswering} />
        <Blank />
      </SendArea>

      {isAnswered &&
        <>
          <Stats>
            <StatsColumn>
              <TitleSmall>Resolução</TitleSmall>
              {hasVideoResolution && videoLinks.map((item, i) => (
                <ResolutionButton key={i} onClick={() => onSeeVideoResolutionClick(i)} variant='success' as='button'>
                  <WrapperButtonTitle>
                    <ButtonLabel>Ver resolução</ButtonLabel>
                    <TitleLabel>{item.title}</TitleLabel>
                  </WrapperButtonTitle>
                  <IconVideo size={18} />
                </ResolutionButton>
              )

              )}
              {hasPDFResolution &&
                <ResolutionButton onClick={onSeePDFResolutionClick} variant='success' as='button'>
                  <ButtonLabel>Ver resolução</ButtonLabel>
                  <IconPDF size={18} />
                </ResolutionButton>}
            </StatsColumn>

            <StatsColumn>
              <TitleSmall>Percentual de acertos</TitleSmall>
              <ProgressWrapper>
                <ProgressBar
                  percentage={percentageOfRightAnswers}
                  background={theme.colours.background}
                  showPercentage
                />
              </ProgressWrapper>
            </StatsColumn>

            <StatsColumn>
              <TitleSmall>Nível de dificuldade</TitleSmall>
              <QuestionLevel level={dificultyLevel} />
            </StatsColumn>
          </Stats>

          <AlternativeStatsWrapper>
            <TitleSmall>ALTERNATIVAS MAIS MARCADAS</TitleSmall>
            <AlternativeStatsList>
              {moreMarkedAlternatives.map(({ alternative, percentageOfAnswers }, index) => (
                <AlternativeStatsItem key={index}>
                  <AlternativeStatsLabel>{letterFromNumber(alternative.order)}</AlternativeStatsLabel>
                  <AlternativeStatsValue>{percentageOfAnswers}%</AlternativeStatsValue>
                </AlternativeStatsItem>
              ))}
            </AlternativeStatsList>
          </AlternativeStatsWrapper>
        </>}
    </Wrapper>
  )
}

const getButtonStatus = (isAnswered, checkedAnswer, isAnswering) =>
  isEmpty(checkedAnswer) ||
    isNil(checkedAnswer) ||
    isAnswered ||
    isAnswering

const ContentLoading = () => (
  <Wrapper>
    <ContentLoader
      speed={2}
      width='100%'
      height={856}
      backgroundColor={colours.contentLoadingColor3}
      foregroundColor={colours.contentLoadingColor4}
    >
      <rect x='0' y='0' rx={radius.large} ry={radius.large} width='100%' height='40' />
      <rect x='1' y='51' rx={radius.large} ry={radius.large} width='100%' height='733' />
      <rect x='200' y='140' rx={radius.large} ry={radius.large} width='1' height='1' />
      <rect x='0' y='805' rx={radius.large} ry={radius.large} width='100%' height='40' />
    </ContentLoader>
  </Wrapper>
)

Question.propTypes = {
  code: t.string.isRequired,
  institution: t.string,
  enunciated: t.string.isRequired,
  answers: t.array.isRequired,
  isSaved: t.bool,
  onSelectAnswer: t.func.isRequired,
  onAnswer: t.func.isRequired,
  isAnswering: t.bool.isRequired,
  onSeeVideoResolutionClick: t.func,
  onSeePDFResolutionClick: t.func,
  hasPDFResolution: t.bool,
  hasVideoResolution: t.bool,
  onToggleQuestionsBook: t.func,
  hasBookButton: t.bool,
  checkedAnswer: t.string,
  percentageOfRightAnswers: t.number,
  isAnswered: t.bool,
  moreMarkedAlternatives: t.array,
  dificultyLevel: t.string,
  videoLinks: t.array,
}

Question.ContentLoading = ContentLoading

export { Question }

const renderText = (theme, text) => {
  const storedValue = localStorage.getItem('darkMode')
  const darkMode = storedValue ? storedValue.toLowerCase() === 'true' : false
  if (!darkMode) {
    return React.createElement('p', { dangerouslySetInnerHTML: { __html: text } })
  }

  const color = /color:\s*#[a-zA-Z0-9]+/gi
  const background = /background:\s*#[a-zA-Z0-9]+/gi
  const backgroundColor = /background-color:\s*#[a-zA-Z0-9]+/gi
  const newColor = text.replace(color, `color: ${theme.colours.text}`)
  const newBackground = newColor.replace(background, `background: ${theme.colours.mainBackground}`)
  const newBackgroundColor = newBackground.replace(backgroundColor, `background-color: ${theme.colours.background}`)

  return (
    <div dangerouslySetInnerHTML={{ __html: newBackgroundColor }} />
  )
}
