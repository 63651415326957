import React from 'react'
import t from 'prop-types'

import { useSupportDisciplineSelect } from './use-support-discipline-select'
import { Select } from 'ui/form/form'

export const SupportDisciplineSelect = ({ disabled, ...props }) => {
  const { supportDisciplines, loading } = useSupportDisciplineSelect()

  return <Select {...props} items={supportDisciplines} loading={loading} disabled={loading || disabled} />
}

SupportDisciplineSelect.propTypes = {
  disabled: t.bool,
}
