import React, { useState } from 'react'
import styled from 'styled-components'
import t from 'prop-types'
import { animated, useTransition, useSpring } from 'react-spring'

import { Button, media } from 'ui'
import { SIMULATED_STATUS, useMe, warningMessages } from 'resources'
import { PremiumContent } from 'ui/premium-content/premium-content'

const SimulatedCard = ({ title, data, status, url, description }) => {
  const [showDescription, setShowDescription] = useState(false)
  const { isFree } = useMe()

  const handleShowDescription = () => setShowDescription(true)
  const handleCloseDescription = () => setShowDescription(false)

  const transitions = useTransition(showDescription, null, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const slide = useSpring({
    transform: showDescription ? 'translateX(0)' : 'translateX(-10rem)',
  })

  return (
    <Wrapper active={status === SIMULATED_STATUS.STARTED}>
      {transitions.map(
        ({ key, item, props }) =>
          item && (
            <PreStarted key={key} style={props}>
              <Title>{title}</Title>
              <Warning>{warningMessages.START_SIMULATED}</Warning>
              <Action>
                <ActionInner>
                  <Button
                    as='button'
                    variant='ghost'
                    className='cancel-button'
                    onClick={handleCloseDescription}
                  >
                    Voltar
                  </Button>
                </ActionInner>
                <animated.span style={slide}>
                  <Button to={url} disabled={isFree}>
                    Iniciar
                  </Button>
                </animated.span>
              </Action>
            </PreStarted>
          )
      )}
      <MainContent>
        <Title status={status}>{title}</Title>
        {description && <Description>{description}</Description>}
        <Data>
          {data.map(({ title, value }) => {
            return (
              <DataRow key={title}>
                <DataTitle>{title}</DataTitle>
                <DataValue>{value}</DataValue>
              </DataRow>
            )
          })}
        </Data>
        <ButtonWrapper>
          {status === SIMULATED_STATUS.OPEN && (
            <Button
              as='button'
              onClick={handleShowDescription}
              disabled={isFree}
            >
              Participar
            </Button>
          )}
          {status === SIMULATED_STATUS.FINISHED && (
            <Button to={url} variant='ghost' disabled={isFree}>
              Resultados
            </Button>
          )}
          {status === SIMULATED_STATUS.STARTED && (
            <Button to={url} variant='success' disabled={isFree}>
              Continuar
            </Button>
          )}
        </ButtonWrapper>
      </MainContent>
    </Wrapper>
  )
}

SimulatedCard.propTypes = {
  title: t.string.isRequired,
  url: t.string.isRequired,
  description: t.string.isRequired,
  status: t.oneOf(Object.keys(SIMULATED_STATUS)),
  data: t.arrayOf(
    t.shape({
      title: t.oneOfType([t.number, t.string]).isRequired,
      value: t.oneOfType([t.number, t.string]).isRequired,
    })
  ),
}

const PreStarted = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => `
    background: ${theme.colours.purpleGray};
    z-index: ${theme.zIndex.top};
    border-radius: ${theme.radius.small};
    box-shadow: 0 0 0 2px ${theme.colours.background} inset;
  `}
`

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const Warning = styled.p`
  padding-top: 1rem;
  color: ${({ theme }) => theme.colours.text};
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: auto;
`

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ActionInner = styled.div`
  padding-top: 0.2rem;
`

const Wrapper = ({ children, active }) => {
  return (
    <PremiumContent active={active}>
      <StyledWrapper active={active}>{children}</StyledWrapper>
    </PremiumContent>
  )
}

Wrapper.propTypes = {
  children: t.node.isRequired,
  active: t.bool,
}

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 2rem 3rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  background: ${({ theme }) => theme.colours.background};
  border-radius: ${({ theme }) => theme.radius.small};
  z-index: ${({ theme }) => theme.zIndex.base};

  ${({ active, theme }) =>
    active &&
    `
    border: 1px solid ${theme.colours.success};
  `}

  ${Button} {
    font-size: 1.2rem;
    padding: 1rem 2rem;

    &.cancel-button {
      padding: 0.7em 1.4em 0.75em;
      margin-top: -3px;
    }
  }
`

const Title = styled.h3`
  padding: 0.4rem 0;
  color: ${({ theme }) => theme.colours.primary};
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.4;
  position: relative;

  ${media.lessThan('phone')`
    font-size: 1.4rem;
    padding: 0;
  `}
`

const Description = styled.p`
  padding: 1rem 0;
`

const Data = styled.div`
  padding: 1rem 0;
`

const DataRow = styled.div`
  padding-bottom: 1.2rem;
`

const DataTitle = styled.h6`
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: normal;
  color: ${({ theme }) =>
    theme.darkTheme ? theme.colours.textSecondary : theme.colours.gray1};
`

const DataValue = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
  padding-top: 0.5rem;
  color: ${({ theme }) => theme.colours.text};
`

const ButtonWrapper = styled.div`
  flex-basis: auto;
`

const SimulatedList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  grid-gap: 3rem;
`

export { SimulatedList, SimulatedCard }
