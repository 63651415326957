import { gql, useQuery } from '@apollo/client'
import { renameKeys } from 'resources/composable'
import { map, pipe, prepend } from 'ramda'
import { safelyGetFields } from 'resources'

const QUESTIONS_QUERY = gql`
  query GetQuestionsBook {
    questionsBook {
      code
      slug
    }
  }
`

export const useQuestionsFetcher = () => {
  const { data, loading } = useQuery(QUESTIONS_QUERY, {
    fetchPolicy: 'no-cache',
  })

  return {
    questions: toOptions(data),
    loading,
  }
}

const toOptions = pipe(
  safelyGetFields(['questionsBook']),
  map(
    renameKeys({
      code: 'label',
      slug: 'value',
    })
  ),
  prepend({
    label: 'Escolha uma questão',
    value: '',
  })
)
