import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { useWindowSize } from '@react-hook/window-size/throttled'
import ContentLoader from 'react-content-loader'

import { ContentBox, ErrorMessage, colours, radius, media, EssaysMenu } from 'ui'
import { errorMessages, useDisciplines } from 'resources'

export const Essays = ({ context, children }) => {
  const isWriteEssay = window.location.pathname.includes('escrever-redacao')
  const MyEssay = window.location.pathname.includes('/redacao')
  const [width] = useWindowSize()
  const showCollapsedFilter = width < 900
  const { disciplines, loading, error } = useDisciplines()
  const essays = disciplines.find(({ slug }) => slug === 'redacao')
  if (error) {
    return (
      <ContentBox>
        <ErrorMessage message={errorMessages.DISCIPLINES_ERROR} hasRefresh />
      </ContentBox>
    )
  }
  return (
    <Wrapper>
      <Content>{children}</Content>
      <Nav hidden={isWriteEssay || MyEssay}>
        <StickyContentBox
          title='Matérias'
          collapsable={showCollapsedFilter}
          collapsed={showCollapsedFilter}
        >
          {loading
            ? <ContentLoading />
            : (
              <>
                <EssaysMenu context={context} disciplines={essays} />
              </>
            )}
        </StickyContentBox>
      </Nav>
    </Wrapper>
  )
}

Essays.propTypes = {
  context: t.string.isRequired,
  children: t.oneOfType([
    t.arrayOf(t.node),
    t.node,
  ]).isRequired,
}

const Wrapper = styled.section`
      display: grid;
      grid-template-columns: 4fr 1fr;
      grid-template-areas:
      'content nav';
      grid-gap: 3rem;

      ${media.lessThan('tablet')`
    grid-template-columns: 1fr;
    grid-template-areas:
      'nav'
      'content';
  `}
      `

const Content = styled.div`
      grid-area: content;
      `

const Nav = styled.div`
      grid-area: nav;
      `

const StickyContentBox = styled(ContentBox)`
      ${media.greaterThan('tablet')`
    position: sticky;
    top: 2rem;
  `}
      `

const ContentLoading = () => (
  <ContentLoader
    speed={2}
    width={100}
    height={185}
    backgroundColor={colours.contentLoadingColor3}
    foregroundColor={colours.contentLoadingColor4}
    style={{ width: '100%', height: '18.5rem' }}
  >
    <rect x='0' y='0' rx={radius.small} ry={radius.small} width='100%' height='3.5rem' />
    <rect x='0' y='50' rx={radius.small} ry={radius.small} width='100%' height='3.5rem' />
    <rect x='0' y='100' rx={radius.small} ry={radius.small} width='100%' height='3.5rem' />
    <rect x='0' y='150' rx={radius.small} ry={radius.small} width='100%' height='3.5rem' />
  </ContentLoader>
)
