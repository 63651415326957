import { gql } from '@apollo/client'

export const ANNOUNCEMENT_QUERY = gql`
  query GetAnnouncement {
    announcement {
      id
      link
      image
      active
    }
  }
`

export const CLOSE_ANNOUNCEMENT_MUTATION = gql`
  mutation CloseAnnouncement {
    closeAnnouncement {
      id
      active
    }
  }
`
