import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import { useRouteMatch, Link } from 'react-router-dom'
import { media, ProgressBar } from 'ui'
import { setTextMaxLength } from 'resources'
import { theme } from 'ui/theme'

const Card = ({
  id,
  icon,
  title,
  url,
  data,
  disabled,
  progressValues,
  showProgressBar,
  onClick,
  totalVideoTime,
  institution,
}) => {
  const match = useRouteMatch()
  const croppedTitle = setTextMaxLength(title, 24)

  let pathTo = `${match.url}/${url}`
  if (id) {
    pathTo = `${match.url}/${url}/${id}`
  }

  const renderTime = () => {
    if (!totalVideoTime) return
    const hour = totalVideoTime.split(':')[0]
    const minutes = totalVideoTime.split(':')[1]

    return `${hour}h${minutes}min`
  }

  return (
    <Box
      to={pathTo}
      className={disabled ? 'disabled' : null}
      onClick={(evt) => onClick && onClick(evt)}
    >
      <Contents>
        <Icon>
          <img src={icon} alt={title} />
        </Icon>
        <Metadata>
          <Title>{croppedTitle}</Title>
          {data && <Data>{data}</Data>}
          {institution && <Data>{institution}</Data>}
        </Metadata>
      </Contents>
      {showProgressBar && (
        <ProgressBarWrapper>
          <ProgressBar
            completed={progressValues.completed}
            total={progressValues.total}
            foreground={theme.colours.primary}
            showCompletedOfTotal={progressValues.completed >= 0}
          />
        </ProgressBarWrapper>
      )}
      <TotalVideoTime>
        {renderTime()}
      </TotalVideoTime>
    </Box>
  )
}

Card.propTypes = {
  id: t.string,
  icon: t.string.isRequired,
  title: t.string.isRequired,
  url: t.string,
  data: t.string,
  progressValues: t.object,
  showProgressBar: t.bool,
  disabled: t.bool,
  onClick: t.func,
  totalVideoTime: t.string,
  institution: t.string,
}

const TotalVideoTime = styled.div`
    display: flex;
    flex-direction: row-reverse;
    color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.gray1};
    padding-top: 0.5rem;
`

const Box = styled(Link)`
  position: relative;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.colours.background};
  border-radius: ${({ theme }) => theme.radius.small};
  padding: 2rem;
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 0 20px ${({ theme }) => rgba(theme.colours.text, 0.05)};
  }

  ${media.lessThan('phone')`
    padding: 1.5em;
  `}

  &.disabled {
    color: currentColor;
    opacity: 0.5;
    text-decoration: none;
  }
`

const Icon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${({ theme }) => theme.colours.secondary};
  border-radius: ${({ theme }) => theme.radius.small};
  max-width: 6rem;
  height: 6rem;
  flex: 0 0 auto;
  margin-right: 2rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Metadata = styled.div`
  flex-basis: 70%;
`

const Title = styled.h4`
  margin: 0;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.secondary};
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.4;
`

const Data = styled.p`
  display: inline-block;
  margin: 0.8rem 0.8rem 0 0;
  padding: 0.1rem 0.8rem;
  color: ${({ theme }) => theme.colours.gray2};
  border: 1px solid ${({ theme }) => theme.colours.gray2};
  border-radius: ${({ theme }) => theme.radius.small};
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.4;
`

const CardList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));
  grid-gap: 3rem;
`

const Contents = styled.div`
  display: flex;
  align-items: flex-start;
`

const ProgressBarWrapper = styled.div`
  display: flex;
  padding-top: 10px;
`

export { Card, CardList }
