import { type } from 'ramda'

export const queryStringParser = (values, key) => {
  if (type(values[key]) === 'Array') {
    if (values[key].length) {
      return values[key]
    }
    return []
  }

  return typeof values[key] !== 'undefined' ? [values[key]] : []
}
