import { gql, useQuery } from '@apollo/client'
import { map, pipe, prepend, pathOr } from 'ramda'

const useCommunitySubjects = ({ valueName, emptyLabel }) => {
  const { data, loading } = useQuery(COMMUNITY_SUBJECTS_QUERY)

  const communitySubjects = pathOr([], ['communitySubjects'], data)

  return {
    subjects: transformOptions(emptyLabel, valueName, communitySubjects),
    loading,
  }
}

const COMMUNITY_SUBJECTS_QUERY = gql`
  {
    communitySubjects {
      slug
      id
      name
    }
  }
`
const toOption = valueName => ({ name, ...data }) => ({
  label: name,
  value: data[valueName],
})

const transformOptions = (emptyLabel, valueName, items) => pipe(
  map(toOption(valueName)),
  prepend({
    label: emptyLabel,
    value: '',
  })
)(items)

export { useCommunitySubjects }
