import React from 'react'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'
import { ProgressBar, colours, ErrorMessage, radius } from 'ui'
import { errorMessages } from 'resources'

import { useFeaturedStats } from './hooks'

const SidebarFeaturedStats = () => {
  const { featuredStats, error, loading } = useFeaturedStats()

  if (loading) return <ContentLoading />

  if (error) return <ErrorMessage message={errorMessages.FEATURED_STATS_ERROR} hasRefresh />

  return (
    <Wrapper>
      {featuredStats.map(({ type, resolved, total }) => {
        if (type === 'Simulados realizados' || type === 'Redações enviadas') {
          return (
            <Item key={type}>
              <Numbers>
                <List>
                  <StatsTitle>{type}</StatsTitle>
                  <NumbersText>{resolved}</NumbersText>
                </List>
              </Numbers>
            </Item>
          )
        }
        return (
          <Item key={type}>
            <Numbers>
              <List>
                <StatsTitle>{type}</StatsTitle>
                <NumbersText>{resolved}/{total}</NumbersText>
              </List>
              <ProgressBar
                completed={resolved}
                total={total}
                background={colours.gray4}
                foreground={colours.primary}
              />
            </Numbers>
          </Item>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: left;
`

const Item = styled.div`
  overflow: hidden;
  width: 100%;
  margin-bottom: 2.4rem;

  &:last-child {
    margin-bottom: .8rem;
  }
`

const StatsTitle = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 1.3rem;
  line-height: 1;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.textSecondary : theme.colours.gray1};
  font-weight: normal;
  text-transform: normal;
  flex-basis: 100%;
  margin-bottom: 0;
`

const Numbers = styled.div`
  height: 100%;
  flex-basis: 40%;
`

const NumbersText = styled.p`
  margin: 0;
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colours.gray2};
  font-weight: 600;
  text-align: right;
`

const List = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1em;
`

const LoadingWrapper = styled.div`
  padding: 2.4rem 0;
`

const ContentLoading = () => (
  <LoadingWrapper>
    <ContentLoader
      speed={2}
      width={100}
      height={184}
      backgroundColor={colours.contentLoadingColor3}
      foregroundColor={colours.contentLoadingColor4}
      style={{ width: '100%', height: '18.4rem' }}
    >
      <rect x='0' y='0' rx={radius.small} ry={radius.small} width='100%' height='2.8rem' />
      <rect x='0' y='52' rx={radius.small} ry={radius.small} width='100%' height='2.8rem' />
      <rect x='0' y='104' rx={radius.small} ry={radius.small} width='100%' height='2.8rem' />
      <rect x='0' y='156' rx={radius.small} ry={radius.small} width='100%' height='2.8rem' />
    </ContentLoader>
  </LoadingWrapper>
)

export { SidebarFeaturedStats }
