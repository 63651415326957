import React from 'react'
import t from 'prop-types'
import { IconArrowDown, MenuStudent, colours, AvatarPicture } from 'ui'
import { useMe } from 'resources'
import { Collapse, NotificationNotice } from 'springs'

import { useAvatar } from './hooks'
import {
  Wrapper,
  ArrowDown,
  Item,
  Text,
  Name,
  Mail,
  DropWrapper,
  ContentLoading,
  dropdownSpringStyles,
  dropdownSpringStylesDarkMode,
} from './components'

const Avatar = ({ className, showDrop = true, darkMode }) => {
  const { isOpen, setOpen, drop } = useAvatar()
  const { name, photo, email, hasSomeUnreadNotification, loading, error } = useMe()

  if (loading) return <ContentLoading />

  if (error) return null

  return (
    <Wrapper ref={drop} className={className} isOpen={isOpen}>
      <Item onClick={() => showDrop && setOpen(!isOpen)} isOpen={isOpen} data-testid='avatar'>
        <NotificationNotice trigger={hasSomeUnreadNotification} />
        <AvatarPicture name={name} photo={photo} size='4rem' />
        <Text>
          <Name>{name}</Name>
          <Mail>{email}</Mail>
        </Text>
        {showDrop && <ArrowDown><IconArrowDown color={colours.gray2} /></ArrowDown>}
      </Item>
      <Collapse trigger={isOpen} size={215} customStyles={darkMode ? dropdownSpringStylesDarkMode : dropdownSpringStyles}>
        <DropWrapper>
          <MenuStudent className='drop' textIsVisible />
        </DropWrapper>
      </Collapse>
    </Wrapper>
  )
}

Avatar.propTypes = {
  className: t.string,
  showDrop: t.bool,
  darkMode: t.bool,
}

export { Avatar }
