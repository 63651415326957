import React from 'react'
import styled from 'styled-components'
import { colours } from 'ui/theme'
import { createPortal } from 'react-dom'
import { useBetaReleaseNotice } from './use-beta-release-notice'

const BetaReleaseNotice = () => {
  const {
    betaVersion,
    isVisible,
    handleClose,
    changeVersion,
  } = useBetaReleaseNotice()

  const message = betaVersion ? 'Você está na versão beta das nossas estatísticas! Para retornar à versão anterior' : 'Estamos lançando uma nova versão beta das nossas estatísticas! Para habilitar esta nova versão'

  return createPortal(
    <Wrapper isVisible={isVisible}>
      <Banner>
        <Text>{message}<Highlight onClick={changeVersion}>Clique aqui!</Highlight></Text>
        <CloseArea onClick={handleClose}>X</CloseArea>
      </Banner>
    </Wrapper>, document.getElementById('header-portal-root')
  )
}

export { BetaReleaseNotice }

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(${({ isVisible }) => (isVisible ? '0' : '-100%')});
  transition: transform 500ms cubic-bezier(.455,.03,.515,.955);
  z-index: 1000;
`

const Banner = styled.div`
  background: #FFEA94;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Text = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  color: black;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 1rem;
`

const Highlight = styled.div`
  padding-left: .5rem;
  color: ${colours.primary};
  font-weight: 600;
  cursor: pointer;
`

const CloseArea = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${colours.gray3};
  cursor: pointer;
`
