import React from 'react'
import t from 'prop-types'
import { BarChart, Bar, XAxis, YAxis, Cell, ResponsiveContainer } from 'recharts'
import styled from 'styled-components'
import { media, colours } from 'ui'

const HorizontalBarChart = ({ data }) => {
  const height = data.length * 40

  return (
    <>
      <Wrapper>
        <Legend>
          {data.filter((s) => s.resolved > 0).map((entry, index) => (
            <React.Fragment key={index}>
              <Ball color={colours[entry.id]} />
              <Text>{entry.discipline}</Text>
            </React.Fragment>
          ))}
        </Legend>
        <ResponsiveContainer height={height}>
          <BarChart
            layout='vertical'
            data={data}
            margin={{
              top: 10,
              right: 20,
              bottom: 0,
              left: 20,
            }}
          >
            <XAxis type='number' hide />
            {data[0].legend ? (
              <YAxis dataKey='legend' axisLine={false} type='category' tick={{ fontSize: 12 }} />
            ) : (
              <YAxis dataKey='group' axisLine={false} type='category' tick={{ fontSize: 12 }} />
            )}
            {data[0].resolved ? (
              <Bar dataKey='resolved' background={{ fill: '#f0f0f0' }} label={{ position: 'insideRight', style: { fill: 'white' }, fontSize: 18 }}>
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colours[entry.id]} />
                ))}
              </Bar>
            ) : (
              <Bar dataKey='watched' background={{ fill: '#f0f0f0' }} label={{ position: 'insideRight', style: { fill: 'white' }, fontSize: 18 }}>
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colours[entry.id]} />
                ))}
              </Bar>
            )}
          </BarChart>
        </ResponsiveContainer>
      </Wrapper>
    </>
  )
}

HorizontalBarChart.propTypes = {
  data: t.arrayOf(t.shape({
    legend: t.string.isRequired,
    discipline: t.string.isRequired,
    resolved: t.number.isRequired,
    id: t.string.isRequired,
    group: t.string,
    watched: t.number,
  })).isRequired,
}

export { HorizontalBarChart }

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Legend = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  ${media.lessThan('phone')`
    margin-top: 2rem;
  `}
`

const Ball = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 100px;
  background: ${({ color }) => color};
  min-width: 9px;
`

const Text = styled.div`
  padding-left: .5rem;
  padding-right: 1.5rem;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.15px;
  ${media.lessThan('phone')`
    font-size: 10px;
  `}
`
