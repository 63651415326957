import { useForm } from 'react-hook-form'
import { useLocation, useHistory } from 'react-router'
import { parseQueryParams, renameKeys, flow } from 'resources/composable'
import { pipe, keys, evolve, isEmpty, reduce, assoc, isNil } from 'ramda'
import qs from 'qs'
import { useDisciplines, useMount } from 'resources'

export const useQuestionsFilter = onFilter => {
  const { loading: isFetchingDisciplines } = useDisciplines()
  const { search } = useLocation()
  const history = useHistory()
  const queryParams = parseParams(search)

  const {
    register,
    control,
    errors,
    handleSubmit,
    getValues,
    reset,
    setError,
  } = useForm({
    defaultValues: queryParams,
  })

  const onSubmit = values => {
    if (!values.code && !values.disciplineSlug) {
      setError('disciplineSlug', { type: 'custom', message: 'Ao menos um filtro deve ser selecionado' })
      return
    }
    const params = flow(values, validateSubjectsValues(queryParams), stringifyParams)

    const filters = flow(
      values,
      validateSubjectsValues(queryParams),
      evolve({
        disciplineSlug: defaultTo(values.disciplineSlug),
      })
    )

    history.push({
      search: decodeURIComponent(params),
    })

    reset(filters)
    onFilter(removeEmptyValues(filters))
  }

  useMount(() => {
    if (!isEmpty(queryParams)) onFilter(queryParams)
  })

  return {
    register,
    errors,
    handleSubmit,
    getValues,
    control,
    onSubmit,
    isFetchingDisciplines,
  }
}

const defaultTo = defaultValue => value => isEmpty(value) ? defaultValue : value

const parseValues = alias => evolve({
  status: key => alias[key],
  enem: key => alias[key],
})

const removeEmptyValues = values => pipe(
  keys,
  reduce(
    (filters, key) =>
      isEmpty(values[key])
        ? filters
        : assoc(key, values[key], filters),
    {})
)(values)

const parseParams = pipe(
  parseQueryParams,
  renameKeys({
    primario: 'parentSubjectSlug',
    disciplina: 'disciplineSlug',
    tipo: 'status',
    secundario: 'subjectSlug',
    codigo: 'code',
    banca: 'enem',
  }),
  parseValues({
    respondida: 'ANSWERED',
    'nao-respondida': 'NOT_ANSWERED',
    vestibular: 'false',
    enem: 'true',
  })
)

const stringifyParams = pipe(
  removeEmptyValues,
  parseValues({
    ANSWERED: 'respondida',
    NOT_ANSWERED: 'nao-respondida',
    // eslint-disable-next-line quote-props
    'false': 'vestibular',
    // eslint-disable-next-line quote-props
    'true': 'enem',
  }),
  renameKeys({
    parentSubjectSlug: 'primario',
    disciplineSlug: 'disciplina',
    status: 'tipo',
    subjectSlug: 'secundario',
    code: 'codigo',
    enem: 'banca',
  }),
  qs.stringify
)

const resetFieldValue = condition => currentValue => condition ? '' : currentValue

const validateSubjectsValues = queryParams => values => {
  const disciplineHasChanged = queryParams.disciplineSlug !== values.disciplineSlug && !isNil(queryParams.disciplineSlug)
  const sameParentSubject = values.parentSubjectSlug === queryParams.parentSubjectSlug
  const sameSubject = values.subjectSlug === queryParams.subjectSlug

  const shouldResetParentSubject = disciplineHasChanged && sameParentSubject
  const shouldResetSuject = disciplineHasChanged || (!sameParentSubject && sameSubject)

  return evolve({
    parentSubjectSlug: resetFieldValue(shouldResetParentSubject),
    subjectSlug: resetFieldValue(shouldResetSuject),
  })(values)
}
