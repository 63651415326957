import { useQuery } from '@apollo/client'
import t from 'prop-types'
import { setTextMaxLength } from 'resources'
import React, { useMemo } from 'react'
import { path, pipe, map, defaultTo, evolve } from 'ramda'

import { GET_ME_QUERY } from './graphql'
import { formatDate } from 'resources/utils'

const Context = React.createContext({ loading: true })

export const useMe = () => React.useContext(Context)

export const UserDataProvider = ({ children }) => {
  const { data, loading, error, refetch } = useQuery(GET_ME_QUERY, {
    fetchPolicy: 'no-cache',
  })

  const isFree = useMemo(() => data?.me?.role === undefined ? undefined : data?.me?.role === 'free', [data])

  return (
    <Context.Provider
      value={{
        id: data?.me?.id,
        isMonitor: data?.me?.isMonitor,
        isFree,
        name: data?.me?.name,
        photo: data?.me?.photo,
        startedStudyPlan: data?.me?.startedStudyPlan,
        hasSomeUnreadNotification: data?.me?.hasSomeUnreadNotification,
        hasSupport: data?.me?.hasSupport,
        email: setTextMaxLength(data?.me?.email, 25),
        expired: data?.me?.expired,
        hasRenewalDiscount: data?.me?.hasRenewalDiscount,
        expiresIn: formatDate(data?.me?.expiresIn),
        plans: mapPlans(data),
        canUseCommunity: data?.me?.canUseCommunity,
        loading,
        error,
        refetch,
      }}
    >
      {children}
    </Context.Provider>
  )
}

const mapPlans = pipe(
  path(['me', 'plans']),
  defaultTo([]),
  map(
    evolve({
      initialDate: formatDate,
      finalDate: formatDate,
    })
  )
)

UserDataProvider.propTypes = {
  children: t.node.isRequired,
}
