import { reduce, curry, keys, assoc, split, remove, join, pipe, merge, dissoc } from 'ramda'
import qs from 'qs'

export const parseQueryParams = pipe(
  split(''),
  remove(0, 1),
  join(''),
  qs.parse
)

export const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
)

export const indexEq = curry((value, _, index) => index === value)

export const spreadProps = curry(
  (keys, data) => keys.reduce((acc, key) => pipe(
    merge(data[key]),
    dissoc(key)
  )(acc), data)
)

export const conditionalMap = curry((test, left, right, items) => items.map((value, index) => test(value, index) ? left(value) : right(value)))

export const flow = (arg, ...functions) => pipe(...functions)(arg)
