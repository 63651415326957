import React from 'react'
import t from 'prop-types'

export const IconPDF = ({ color = '#fff', size = 24 }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 263.277 246.743'>
    <path id='Union_3' data-name='Union 3' d='M68,236.743V167H18A18,18,0,0,1,0,149V99A18,18,0,0,1,18,81H68V0H188.462l1.609,1.447L251.83,63.206l1.447,1.609V236.743ZM78.293,226.45H242.984V72.052H181.225V10.293H78.293V81H135a18,18,0,0,1,18,18v50a18,18,0,0,1-18,18H78.293ZM191.518,61.759h44.068L191.518,17.691Z' transform='translate(5 5)' fill='#f8f8f8' stroke='#f8f8f8' strokeWidth='10' />
  </svg>
)

IconPDF.propTypes = {
  color: t.string,
  size: t.number,
}
