import React from 'react'
import { Item } from 'ui/study-plan/components'
import { Button, InputText } from 'ui'

import { Overlay, WrapperDialog, WrapperContent, Box, Text, Form, ActionsModal } from './compenents'

import t from 'prop-types'

const MagicWordModal = ({ errors, register, validMagicWord, MAGIC_WORD, handleSubmit, onSubmit, onClose, disabled }) => {
  return (
    <>
      <Overlay>
        <WrapperDialog>
          <WrapperContent>
            <Box>
              <h1>Aviso!</h1>
              <Text>Ao prosseguir com a troca do seu plano de estudo, você <b>não poderá retornar</b> ao cronograma anterior.</Text>
              <Item>
                <InputText
                  id='magicWord'
                  name='magicWord'
                  label={`Digite a palavra ${MAGIC_WORD}`}
                  placeholder={MAGIC_WORD}
                  error={errors.magicWord}
                  register={register}
                  type='text'
                  onChange={e => { validMagicWord(e.target.value) }}
                  inputRef={register({
                    required: 'Campo obrigatório',
                    minLength: {
                      value: 3,
                    },
                  })}
                />
              </Item>
            </Box>
          </WrapperContent>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ActionsModal>
              <Button variant='ghost' as='button' onClick={onClose}>Cancelar</Button>
              <Button as='button' disabled={disabled}>Sim, quero mudar! </Button>
            </ActionsModal>
          </Form>
        </WrapperDialog>
      </Overlay>
    </>
  )
}

MagicWordModal.propTypes = {
  register: t.func.isRequired,
  errors: t.string,
  validMagicWord: t.func.isRequired,
  MAGIC_WORD: t.string,
  handleSubmit: t.func.isRequired,
  onSubmit: t.func.isRequired,
  onClose: t.func.isRequired,
  disabled: t.string,
}

export { MagicWordModal }
