import React, { useEffect, useState, createContext } from 'react'
import t from 'prop-types'

import { ThemeProvider } from 'styled-components'

import { theme, darkTheme } from 'ui'

const ThemeContext = createContext()

const Providers = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false)
  useEffect(() => {
    const storedValue = localStorage.getItem('darkMode')
    if (storedValue !== null) {
      setDarkMode(storedValue === 'true')
    }
  }, [])

  const toggleDarkMode = () => {
    const newValue = !darkMode
    setDarkMode(newValue)
    localStorage.setItem('darkMode', newValue)
  }

  const themeValue = {
    darkMode,
    toggleDarkMode,
    theme: darkMode ? darkTheme : theme,
  }

  return (
    <ThemeContext.Provider value={themeValue}>
      <ThemeProvider theme={themeValue.theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  )
}

Providers.propTypes = {
  children: t.node.isRequired,
}

export { Providers, ThemeContext }
