import { lazy } from 'react'

export const HOME = '/'
export const LOGIN = '/login'
export const LOGOUT = '/logout'
export const REGISTER = '/register'
export const RECOVER_PASSWORD = '/recuperar-senha'
export const NEW_PASSWORD = '/nova-senha'
export const CLASSES = '/aulas'
export const CLASSES_GROUP = '/aulas/:discipline'
export const CLASS_GROUP = '/aulas/:discipline/:slug/:id'
export const REGIONAL = '/regionais'
export const REGIONALS_GROUP = '/regionais/:state'
export const REGIONAL_GROUP = '/regionais/:state/:slug/:id'
export const DISCURSIVE_COURSE = '/curso-de-discursivas'
export const SINGLE_DISCURSIVE_COURSE = '/curso-de-discursivas/:slug'
export const SINGLE_CLASS = '/aula/:slug'
export const ESSAYS = '/redacoes'
export const ESSAYS_GROUP = '/redacoes/aulas/:slug'
export const ESSAY_GROUP = '/redacoes/aulas/:slug/:slug/:id'
export const ESSAYS_THEME_GROUP = '/redacoes/temas/:slug'
export const ESSAY_THEME_GROUP = '/redacoes/temas/:slug/:themeId'
export const WRITE_ESSAY = '/redacoes/temas/:slug/:themeId/escrever-redacao'
export const MY_ESSAYS_GROUP = '/redacoes/minhas-redacoes'
export const ESSAY_DETAIL = '/redacoes/minhas-redacoes/redacao/:id'
export const PLAY = '/play/:discipline/:parentSlug/:type'
export const PLAYREGIONAL = '/play/:state/:parentSlug/:type'
export const COMMENTED_TESTS = '/provas-comentadas'
export const COMMENTED_TESTS_DISCIPLINES = '/provas-comentadas/:slug'
export const SINGLE_COMMENTED_TEST = '/provas-comentadas/:slug/:discipline'
export const COMMENTED_TEST_VIDEO =
  '/video-prova-comentada/:slug/:discipline/:video'
export const DISCURSIVE_COURSE_VIDEO =
  '/video-curso-de-discursiva/:discipline/:video'
export const STUDY_PLAN = '/planos-de-estudo'
export const QUESTION_COURSE = '/curso-por-questoes'
export const STUDY_PLAN_VIDEO =
  '/video-plano-estudo/:discipline/:studyPlan/:week/:class'
export const STUDY_PLAN_PRE_START = '/planos-de-estudo/cronograma/:slug'
export const QUESTION_COURSE_PRE_START = '/curso-por-questoes/cronograma/:slug'
export const STUDY_PLAN_DISCIPLINES = '/planos-de-estudo/:slug'
export const QUESTION_COURSE_DISCIPLINES = '/curso-por-questoes/:slug'
export const SINGLE_STUDY_PLAN = '/planos-de-estudo/:slug/:discipline'
export const SINGLE_QUESTION_COURSE = '/curso-por-questoes/:slug/:discipline'
export const QUESTIONS = '/questoes'
export const QUESTIONS_BOOK = '/caderno-de-questoes'
export const STATS = '/estatisticas'
export const CLASSES_DETAILED = '/aulas-detalhadas'
export const QUESTIONS_DETAILED = '/questões-detalhadas'
export const ESSAY_DETAILED = '/redações-detalhadas'
export const SIMULATED_DETAILED = '/simulados-detalhados'
export const SIMULATED = '/simulados'
export const SINGLE_SIMULATED = '/simulado/:slug'
export const COMMUNITY_AREA = '/area-da-comunidade'
export const COMMUNITY = '/area-da-comunidade/comunidade'
export const COMMUNITY_NEW = '/comunidade/novo-topico'
export const COMMUNITY_SINGLE = '/comunidade/:id'
export const COMMUNITY_NOTIFICATIONS = '/area-da-comunidade/notificacoes'
export const STUDENT_AREA = '/area-do-aluno'
export const STUDENT_DATA_AREA = '/area-do-aluno/meus-dados'
export const NOTIFICATIONS = '/area-do-aluno/notificacoes'
export const MY_PLANS = '/area-do-aluno/meus-planos'
export const HELP_CENTER = '/central-de-ajuda'
export const RENEW_PLAN = '/renovar-plano'
export const SIMULATED_STATS = '/estatisticas-simulados/:slug'
export const VERSION = '/versao'
export const NOT_FOUND = '/*'

export const SUPPORT = '/monitoria'
export const SUPPORT_LIST = '/monitoria/:disciplineId'
export const SUPPORT_SINGLE = '/monitoria-chamado/:id'
export const SUPPORT_NEW = '/monitoria-novo-chamado'

export const LIVES = '/lives'
export const BOOK_ANALYSIS = '/analise-obras'
export const WATCH_LIVES = '/lives/:id/play/:youtubeid'
export const WATCH_LIVES_RECORDED =
  '/lives/:id/play/recorded-lives/:youtubeid/:type'

export const THEMES_ANALYSE = '/redacoes/analise-de-temas-de-redacao/:slug'
export const THEME_ANALYSE =
  '/redacoes/analise-de-temas-de-redacao/:slug/:slug/:id'

const Home = lazy(() => import('./pages/home'))
const Version = lazy(() => import('./pages/version/version'))
const ClassesPage = lazy(() => import('./pages/classes'))
const RegionalsPage = lazy(() => import('./pages/regionals'))
const SingleClassPage = lazy(() => import('./pages/single-class'))
const EssaysPage = lazy(() => import('./pages/essays'))
const PlayPage = lazy(() => import('./pages/play'))
const CommentedTestsPage = lazy(() => import('./pages/commented-tests'))
const DiscursiveCoursePage = lazy(() => import('./pages/discursive-course'))
const CommentedTestsDisciplinesPage = lazy(() =>
  import('./pages/commented-tests-disciplines')
)
const StudyPlanPage = lazy(() => import('./pages/study-plan'))
const QuestionCoursePage = lazy(() => import('./pages/question-course'))
const StudyPlanDisciplinesPage = lazy(() =>
  import('./pages/study-plan-disciplines')
)
const QuestionCourseDisciplinesPage = lazy(() =>
  import('./pages/question-course-disciplines')
)
const QuestionsPage = lazy(() => import('./pages/questions'))
const QuestionsBookPage = lazy(() => import('./pages/questions-book'))
const StatsPage = lazy(() => import('./pages/stats'))
const StatsDashboardPage = lazy(() => import('./pages/statsDashboard'))
const ClassesDetailedPage = lazy(() => import('./pages/stats-detailed/classes'))
const QuestionsDetailedPage = lazy(() => import('./pages/stats-detailed/questions'))
const EssaysDetailedPage = lazy(() => import('./pages/stats-detailed/essays'))
const SimulatedsDetailedPage = lazy(() => import('./pages/stats-detailed/simulateds'))
const SimulatedPage = lazy(() => import('./pages/simulated'))
const SingleSimulatedPage = lazy(() => import('./pages/single-simulated'))
const StudentAreaPage = lazy(() => import('./pages/student-area'))
const CommunityPage = lazy(() => import('./pages/community'))
const CommunityNewPage = lazy(() => import('./pages/community-new'))
const SingleCommunityPage = lazy(() => import('./pages/single-community'))
const HelpCenterPage = lazy(() => import('./pages/help-center'))
const SupportPage = lazy(() => import('./pages/support'))
const SupportNewPage = lazy(() => import('./pages/support-new'))
const SingleSupportPage = lazy(() => import('./pages/support-single'))
const RenewPlanPage = lazy(() => import('./pages/renew-plan'))
const SingleSimulatedFinished = lazy(() =>
  import('./pages/single-simulated-finished')
)
const CommentedTestVideo = lazy(() => import('./pages/commented-test-video'))
const DiscursiveCourseVideo = lazy(() =>
  import('./pages/discursive-course-video')
)
const StudyPlanVideo = lazy(() => import('./pages/study-plan-video'))
export const RenovationPage = lazy(() => import('./pages/renovation'))
const Lives = lazy(() => import('pages/lives/livesRoutes'))
const BookAnalysis = lazy(() => import('pages/book-analysis'))
const NotFound = lazy(() => import('./pages/not-found'))

export const routeConfig = [
  {
    path: HOME,
    component: Home,
    breadcrumb: 'Início',
    exact: true,
    title: 'Dashboard',
    allowedToFree: false,
  },
  {
    path: CLASSES,
    component: ClassesPage,
    breadcrumb: 'Aulas',
    allowedToFree: true,
  },
  {
    path: REGIONAL,
    component: RegionalsPage,
    breadcrumb: 'Cursos regionais',
    allowedToFree: false,
  },
  {
    path: LIVES,
    component: Lives,
    breadcrumb: 'Lives',
    allowedToFree: true,
  },
  {
    path: BOOK_ANALYSIS,
    component: BookAnalysis,
    breadcrumb: 'Análise de obras',
    allowedToFree: false,
  },
  {
    path: SINGLE_CLASS,
    component: SingleClassPage,
    breadcrumb: 'Aula',
    exact: true,
    allowedToFree: true,
  },
  {
    path: ESSAYS,
    component: EssaysPage,
    breadcrumb: 'Redações',
    allowedToFree: false,
  },
  {
    path: COMMENTED_TESTS,
    component: CommentedTestsPage,
    breadcrumb: 'Provas comentadas',
    exact: true,
    allowedToFree: false,
  },
  {
    path: DISCURSIVE_COURSE,
    component: DiscursiveCoursePage,
    breadcrumb: 'Curso de discursivas',
    allowedToFree: false,
  },
  {
    path: COMMENTED_TESTS_DISCIPLINES,
    component: CommentedTestsDisciplinesPage,
    breadcrumb: 'Provas comentadas',
    allowedToFree: false,
  },
  {
    path: STUDY_PLAN,
    component: StudyPlanPage,
    breadcrumb: 'Planos de estudo',
    exact: true,
    allowedToFree: false,
  },
  {
    path: QUESTION_COURSE,
    component: QuestionCoursePage,
    breadcrumb: 'Curso por questões',
    exact: true,
    allowedToFree: false,
  },
  {
    path: STUDY_PLAN_DISCIPLINES,
    component: StudyPlanDisciplinesPage,
    breadcrumb: 'Plano de estudo',
    allowedToFree: false,
  },
  {
    path: QUESTION_COURSE_DISCIPLINES,
    component: QuestionCourseDisciplinesPage,
    breadcrumb: 'Curso por questões',
    allowedToFree: false,
  },
  {
    path: QUESTIONS,
    component: QuestionsPage,
    breadcrumb: 'Questões',
    exact: true,
    allowedToFree: false,
  },
  {
    path: QUESTIONS_BOOK,
    component: QuestionsBookPage,
    breadcrumb: 'Caderno de questões',
    exact: true,
    allowedToFree: false,
  },
  {
    path: STATS,
    component: process.env.REACT_APP_BETA_DASH === 'true' ? (localStorage.getItem('betaVersion') === 'true' ? StatsDashboardPage : StatsPage) : StatsPage,
    breadcrumb: 'Estatísticas',
    exact: true,
    allowedToFree: false,
  },
  {
    path: CLASSES_DETAILED,
    component: ClassesDetailedPage,
    breadcrumb: 'Aulas Detalhadas',
    exact: true,
    allowedToFree: false,
  },
  {
    path: QUESTIONS_DETAILED,
    component: QuestionsDetailedPage,
    breadcrumb: 'Questões Detalhadas',
    exact: true,
    allowedToFree: false,
  },
  {
    path: ESSAY_DETAILED,
    component: EssaysDetailedPage,
    breadcrumb: 'Redações Detalhadas',
    exact: true,
    allowedToFree: false,
  },
  {
    path: SIMULATED_DETAILED,
    component: SimulatedsDetailedPage,
    breadcrumb: 'Simulados Detalhados',
    exact: true,
    allowedToFree: false,
  },
  {
    path: SIMULATED,
    component: SimulatedPage,
    breadcrumb: 'Simulado',
    exact: true,
    allowedToFree: true,
  },
  {
    path: SINGLE_SIMULATED,
    component: SingleSimulatedPage,
    breadcrumb: 'Simulado',
    exact: true,
    allowedToFree: false,
  },
  {
    path: SIMULATED_STATS,
    component: SingleSimulatedFinished,
    breadcrumb: 'Estatísticas do simulado',
    exact: true,
    allowedToFree: true,
  },
  {
    path: COMMUNITY_AREA,
    component: CommunityPage,
    breadcrumb: 'Comunidade',
    exact: false,
    allowedToFree: false,
  },
  {
    path: COMMUNITY_NEW,
    component: CommunityNewPage,
    breadcrumb: 'Novo tópico',
    exact: true,
    allowedToFree: false,
  },
  {
    path: COMMUNITY_SINGLE,
    component: SingleCommunityPage,
    breadcrumb: 'Tópico',
    exact: false,
    allowedToFree: false,
  },
  {
    path: HELP_CENTER,
    component: HelpCenterPage,
    breadcrumb: 'Central de ajuda',
    exact: true,
    allowedToFree: false,
  },
  {
    path: SUPPORT,
    component: SupportPage,
    breadcrumb: 'Monitoria',
    allowedToFree: false,
  },
  {
    path: SUPPORT_NEW,
    component: SupportNewPage,
    breadcrumb: 'Abrir Chamado',
    exact: true,
    allowedToFree: false,
  },
  {
    path: SUPPORT_SINGLE,
    component: SingleSupportPage,
    breadcrumb: 'Monitoria',
    exact: true,
    allowedToFree: false,
  },
  {
    path: STUDENT_AREA,
    component: StudentAreaPage,
    breadcrumb: 'Área do aluno',
    exact: false,
    allowedToFree: true,
  },
  {
    path: PLAY,
    component: PlayPage,
    breadcrumb: 'Play',
    exact: false,
    allowedToFree: true,
  },
  {
    path: RENEW_PLAN,
    component: RenewPlanPage,
    breadcrumb: 'Renovar plano',
    exact: false,
    allowedToFree: false,
  },
  {
    path: COMMENTED_TEST_VIDEO,
    component: CommentedTestVideo,
    breadcrumb: 'Prova comentada',
    exact: false,
    allowedToFree: false,
  },
  {
    path: DISCURSIVE_COURSE_VIDEO,
    component: DiscursiveCourseVideo,
    breadcrumb: 'Curso de discursivas',
    exact: false,
    allowedToFree: false,
  },
  {
    path: STUDY_PLAN_VIDEO,
    component: StudyPlanVideo,
    breadcrumb: 'Vídeo do plano de estudo',
    exact: false,
    allowedToFree: false,
  },
  {
    path: VERSION,
    component: Version,
    breadcrumb: 'Versão da plataforma',
    exact: false,
    allowedToFree: false,
  },
  {
    path: NOT_FOUND,
    component: NotFound,
    breadcrumb: 'Página não encontrada',
    exact: false,
    allowedToFree: true,
  },
]
