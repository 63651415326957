import { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useClickOutside from 'use-click-outside'

export const useAvatar = () => {
  const [isOpen, setOpen] = useState(false)
  const drop = useRef()
  const { pathname } = useLocation()

  useClickOutside(drop, () => setOpen(false))

  useEffect(() => {
    setOpen(false)
  }, [pathname])

  return {
    isOpen,
    setOpen,
    drop,
  }
}
