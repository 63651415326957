
import styled from 'styled-components'
import { animated } from 'react-spring'
import { media } from 'ui'
import { rgba } from 'polished'

export const Overlay = styled(animated.div)`
position: fixed;
top: 0;
right: 0;
width: 100%;
height: 100%;
z-index: 40;
overflow: hidden;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: row;
-ms-flex-direction: row;
flex-direction: row;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
background:${({ theme }) => rgba(theme.colours.background, 0.8)};
border-radius: 20px;
-webkit-backdrop-filter: blur(.2rem);
backdrop-filter: blur(0.2rem);
align-items: center;
`

export const WrapperDialog = styled.div`
background: ${({ theme }) => theme.darkTheme ? theme.colours.mainBackground : theme.colours.background};
padding: 3rem;
border-radius: 15px;
height: 50%;
  position: relative;
${media.lessThan('phone')`
  background: ${({ theme }) => theme.darkTheme ? theme.colours.mainBackground : theme.colours.background};
  padding: 3rem;
  border-radius: 15px;
  height: 76%;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
`}
`

export const WrapperContent = styled.div`
display: flex;
${media.lessThan('phone')`
  display: flex;
  flex-direction: column;
  align-content: center;
`}
`

export const Box = styled.div`
${media.lessThan('phone')`
  width: 100%;
  flex:0;
  margin:0;
`}
width: 470px;
`
export const Form = styled.form`

`

export const ActionsModal = styled.div`
 margin-top: 1rem;
 width: 100%;
  text-align: right;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
${media.lessThan('phone')`
  flex-direction: column;
  gap: 1rem;
  > a,
  > button {
    width: 100%;
  }
`}
`

export const Text = styled.div`
font-size: 1.2rem;
padding: 2rem;
`
