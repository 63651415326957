export const cep = async value => {
  const zipcode = value.replace(/\D/g, '')

  if (zipcode.length === 8) {
    const response = await fetch(`https://viacep.com.br/ws/${value}/json/`)
    const json = await response.json()

    return !!json.cep
  } else {
    return false
  }
}
