import React from 'react'
import t from 'prop-types'

import { useDisciplinesFetcher } from './use-disciplines-fetcher'
import { Select } from 'ui'

export const DisciplineSelect = ({ disabled = false, emptyLabel = 'Selecione uma disciplina', valueName = 'slug', withQuestions, ...props }) => {
  const { disciplines, loading } = useDisciplinesFetcher({
    valueName,
    emptyLabel,
    withQuestions,
  })
  return (
    <Select
      items={disciplines}
      disabled={disabled}
      loading={loading}
      {...props}
    />
  )
}

DisciplineSelect.propTypes = {
  disabled: t.bool,
  valueName: t.string,
  emptyLabel: t.string,
  withQuestions: t.bool,
}
