import { gql, useLazyQuery } from '@apollo/client'
import { getNodes } from 'resources'
import { pipe, map, prepend } from 'ramda'
import { useEffect } from 'react'

export const SUBJECTS_QUERY = gql`
  query GetSubjects($filter:FilterSubjectsInput!){
    subjects (filter: $filter){
     nodes {
       id
       slug
       name
      }
    }
  }
`

export const useSubjectsFetcher = ({ onlyParents, parentSlug, disciplineSlug }) => {
  const [fetchSubjects, { data, loading }] = useLazyQuery(SUBJECTS_QUERY)

  useEffect(() => {
    if (disciplineSlug || parentSlug) {
      fetchSubjects({
        variables: {
          filter: {
            onlyParents,
            parentSlug,
            disciplineSlug,
            showAll: true,
          },
        },
      })
    }
  }, [disciplineSlug, onlyParents, parentSlug, fetchSubjects])

  return {
    subjects: toOptions(data),
    isFetching: loading,
  }
}

const toOption = ({
  slug: value,
  name: label,
}) => ({ label, value })

const toOptions = pipe(
  getNodes('subjects'),
  map(toOption),
  prepend({
    label: 'Todas',
    value: '',
  })
)
