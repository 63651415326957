import React from 'react'
import t from 'prop-types'
import { IconClose } from 'ui'
import { useTransition } from 'react-spring'

import { Overlay, Wrapper, CloseButton, Label } from './components'

const makeUrl = ({ id }) => `https://fast.player.liquidplatform.com/pApiv2/embed/f5ed1e5797cf4c36a6a292ae786327ae/${id}?html5=true&startOutput=480p`

const PlayerModal = ({ id, visible, onClose }) => {
  const transitions = useTransition(visible, null, {
    from: { position: 'fixed', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  document.addEventListener('keypress', handleKeyPress)

  function handleKeyPress (event) {
    if (event.code === 'KeyS' && visible) {
      onClose()
    }
  }

  return transitions.map(({ item, props, key }) => item ? (
    <Overlay key={key} style={props} onClick={() => onClose()}>
      <Wrapper>
        <>
          <Label>Pressione "S" para sair</Label>
          <CloseButton onClick={onClose}>
            <IconClose size={24} />
          </CloseButton>
          <iframe
            src={makeUrl({ id })}
            allowFullScreen='allowfullscreen'
            scrolling='no'
            frameBorder='0'
            title={`Video ${id}`}
          />
        </>
      </Wrapper>
    </Overlay>
  ) : null)
}

PlayerModal.propTypes = {
  id: t.string,
  visible: t.bool.isRequired,
  onClose: t.func.isRequired,
}

export { PlayerModal }
