import styled from 'styled-components'
import { Button, TitleMedium, media } from 'ui'
import { Spinner } from 'ui/spinner/spinner'

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.darkTheme ? theme.colours.mainBackground : theme.colours.purpleGray};
  padding: 3rem;
  border-radius: ${({ theme }) => theme.radius.large};

  ${media.lessThan('tablet')`
    padding: 2rem;
  `}

  ${media.lessThan('phone')`
    padding: 2rem 1rem;
  `}
`

export const Header = styled.div`
  text-align: center;
`

export const Title = styled(TitleMedium)`
  padding: .2rem 0;
  margin-bottom: 1.4rem;
`

export const Level = styled.span`
  display: inline-block;
  font-size: 1.1rem;
  font-weight: bold;
  padding: .2rem 1rem;
  letter-spacing: .05em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colours.secondary};
  border: 1px solid currentColor;
  border-radius: ${({ theme }) => theme.radius.small};
`

export const Content = styled.div`
  background: ${({ theme }) => theme.colours.background};
  padding: 4rem;
  border-radius: ${({ theme }) => theme.radius.medium};
  margin-top: 2rem;

  ${media.lessThan('tablet')`
    padding: 2rem;
  `}
`

export const Enunciated = styled.p`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colours.text};
  font-size: 1.4rem;
  line-height: 2;

  img {
    max-width: 100%;
    height: auto !important;
  }
`

export const Answers = styled.div`
  margin-top: 5rem;
`

export const SendArea = styled.div`
  padding: 3rem 2rem 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.lessThan('tablet')`
    flex-direction: column;
  `}

  ${media.lessThan('tablet')`
    padding-top: 2rem;
  `}
`

export const SendButton = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AddToNotebook = styled.div`
  flex: 1;

  ${media.lessThan('tablet')`
    order: 1;
    padding-top: 2rem;
  `}
`

export const Blank = styled.div`
  flex: 1;
`

export const Stats = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 1rem 0;
  padding: 2.4rem 1.4rem 0;
  border-top: 1px solid ${({ theme }) => theme.colours.background};

  ${media.lessThan('phone')`
    display: block;
  `}
`

export const StatsColumn = styled.div`
  padding-right: 3rem;
  text-align: center;
  flex: 1;
  display: grid;
  justify-content: center;
  align-items: center;

  ${media.lessThan('phone')`
    padding-right: 0;

    &:first-child {
      margin-bottom: 3rem;
    }
  `}
`

export const ResolutionButton = styled(Button)`
  display: flex;
  padding: 1em 2em 1.1em;
  font-size: 1.2rem;
  margin-top: 1rem;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
`

export const ButtonLabel = styled.p`
  margin-right: 5px;
`

export const ProgressWrapper = styled.div`
  padding: 2rem 0;

  ${media.lessThan('phone')`
    padding: 1rem 5rem;
  `}
`

export const SpacedSpinner = styled(Spinner)`
  margin-left: 1rem;
  opacity: ${p => p.visible ? '1' : '0'};
  transition: 200ms;

  ${media.lessThan('tablet')`
    margin-left: 0;
    margin-top: 1rem;
  `}
`

export const AnswerWrapper = styled.div`
`

export const ContentLoadaderContainer = styled.div`
  width: 100%;
  height: ${props => props.height}%;
`

export const AlternativeStatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
`

export const AlternativeStatsList = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`

export const AlternativeStatsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2rem;
`

export const AlternativeStatsLabel = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colours.primary};
`

export const AlternativeStatsValue = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
`
export const WrapperButtonTitle = styled.div``
export const TitleLabel = styled.div``
