import styled from 'styled-components'
import { rotate } from 'ui'

const loadingSize = '2.5rem'

const Loading = styled.div.attrs({ 'data-testid': 'loading' })`
  width: 100%;
  height: 100vh;

  &:after {
    content: '';
    position: fixed;
    width: ${loadingSize};
    height: ${loadingSize};
    font-size: ${loadingSize};
    border: solid .15em ${({ theme }) => theme.colours.gray2};
    border-left-color: transparent;
    border-right-color: transparent;
    border-radius: 50%;
    top: calc(50% - ${loadingSize} / 2);
    left: calc(50% - ${loadingSize} / 2);
    animation: ${rotate} 1s linear infinite;
  }
`

export { Loading }
