import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import { useRouteMatch, Link } from 'react-router-dom'
import { media } from 'ui'
import { PlayIcon } from '../../ui/generic/generic-icons'

const Card2 = ({
  id,
  icon,
  title,
  url,
  liveDate,
  disabled,
  progressValues,
  showProgressBar,
  onClick,
}) => {
  const match = useRouteMatch()
  // const croppedTitle = setTextMaxLength(title, 24)

  let pathTo = `${match.url}/${url}`
  if (id) {
    pathTo = `${match.url}/${url}/${id}`
  }

  return (
    <Box
      to={pathTo}
      className={disabled ? 'disabled' : null}
      onClick={(evt) => onClick && onClick(evt)}
    >
      <Contents>
        <Icon>
          <img src={icon} alt={title} />
        </Icon>
      </Contents>
      <Metadata>
        <TextWrapper>
          <PlayIcon />
        </TextWrapper>
        <Title>{title}</Title>
        {liveDate && <Data>{new Date(liveDate).toLocaleDateString()}</Data>}
      </Metadata>
    </Box>
  )
}

Card2.propTypes = {
  id: t.string,
  icon: t.string.isRequired,
  title: t.string.isRequired,
  url: t.string,
  liveDate: t.string,
  progressValues: t.object,
  showProgressBar: t.bool,
  disabled: t.bool,
  onClick: t.func,
}

const TextWrapper = styled.div`
  position: absolute;
  margin-top: 10px;
`

const Box = styled(Link)`
  position: relative;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.colours.background};
  border-radius: ${({ theme }) => theme.radius.small};
  padding: 10px;
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 0 20px ${({ theme }) => rgba(theme.colours.text, 0.05)};
  }

  ${media.lessThan('phone')`
    padding: 1.5em;
  `}

  &.disabled {
    color: currentColor;
    opacity: 0.5;
    text-decoration: none;
  }
`

const Icon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${({ theme }) => theme.colours.secondary};
  border-radius: ${({ theme }) => theme.radius.small};
  flex: 0 0 auto;
  margin-right: 2rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Metadata = styled.div`
  flex-basis: 70%;
`

const Title = styled.h4`
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.secondary};
  margin: 0;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.4;
  padding: 10px 0px 0px 30px;
  word-break: break-word;
`

const Data = styled.p`
  display: inline-block;
  color: ${({ theme }) => theme.colours.primary};
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.4;
  padding-left: 30px;
`

const CardList2 = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(20rem,1fr));
  grid-gap: 3rem;
  justify-items: center;

  > ${Box} {
    width: 100%;
    flex: 1 1 24rem;
  }
`

const Contents = styled.div`
  display: flex;
  align-items: flex-start;
`

export { Card2, CardList2 }
