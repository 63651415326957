import React, { Suspense, lazy } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { Loading, ToastNotification } from 'ui'
import { useAuth, PageDataProvider, UserDataProvider, NotificationStatusProvider } from 'resources'
import {
  HOME,
  LOGIN,
  LOGOUT,
  REGISTER,
  RECOVER_PASSWORD,
  NEW_PASSWORD,
} from './routes'
import 'dayjs/locale/pt-br'
import dayjs from 'dayjs'

const Login = lazy(() => import('pages/login'))
const Register = lazy(() => import('pages/register'))
const Main = lazy(() => import('pages/main'))
const RecoverPassword = lazy(() => import('pages/recover-password'))
const NewPassword = lazy(() => import('pages/new-password'))
const LiveNotification = lazy(() => import('pages/live-notification'))
dayjs.locale('pt-br')

const App = () => {
  const { pathname } = useLocation()
  const { didCheckUserIn, userInfo: { isUserLoggedIn }, logout } = useAuth()
  const PUBLIC_PAGES = [LOGIN, REGISTER, RECOVER_PASSWORD, NEW_PASSWORD]

  if (!didCheckUserIn) {
    return <Loading />
  }

  if (isUserLoggedIn && pathname === LOGOUT) {
    logout()

    return <Redirect to={LOGIN} />
  }

  if (isUserLoggedIn && pathname === LOGIN) {
    return <Redirect to={HOME} />
  }

  if (isUserLoggedIn && pathname === REGISTER) {
    return <Redirect to={HOME} />
  }

  if (!isUserLoggedIn && (!PUBLIC_PAGES.includes(pathname))) {
    return <Redirect to={LOGIN} />
  }

  return (
    <>
      <ToastNotification />
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path={NEW_PASSWORD} children={<NewPassword />} />
          <Route path={RECOVER_PASSWORD} children={<RecoverPassword />} />
          <Route path={LOGIN} children={<Login />} />
          <Route path={REGISTER} children={<Register />} />
          <PageDataProvider>
            <UserDataProvider>
              <NotificationStatusProvider>
                <LiveNotification />
                <Main />
              </NotificationStatusProvider>
            </UserDataProvider>
          </PageDataProvider>
        </Switch>
      </Suspense>
    </>
  )
}

export default App
