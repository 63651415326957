import React from 'react'
import t from 'prop-types'
import { FormControl, FormLabel, FormField, FormError } from 'ui'

const InputText = ({
  label,
  name,
  type = 'text',
  id,
  placeholder,
  inputRef,
  error,
  required,
  disabled,
  disableTabbing,
  cardFlag,
  ...rest
}) => {
  return (
    <FormControl>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <FormField
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        ref={inputRef}
        error={error}
        disabled={disabled}
        tabIndex={disableTabbing ? '-1' : undefined}
        required={required}
        cardFlag={cardFlag}
        {...rest}
      />
      {error && <FormError>{error?.message || 'Campo obrigatório'}</FormError>}
    </FormControl>
  )
}

InputText.propTypes = {
  label: t.string.isRequired,
  name: t.string.isRequired,
  id: t.string.isRequired,
  register: t.func,
  placeholder: t.string,
  type: t.string,
  cardFlag: t.string,
  inputRef: t.func,
  error: t.object,
  required: t.bool,
  disabled: t.bool,
  disableTabbing: t.bool,
}

export { InputText }
