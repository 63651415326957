import React from 'react'
import t from 'prop-types'

export const IconGoldMedal = ({ size = 16 }) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M4.73193 6.91724L1.23779 13.5003L4.41078 13.2072L5.9465 15.9995L9.44064 9.41641L4.73193 6.91724Z' fill='#003ACA' />
    <path d='M5.1321 14.0826C5.23015 13.872 5.1276 13.6204 4.92251 13.5113L4.72141 13.4043C4.52032 13.2897 4.44378 13.0351 4.55333 12.829C4.66438 12.6194 4.92551 12.5394 5.13511 12.6504L5.70337 12.9521C5.87896 13.0451 6.09906 12.9776 6.1896 12.801L6.20361 12.774C6.30766 12.6074 6.24613 12.3873 6.07305 12.2958L4.37325 11.3938C4.16615 11.2838 4.0646 11.0332 4.16115 10.8221C4.2677 10.5975 4.53682 10.5089 4.75343 10.624L6.33017 11.4609C6.50575 11.5539 6.72585 11.4864 6.8189 11.3108L6.8279 11.2878L6.8304 11.2828C6.93095 11.0987 6.86042 10.8676 6.67483 10.7691L5.79041 10.2998C5.58332 10.1898 5.48177 9.93917 5.57982 9.72557C5.68536 9.50346 5.95549 9.41242 6.17209 9.52747L7.14905 10.0462C7.33164 10.1433 7.54974 10.0622 7.64428 9.87814C7.64579 9.87564 7.64579 9.87564 7.64679 9.87314C7.64829 9.87064 7.64829 9.87064 7.64929 9.86813C7.74883 9.68655 7.69381 9.46044 7.51072 9.3639L6.45673 8.80464C6.25213 8.69608 6.15058 8.44547 6.24713 8.23437C6.35368 8.00976 6.6228 7.92122 6.83941 8.03628L9.43963 9.41642L5.94549 15.9995L4.98253 14.2492C5.04456 14.2072 5.09859 14.1551 5.1321 14.0826Z' fill='#2857CE' />
    <path d='M11.268 6.91724L14.7622 13.5003L11.5892 13.2072L10.0535 15.9995L6.55933 9.41641L11.268 6.91724Z' fill='#2857CE' />
    <path d='M10.8678 14.0826C10.7698 13.872 10.8723 13.6204 11.0774 13.5113L11.2785 13.4043C11.4796 13.2897 11.5562 13.0351 11.4466 12.829C11.3356 12.6194 11.0744 12.5394 10.8648 12.6504L10.2966 12.9521C10.121 13.0451 9.90088 12.9776 9.81034 12.801L9.79634 12.774C9.69229 12.6074 9.75381 12.3873 9.9269 12.2958L11.6272 11.3933C11.8343 11.2833 11.9358 11.0327 11.8393 10.8216C11.7327 10.597 11.4636 10.5084 11.247 10.6235L9.67028 11.4604C9.49469 11.5534 9.27459 11.4859 9.18155 11.3103L9.17254 11.2873L9.17004 11.2823C9.06949 11.0982 9.14003 10.8671 9.32561 10.7685L10.21 10.2993C10.4171 10.1893 10.5187 9.93866 10.4206 9.72506C10.3151 9.50295 10.045 9.41191 9.82835 9.52696L8.85039 10.0467C8.6678 10.1438 8.4497 10.0627 8.35516 9.87863C8.35366 9.87613 8.35366 9.87613 8.35266 9.87363C8.35116 9.87112 8.35116 9.87112 8.35016 9.86862C8.25061 9.68704 8.30563 9.46093 8.48872 9.36439L9.54272 8.80512C9.74731 8.69657 9.84886 8.44596 9.75231 8.23486C9.64576 8.01025 9.37664 7.92171 9.16004 8.03676L6.55981 9.41691L10.054 16L11.0169 14.2497C10.9554 14.2072 10.9014 14.1551 10.8678 14.0826Z' fill='#003ACA' />
    <path d='M7.99998 11.6765C11.2243 11.6765 13.8382 9.0626 13.8382 5.83824C13.8382 2.61387 11.2243 0 7.99998 0C4.77561 0 2.16174 2.61387 2.16174 5.83824C2.16174 9.0626 4.77561 11.6765 7.99998 11.6765Z' fill='#FFCC5B' />
    <path d='M8.79181 5.16842H5.09208C4.80444 5.16842 4.56833 4.93231 4.56833 4.64467L4.57233 4.60866V4.60065C4.56833 4.29701 4.81595 4.04889 5.12009 4.04889H7.68429C8.02395 4.04889 8.3241 3.79728 8.3441 3.45712C8.36011 3.10145 8.07648 2.80131 7.72031 2.80131H5.00404C4.7044 2.80131 4.48029 2.54969 4.48429 2.24955C4.48429 2.24555 4.48429 2.24555 4.48429 2.24155C4.48429 2.23755 4.48429 2.23755 4.48429 2.23354C4.48029 1.9339 4.7039 1.68178 5.00404 1.68178H7.84637C8.18203 1.68178 8.48217 1.43017 8.50218 1.09401C8.51819 0.734341 8.23455 0.438202 7.87839 0.438202H5.79691C3.67441 1.31311 2.17871 3.40009 2.17871 5.83823C2.17871 8.27638 3.67441 10.3629 5.79741 11.2368H8.44015C8.77581 11.2368 9.07595 10.9851 9.09596 10.649C9.11197 10.2893 8.82833 9.99368 8.47217 9.99368L5.46575 9.99318C5.11109 9.99318 4.82545 9.69654 4.84446 9.33737C4.86197 9.00122 5.16411 8.7486 5.50077 8.7486H6.66632C7.00147 8.73909 7.2721 8.46446 7.2721 8.12681C7.2721 7.78314 6.99247 7.50301 6.64831 7.50301H4.88148C4.59384 7.50301 4.35773 7.2674 4.36174 6.97927V6.93525C4.34173 6.65161 4.56983 6.4115 4.85347 6.4115H8.7558C9.09546 6.4115 9.3956 6.15988 9.41561 5.82373C9.43161 5.46456 9.14748 5.16842 8.79181 5.16842Z' fill='#FDBC4B' />
    <path d='M7.99997 10.0052C5.7019 10.0052 3.83252 8.13531 3.83252 5.83773C3.83252 3.54015 5.7019 1.67078 7.99997 1.67078C10.298 1.67078 12.1674 3.54065 12.1674 5.83823C12.1674 8.13581 10.298 10.0052 7.99997 10.0052Z' fill='#FFDB70' />
    <path opacity='0.2' d='M12.1674 5.83825C12.1674 3.62121 10.4256 1.80786 8.23909 1.6828H7.8509C7.59478 1.6828 7.36618 1.87489 7.35067 2.13101C7.33866 2.40514 7.55477 2.63124 7.82639 2.63124L10.1195 2.63175C10.3901 2.63175 10.6077 2.85785 10.5932 3.13198C10.5797 3.3886 10.3496 3.58119 10.0925 3.58119H9.20354C8.94792 3.58869 8.74132 3.79779 8.74132 4.05541C8.74132 4.31754 8.95492 4.53114 9.21704 4.53114H10.5647C10.7843 4.53114 10.9639 4.71122 10.9609 4.93032V4.96334C10.9759 5.17994 10.8023 5.36253 10.5857 5.36253H7.60979C7.35067 5.36253 7.12206 5.55462 7.10656 5.81074C7.09455 6.08486 7.31065 6.31097 7.58228 6.31097H10.4036C10.6232 6.31097 10.8028 6.49105 10.8028 6.71016L10.7998 6.73767V6.74367C10.8028 6.97528 10.6137 7.16437 10.3821 7.16437H8.42617C8.16705 7.16437 7.93844 7.35646 7.92294 7.61558C7.91093 7.88671 8.12703 8.11582 8.39866 8.11582H10.4701C10.6987 8.11582 10.8693 8.30791 10.8663 8.53651C10.8663 8.53951 10.8663 8.53951 10.8663 8.54252C10.8663 8.54552 10.8663 8.54552 10.8663 8.54852C10.8693 8.77713 10.6987 8.96922 10.4701 8.96922H8.30262C8.0465 8.96922 7.81789 9.16131 7.80238 9.41743C7.79038 9.69205 8.00648 9.91766 8.2781 9.91766H8.84987C10.7423 9.52348 12.1674 7.84419 12.1674 5.83825Z' fill='white' />
    <path d='M10.9094 4.91531H8.88244L8.25564 2.98741C8.17511 2.73979 7.82444 2.73979 7.74391 2.98741L7.11761 4.91531H5.09066C4.83004 4.91531 4.72149 5.24897 4.93259 5.40204L6.57236 6.5936L5.94607 8.52151C5.86553 8.76912 6.14916 8.97522 6.36026 8.82215L8.00003 7.63059L9.63979 8.82215C9.85089 8.97522 10.1345 8.76912 10.054 8.52151L9.42769 6.5936L11.0675 5.40204C11.2781 5.24897 11.17 4.91531 10.9094 4.91531Z' fill='#EC9922' />
  </svg>
)

export const IconSilverMedal = ({ size = 16 }) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M4.73193 6.91724L1.23779 13.5003L4.41078 13.2072L5.9465 15.9995L9.44064 9.41641L4.73193 6.91724Z' fill='#003ACA' />
    <path d='M5.1321 14.0826C5.23015 13.872 5.1276 13.6204 4.92251 13.5113L4.72141 13.4043C4.52032 13.2897 4.44378 13.0351 4.55333 12.829C4.66438 12.6194 4.92551 12.5394 5.13511 12.6504L5.70337 12.9521C5.87896 13.0451 6.09906 12.9776 6.1896 12.801L6.20361 12.774C6.30766 12.6074 6.24613 12.3873 6.07305 12.2958L4.37325 11.3938C4.16615 11.2838 4.0646 11.0332 4.16115 10.8221C4.2677 10.5975 4.53682 10.5089 4.75343 10.624L6.33017 11.4609C6.50575 11.5539 6.72585 11.4864 6.8189 11.3108L6.8279 11.2878L6.8304 11.2828C6.93095 11.0987 6.86042 10.8676 6.67483 10.769L5.79041 10.2998C5.58332 10.1898 5.48177 9.93915 5.57982 9.72555C5.68536 9.50345 5.95549 9.41241 6.17209 9.52746L7.14905 10.0462C7.33164 10.1432 7.54974 10.0622 7.64428 9.87812C7.64579 9.87562 7.64579 9.87562 7.64679 9.87312C7.64829 9.87062 7.64829 9.87062 7.64929 9.86812C7.74883 9.68653 7.69381 9.46043 7.51072 9.36388L6.45673 8.80462C6.25213 8.69607 6.15058 8.44545 6.24713 8.23435C6.35368 8.00975 6.6228 7.92121 6.83941 8.03626L9.43963 9.41641L5.94549 15.9995L4.98253 14.2492C5.04456 14.2072 5.09859 14.1551 5.1321 14.0826Z' fill='#2857CE' />
    <path d='M11.268 6.91724L14.7622 13.5003L11.5892 13.2072L10.0535 15.9995L6.55933 9.41641L11.268 6.91724Z' fill='#2857CE' />
    <path d='M10.8678 14.0826C10.7698 13.872 10.8723 13.6204 11.0774 13.5113L11.2785 13.4043C11.4796 13.2897 11.5562 13.0351 11.4466 12.829C11.3356 12.6194 11.0744 12.5394 10.8648 12.6504L10.2966 12.9521C10.121 13.0451 9.90088 12.9776 9.81034 12.801L9.79634 12.774C9.69229 12.6074 9.75381 12.3873 9.9269 12.2958L11.6272 11.3933C11.8343 11.2833 11.9358 11.0327 11.8393 10.8216C11.7327 10.597 11.4636 10.5084 11.247 10.6235L9.67028 11.4604C9.49469 11.5534 9.27459 11.4859 9.18155 11.3103L9.17254 11.2873L9.17004 11.2823C9.06949 11.0982 9.14003 10.8671 9.32561 10.7685L10.21 10.2993C10.4171 10.1893 10.5187 9.93866 10.4206 9.72506C10.3151 9.50295 10.045 9.41191 9.82835 9.52696L8.85039 10.0467C8.6678 10.1438 8.4497 10.0627 8.35516 9.87863C8.35366 9.87613 8.35366 9.87613 8.35266 9.87363C8.35116 9.87112 8.35116 9.87112 8.35016 9.86862C8.25061 9.68704 8.30563 9.46093 8.48872 9.36439L9.54272 8.80512C9.74731 8.69657 9.84886 8.44596 9.75231 8.23486C9.64576 8.01025 9.37664 7.92171 9.16004 8.03676L6.55981 9.41691L10.054 16L11.0169 14.2497C10.9554 14.2072 10.9014 14.1551 10.8678 14.0826Z' fill='#003ACA' />
    <path d='M7.99998 11.6765C11.2243 11.6765 13.8382 9.0626 13.8382 5.83824C13.8382 2.61387 11.2243 0 7.99998 0C4.77561 0 2.16174 2.61387 2.16174 5.83824C2.16174 9.0626 4.77561 11.6765 7.99998 11.6765Z' fill='#CDCDCD' />
    <path d='M8.79181 5.16843H5.09208C4.80444 5.16843 4.56833 4.93232 4.56833 4.64469L4.57233 4.60867V4.60067C4.56833 4.29703 4.81595 4.04891 5.12009 4.04891H7.68429C8.02395 4.04891 8.3241 3.79729 8.3441 3.45713C8.36011 3.10147 8.07648 2.80132 7.72031 2.80132H5.00404C4.7044 2.80132 4.48029 2.54971 4.48429 2.24957C4.48429 2.24556 4.48429 2.24556 4.48429 2.24156C4.48429 2.23756 4.48429 2.23756 4.48429 2.23356C4.48029 1.93392 4.7039 1.6818 5.00404 1.6818H7.84637C8.18203 1.6818 8.48217 1.43018 8.50218 1.09402C8.51819 0.734356 8.23455 0.438217 7.87839 0.438217H5.79691C3.67441 1.31313 2.17871 3.4001 2.17871 5.83825C2.17871 8.27639 3.67441 10.3629 5.79741 11.2368H8.44015C8.77581 11.2368 9.07595 10.9852 9.09596 10.649C9.11197 10.2893 8.82833 9.9937 8.47217 9.9937L5.46575 9.9932C5.11109 9.9932 4.82545 9.69656 4.84446 9.33739C4.86197 9.00123 5.16411 8.74861 5.50077 8.74861H6.66632C7.00147 8.73911 7.2721 8.46448 7.2721 8.12682C7.2721 7.78316 6.99247 7.50303 6.64831 7.50303H4.88148C4.59384 7.50303 4.35773 7.26742 4.36174 6.97928V6.93526C4.34173 6.65163 4.56983 6.41152 4.85347 6.41152H8.7558C9.09546 6.41152 9.3956 6.1599 9.41561 5.82374C9.43161 5.46457 9.14748 5.16843 8.79181 5.16843Z' fill='#BABAB9' />
    <path d='M7.99997 10.0052C5.7019 10.0052 3.83252 8.13531 3.83252 5.83773C3.83252 3.54015 5.7019 1.67078 7.99997 1.67078C10.298 1.67078 12.1674 3.54065 12.1674 5.83823C12.1674 8.13581 10.298 10.0052 7.99997 10.0052Z' fill='#DEDEDE' />
    <path opacity='0.2' d='M12.1674 5.83823C12.1674 3.62119 10.4256 1.80784 8.23909 1.68279H7.8509C7.59478 1.68279 7.36618 1.87488 7.35067 2.131C7.33866 2.40512 7.55477 2.63123 7.82639 2.63123L10.1195 2.63173C10.3901 2.63173 10.6077 2.85784 10.5932 3.13196C10.5797 3.38858 10.3496 3.58117 10.0925 3.58117H9.20354C8.94792 3.58868 8.74132 3.79778 8.74132 4.0554C8.74132 4.31752 8.95492 4.53112 9.21704 4.53112H10.5647C10.7843 4.53112 10.9639 4.7112 10.9609 4.93031V4.96332C10.9759 5.17992 10.8023 5.36251 10.5857 5.36251H7.60979C7.35067 5.36251 7.12206 5.5546 7.10656 5.81072C7.09455 6.08485 7.31065 6.31095 7.58228 6.31095H10.4036C10.6232 6.31095 10.8028 6.49104 10.8028 6.71014L10.7998 6.73765V6.74366C10.8028 6.97527 10.6137 7.16435 10.3821 7.16435H8.42617C8.16705 7.16435 7.93844 7.35644 7.92294 7.61557C7.91093 7.88669 8.12703 8.1158 8.39866 8.1158H10.4701C10.6987 8.1158 10.8693 8.30789 10.8663 8.5365C10.8663 8.5395 10.8663 8.5395 10.8663 8.5425C10.8663 8.5455 10.8663 8.5455 10.8663 8.5485C10.8693 8.77711 10.6987 8.9692 10.4701 8.9692H8.30262C8.0465 8.9692 7.81789 9.16129 7.80238 9.41741C7.79038 9.69204 8.00648 9.91765 8.2781 9.91765H8.84987C10.7423 9.52346 12.1674 7.84417 12.1674 5.83823Z' fill='#BABAB9' />
    <path d='M10.9094 4.91531H8.88244L8.25564 2.98741C8.17511 2.73979 7.82444 2.73979 7.74391 2.98741L7.11761 4.91531H5.09066C4.83004 4.91531 4.72149 5.24897 4.93259 5.40204L6.57236 6.5936L5.94607 8.52151C5.86553 8.76912 6.14916 8.97522 6.36026 8.82215L8.00003 7.63059L9.63979 8.82215C9.85089 8.97522 10.1345 8.76912 10.054 8.52151L9.42769 6.5936L11.0675 5.40204C11.2781 5.24897 11.17 4.91531 10.9094 4.91531Z' fill='#BABAB9' />
  </svg>
)

export const IconBronzeMedal = ({ size = 16 }) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M4.73193 6.91724L1.23779 13.5003L4.41078 13.2072L5.9465 15.9995L9.44064 9.41641L4.73193 6.91724Z' fill='#003ACA' />
    <path d='M5.1321 14.0826C5.23015 13.872 5.1276 13.6204 4.92251 13.5113L4.72141 13.4043C4.52032 13.2897 4.44378 13.0351 4.55333 12.829C4.66438 12.6194 4.92551 12.5394 5.13511 12.6504L5.70337 12.9521C5.87896 13.0451 6.09906 12.9776 6.1896 12.801L6.20361 12.774C6.30766 12.6074 6.24613 12.3873 6.07305 12.2958L4.37325 11.3938C4.16615 11.2838 4.0646 11.0332 4.16115 10.8221C4.2677 10.5975 4.53682 10.5089 4.75343 10.624L6.33017 11.4609C6.50575 11.5539 6.72585 11.4864 6.8189 11.3108L6.8279 11.2878L6.8304 11.2828C6.93095 11.0987 6.86042 10.8676 6.67483 10.769L5.79041 10.2998C5.58332 10.1898 5.48177 9.93915 5.57982 9.72555C5.68536 9.50345 5.95549 9.41241 6.17209 9.52746L7.14905 10.0462C7.33164 10.1432 7.54974 10.0622 7.64428 9.87812C7.64579 9.87562 7.64579 9.87562 7.64679 9.87312C7.64829 9.87062 7.64829 9.87062 7.64929 9.86812C7.74883 9.68653 7.69381 9.46043 7.51072 9.36388L6.45673 8.80462C6.25213 8.69607 6.15058 8.44545 6.24713 8.23435C6.35368 8.00975 6.6228 7.92121 6.83941 8.03626L9.43963 9.41641L5.94549 15.9995L4.98253 14.2492C5.04456 14.2072 5.09859 14.1551 5.1321 14.0826Z' fill='#2857CE' />
    <path d='M11.268 6.91724L14.7622 13.5003L11.5892 13.2072L10.0535 15.9995L6.55933 9.41641L11.268 6.91724Z' fill='#2857CE' />
    <path d='M10.8678 14.0826C10.7698 13.872 10.8723 13.6204 11.0774 13.5113L11.2785 13.4043C11.4796 13.2897 11.5562 13.0351 11.4466 12.829C11.3356 12.6194 11.0744 12.5394 10.8648 12.6504L10.2966 12.9521C10.121 13.0451 9.90088 12.9776 9.81034 12.801L9.79634 12.774C9.69229 12.6074 9.75381 12.3873 9.9269 12.2958L11.6272 11.3933C11.8343 11.2833 11.9358 11.0327 11.8393 10.8216C11.7327 10.597 11.4636 10.5084 11.247 10.6235L9.67028 11.4604C9.49469 11.5534 9.27459 11.4859 9.18155 11.3103L9.17254 11.2873L9.17004 11.2823C9.06949 11.0982 9.14003 10.8671 9.32561 10.7685L10.21 10.2993C10.4171 10.1893 10.5187 9.93866 10.4206 9.72506C10.3151 9.50295 10.045 9.41191 9.82835 9.52696L8.85039 10.0467C8.6678 10.1438 8.4497 10.0627 8.35516 9.87863C8.35366 9.87613 8.35366 9.87613 8.35266 9.87363C8.35116 9.87112 8.35116 9.87112 8.35016 9.86862C8.25061 9.68704 8.30563 9.46093 8.48872 9.36439L9.54272 8.80512C9.74731 8.69657 9.84886 8.44596 9.75231 8.23486C9.64576 8.01025 9.37664 7.92171 9.16004 8.03676L6.55981 9.41691L10.054 16L11.0169 14.2497C10.9554 14.2072 10.9014 14.1551 10.8678 14.0826Z' fill='#003ACA' />
    <path d='M7.99998 11.6765C11.2243 11.6765 13.8382 9.0626 13.8382 5.83824C13.8382 2.61387 11.2243 0 7.99998 0C4.77561 0 2.16174 2.61387 2.16174 5.83824C2.16174 9.0626 4.77561 11.6765 7.99998 11.6765Z' fill='#F29946' />
    <path d='M8.79181 5.16843H5.09208C4.80444 5.16843 4.56833 4.93232 4.56833 4.64469L4.57233 4.60867V4.60067C4.56833 4.29703 4.81595 4.04891 5.12009 4.04891H7.68429C8.02395 4.04891 8.3241 3.79729 8.3441 3.45713C8.36011 3.10147 8.07648 2.80132 7.72031 2.80132H5.00404C4.7044 2.80132 4.48029 2.54971 4.48429 2.24957C4.48429 2.24556 4.48429 2.24556 4.48429 2.24156C4.48429 2.23756 4.48429 2.23756 4.48429 2.23356C4.48029 1.93392 4.7039 1.6818 5.00404 1.6818H7.84637C8.18203 1.6818 8.48217 1.43018 8.50218 1.09402C8.51819 0.734356 8.23455 0.438217 7.87839 0.438217H5.79691C3.67441 1.31313 2.17871 3.4001 2.17871 5.83825C2.17871 8.27639 3.67441 10.3629 5.79741 11.2368H8.44015C8.77581 11.2368 9.07595 10.9852 9.09596 10.649C9.11197 10.2893 8.82833 9.9937 8.47217 9.9937L5.46575 9.9932C5.11109 9.9932 4.82545 9.69656 4.84446 9.33739C4.86197 9.00123 5.16411 8.74861 5.50077 8.74861H6.66632C7.00147 8.73911 7.2721 8.46448 7.2721 8.12682C7.2721 7.78316 6.99247 7.50303 6.64831 7.50303H4.88148C4.59384 7.50303 4.35773 7.26742 4.36174 6.97928V6.93526C4.34173 6.65163 4.56983 6.41152 4.85347 6.41152H8.7558C9.09546 6.41152 9.3956 6.1599 9.41561 5.82374C9.43161 5.46457 9.14748 5.16843 8.79181 5.16843Z' fill='#E8852A' />
    <path d='M7.99997 10.0052C5.7019 10.0052 3.83252 8.13531 3.83252 5.83773C3.83252 3.54015 5.7019 1.67078 7.99997 1.67078C10.298 1.67078 12.1674 3.54065 12.1674 5.83823C12.1674 8.13581 10.298 10.0052 7.99997 10.0052Z' fill='#F7AD69' />
    <path opacity='0.2' d='M12.1674 5.83823C12.1674 3.62119 10.4256 1.80784 8.23909 1.68279H7.8509C7.59478 1.68279 7.36618 1.87488 7.35067 2.131C7.33866 2.40512 7.55477 2.63123 7.82639 2.63123L10.1195 2.63173C10.3901 2.63173 10.6077 2.85784 10.5932 3.13196C10.5797 3.38858 10.3496 3.58117 10.0925 3.58117H9.20354C8.94792 3.58868 8.74132 3.79778 8.74132 4.0554C8.74132 4.31752 8.95492 4.53112 9.21704 4.53112H10.5647C10.7843 4.53112 10.9639 4.7112 10.9609 4.93031V4.96332C10.9759 5.17992 10.8023 5.36251 10.5857 5.36251H7.60979C7.35067 5.36251 7.12206 5.5546 7.10656 5.81072C7.09455 6.08485 7.31065 6.31095 7.58228 6.31095H10.4036C10.6232 6.31095 10.8028 6.49104 10.8028 6.71014L10.7998 6.73765V6.74366C10.8028 6.97527 10.6137 7.16435 10.3821 7.16435H8.42617C8.16705 7.16435 7.93844 7.35644 7.92294 7.61557C7.91093 7.88669 8.12703 8.1158 8.39866 8.1158H10.4701C10.6987 8.1158 10.8693 8.30789 10.8663 8.5365C10.8663 8.5395 10.8663 8.5395 10.8663 8.5425C10.8663 8.5455 10.8663 8.5455 10.8663 8.5485C10.8693 8.77711 10.6987 8.9692 10.4701 8.9692H8.30262C8.0465 8.9692 7.81789 9.16129 7.80238 9.41741C7.79038 9.69204 8.00648 9.91765 8.2781 9.91765H8.84987C10.7423 9.52346 12.1674 7.84417 12.1674 5.83823Z' fill='#F29946' />
    <path d='M10.9094 4.91531H8.88244L8.25564 2.98741C8.17511 2.73979 7.82444 2.73979 7.74391 2.98741L7.11761 4.91531H5.09066C4.83004 4.91531 4.72149 5.24897 4.93259 5.40204L6.57236 6.5936L5.94607 8.52151C5.86553 8.76912 6.14916 8.97522 6.36026 8.82215L8.00003 7.63059L9.63979 8.82215C9.85089 8.97522 10.1345 8.76912 10.054 8.52151L9.42769 6.5936L11.0675 5.40204C11.2781 5.24897 11.17 4.91531 10.9094 4.91531Z' fill='#D26704' />
  </svg>
)

IconGoldMedal.propTypes = {
  size: t.number,
}

IconSilverMedal.propTypes = {
  size: t.number,
}

IconBronzeMedal.propTypes = {
  size: t.number,
}
