import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { colours, media } from 'ui'
import { useWindowWidth } from '@react-hook/window-size/throttled'

const PyramidChart = ({ data }) => {
  const width = useWindowWidth()

  const frontTriangleStyle = {
    borderLeftWidth: `${data.userPositionInPercentage / (width > 900 ? 20 : 6.66)}vw`,
    borderRightWidth: `${data.userPositionInPercentage / (width > 900 ? 20 : 6.66)}vw`,
    borderBottomWidth: `${((data.userPositionInPercentage * 139) / 100)}px`,
  }

  return (
    <TriangleContainer>
      <TriangleBack />
      <TriangleFront
        borderLeftWidth={frontTriangleStyle.borderLeftWidth}
        borderRightWidth={frontTriangleStyle.borderRightWidth}
        borderBottomWidth={frontTriangleStyle.borderBottomWidth}
      />
    </TriangleContainer>
  )
}

PyramidChart.propTypes = {
  data: t.object,
}

export { PyramidChart }

const TriangleContainer = styled.div`
  position: relative;
  margin-top: 20px;
`

const TriangleBack = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5vw solid transparent;
  border-right: 5vw solid transparent;
  border-bottom: 139px solid ${colours.gray4};
  ${media.lessThan('tablet')`
   border-left: 14vw solid transparent;
   border-right: 14vw solid transparent;
  `}
`

const TriangleFront = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: ${props => props.borderLeftWidth} solid transparent;
  border-right: ${props => props.borderRightWidth} solid transparent;
  border-bottom: ${props => props.borderBottomWidth} solid ${colours.primary};
`
