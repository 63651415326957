import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import t from 'prop-types'

import { Button as BaseButton, media } from 'ui'
import { useMe, getToken } from 'resources'

const ButtonPlanMessage = ({ size = 'medium', variant = 'success', warning = false }) => {
  const { hasRenewalDiscount, isFree, plans } = useMe()
  const [userToken, setUserToken] = useState('')

  const buyPlanButtonText = useMemo(() => {
    if (isFree && plans.length === 0) return 'Comprar plano de acesso'

    return hasRenewalDiscount ? 'Renovar com desconto' : 'Renovar plano'
  }, [hasRenewalDiscount, isFree, plans.length])

  useEffect(() => {
    const getUserToken = async () => {
      const { token } = await getToken()
      setUserToken(token)
    }

    getUserToken().catch(_e => window.location.reload(true))
  }, [])

  const checkoutUrl = useMemo(() => isFree && plans.length === 0 ? `${process.env.REACT_APP_CHECKOUT_URL}/?utm_source=plataforma&utm_medium=alunos-free&utm_campaign=curso-gratuito&utm_content=pop-up&conversion=curso-gratuito&t=${userToken}` : `${process.env.REACT_APP_CHECKOUT_URL}/?utm_source=plataforma&utm_medium=alunos-free&utm_campaign=curso-gratuito&utm_content=pop-up-renovacao&conversion=curso-gratuito&t=${userToken}`, [isFree, plans.length, userToken])

  return (
    <Wrapper>
      <Button
        as='a'
        href={checkoutUrl}
        variant={variant}
        size={size}
      >
        {buyPlanButtonText}
      </Button>

      {!isFree ? !warning ? (<Text>A renovação da sua assinatura não implicará no cancelamento do plano atual e haverá a acumulação do tempo de acesso dos planos.</Text>) : null : null}
    </Wrapper>
  )
}

const Button = styled(BaseButton)`
  ${media.lessThan('phone')`
    font-size: 1.2rem;
    padding: 0.4rem 1.8rem;
  `}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
`

const Text = styled.p`
 margin-top: 10px;
 text-align: justify;
`

ButtonPlanMessage.propTypes = {
  size: t.string,
  variant: t.string,
  warning: t.bool,
}

export { ButtonPlanMessage }
