import styled, { css } from 'styled-components'
import { Menu, MenuStudent, media } from 'ui'
import CollapseToggleCurve from './sidebar-collapse-curve.svg'

const menuOpen = css`
  ${media.greaterThan('tablet')`
    width: 27.6rem;
  `}

  ${media.greaterThan('largeDesktop')`
    width: 28rem;
  `}

  ${media.greaterThan('largeDesktop')`
    width: 30rem;
  `}
`

const menuClosed = css`
  width: 7.5rem;
`

const Wrapper = styled.aside`
  padding-top: 3.3rem;
  padding-left: 2.2rem;
  background-color: ${({ theme }) => theme.darkTheme ? theme.colours.mainBackground : theme.colours.background};
  will-change: width;
  transition: width .3s ease;
  z-index: ${({ theme }) => theme.zIndex.base};
  box-shadow: 30px 0 30px -35px rgba(0, 0, 0, .04);

  ${({ isOpen }) => isOpen ? menuOpen : menuClosed};
`

const ToggleButton = styled.button`
  appearance: none;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: 7.7rem;
  right: -0.8rem;
  cursor: pointer;
  outline: none;
  padding: 0;

  &:after {
    content: '';
    width: 1rem;
    height: 6.8rem;
    background-image: url(${CollapseToggleCurve});
    position: absolute;
    right: -0.2rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: ${({ theme }) => theme.zIndex.hidden};
  }
`

const LogoWrapper = styled.div`
  min-height: 5rem;
`

const SpacedMenu = styled(Menu)`
  padding-top: 5.5rem;

  ${media.greaterThan('largeDesktop')`
    padding-top: 9rem;
  `}
`

const BottomMenu = styled(MenuStudent)`
  position: sticky;
  top: 100vh;
  padding-bottom: 1.2rem;

  ${media.greaterThan('largeDesktop')`
    padding-bottom: 1.6rem;
  `}
`

const DarkMode = styled.div`
  margin-top: 2rem;
  position: absolute;
  font-size: 1.7rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  padding-left: 0.6rem;
  color: ${({ theme }) => theme.colours.darkPurpleGray};
  ${({ right }) => right && 'padding-right: .6rem;'};
  white-space: nowrap;
  transition: opacity 0.3s ease, visibility 0.3s ease;
`

export { Wrapper, ToggleButton, LogoWrapper, SpacedMenu, BottomMenu, DarkMode }
