import { gql, useQuery } from '@apollo/client'

const useEssayFetcher = () => {
  const { data, loading } = useQuery(ESSAYS_QUERY, {
    fetchPolicy: 'no-cache',
  })

  const emptyLabel = [{
    label: 'Selecione uma redação',
    value: '',
  }]

  const essaysDetailed = data?.stats?.essaysDetailed.filter(s => s.type === 'ENEM').map((data) => {
    return {
      label: data.theme,
      value: data.id,
    }
  })

  const result = essaysDetailed ? emptyLabel.concat(essaysDetailed) : emptyLabel

  return {
    essaysDetailed: result,
    loading,
  }
}

const ESSAYS_QUERY = gql`
query UserStats($timeInDays: Int) {
  stats {
    essaysDetailed(timeInDays: $timeInDays) {
      id
      theme
      type
    }
  }
}
`

export { useEssayFetcher }
