import React, { useEffect, useState } from 'react'
import t from 'prop-types'
import {
  Wrapper,
  Box,
  AnnoucementLink,
  Hide,
  Close,
  CloseWrapper,
  ContentLoading,
} from './components'
import { FadeOutHide } from 'springs'

const Announcement = ({
  loading,
  error,
  title,
  handleDismiss,
  link,
  active,
  image,
}) => {
  const [showAnnouncement, setShowAnnouncement] = useState(false)

  useEffect(() => {
    setShowAnnouncement(active)
  }, [active])

  if (loading) {
    return <ContentLoading />
  }

  if (error) return null

  const isExternalLink = link && /^https?:\/\//i.test(link)

  const handleHide = () => setShowAnnouncement(false)

  return (
    <FadeOutHide trigger={showAnnouncement || false}>
      <CloseWrapper>
        <Close onClick={handleDismiss}>Fechar e não me mostrar novamente</Close>
      </CloseWrapper>
      <Wrapper>
        <Box>
          {!link
            ? <AnnoucementLink as='div' />
            : !isExternalLink
              ? <AnnoucementLink to={link} />
              : <AnnoucementLink as='a' href={link} target='_blank' />}
          <img src={image} alt={title} />
          <Hide onClick={handleHide}>
            <span>Esconder</span>
          </Hide>
        </Box>
      </Wrapper>
    </FadeOutHide>
  )
}

Announcement.propTypes = {
  loading: t.bool,
  error: t.object,
  title: t.string,
  image: t.string,
  link: t.string,
  active: t.bool,
  handleDismiss: t.func.isRequired,
}

export { Announcement }
