import { gql } from '@apollo/client'

export const DISCIPLINES_QUERY = gql`
  query GetDisciplines {
    disciplines {
      nodes {
        id
        name
        slug
      }
    }
  }
`
