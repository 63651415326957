import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'

const UploadButton = ({ label, onChange }) => {
  return (
    <Wrapper>
      <Label htmlFor='upload'>
        {label}
      </Label>
      <input
        id='upload'
        type='file'
        onChange={onChange}
      />
    </Wrapper>
  )
}

UploadButton.propTypes = {
  label: t.string.isRequired,
  onChange: t.func,
}

const Wrapper = styled.div`
  display: inline-block;

  input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1;
    width: 0;
    height: 100%;
    opacity: 0;
  }
`

const Label = styled.label`
  margin: 0;
  outline: 0;
  border: 1px solid ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.secondary};
  border-radius: ${({ theme }) => theme.radius.small};
  padding: .4rem 1rem;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.secondary};
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: opacity .4s ease;

  &:hover {
    opacity: .8;
  }
`

export { UploadButton }
