import React, { createContext, useState, useContext, useCallback } from 'react'
import t from 'prop-types'

const PageDataContext = createContext(null)

const PageDataProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = useState('')
  const [isSidebarOpened, setIsSidebarOpened] = useState(true)

  const openSidebar = useCallback(() => {
    setIsSidebarOpened(true)
  }, [])

  const closeSidebar = useCallback(() => {
    setIsSidebarOpened(false)
  }, [])

  return (
    <PageDataContext.Provider
      value={{
        pageTitle,
        setPageTitle,
        isSidebarOpened,
        openSidebar,
        closeSidebar,
      }}
    >
      {children}
    </PageDataContext.Provider>
  )
}

PageDataProvider.propTypes = {
  children: t.node.isRequired,
}

const usePageData = () => useContext(PageDataContext)

export { PageDataContext, PageDataProvider, usePageData }
