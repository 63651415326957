import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  ::selection {
    background: ${({ theme }) => theme.darkTheme ? theme.colours.textSecondary : theme.colours.primary};
    color: ${({ theme }) => theme.colours.background};
    fill: ${({ theme }) => theme.colours.text};
  }

  ::-moz-selection {
    background: ${({ theme }) => theme.colours.primary};
    color: ${({ theme }) => theme.colours.background};
    fill: ${({ theme }) => theme.colours.text};
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    margin: 0;
  }

  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    margin: 0;
    padding: 0;
    cursor: default;
    font-family: ${({ theme }) => theme.font.default};
    font-variant-ligatures: none;
    color: ${({ theme }) => theme.colours.text};
    background: ${({ theme }) => theme.darkTheme ? theme.colours.background : theme.colours.mainBackground};
  }

  html,
  body {
    height: 100%;
  }

  input,
  select,
  textarea,
  button {
    font-family: inherit;
  }
`
