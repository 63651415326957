import qs from 'qs'
import { formatSecondsToMinutes, resourcesTypes, pdfsTypes } from 'resources'
import { PLAY, PLAYREGIONAL, QUESTIONS, SINGLE_SIMULATED } from 'routes'

import { withParams } from './router-helpers'

export const toGeneric = ({ type, order, item, parentSlug, discipline, state, weekId }) => {
  switch (type) {
    case resourcesTypes.CLASS:
      return classToGeneric({ ...item, order, type, parentSlug, discipline, state })
    case resourcesTypes.SUBJECT:
      return questionToGeneric({ ...item, type })
    case resourcesTypes.SIMULATED:
      return simulatedToGeneric({ ...item, type, order, weekId })
    case resourcesTypes.PDFS:
      return pdfsToGeneric({ ...item, type })
    default:
      return { ...item, type }
  }
}

const classToGeneric = ({ id, classGroup, parentSlug, slug, type, title, mainVideo, audience, watched, enemRelevance, order, discipline, state }) => {
  const queryParams = qs.stringify({ aula: slug })
  let route
  if (discipline) {
    route = withParams(PLAY, {
      parentSlug: classGroup?.meta?.slug || parentSlug,
      discipline,
      type: 1,
    })
  }
  if (state) {
    route = withParams(PLAYREGIONAL, {
      parentSlug: classGroup?.meta?.slug || parentSlug,
      state: state,
      type: 2,
    })
  }
  return {
    id,
    order,
    slug,
    type,
    title,
    enemRelevance,
    watched,
    label: formatSecondsToMinutes(mainVideo?.timeInSeconds) || 'aula',
    tags: audience,
    url: `${route}?${queryParams}`,
  }
}

const buildQuestionURL = ({ parentSubject, slug, discipline }) => {
  if (!parentSubject) return `${QUESTIONS}?primario=${slug}&disciplina=${discipline.slug}`

  return `${QUESTIONS}?primario=${parentSubject.slug}&secundario=${slug}&disciplina=${discipline.slug}`
}

const buildQuestionTitle = ({ parentSubject, name }) => {
  if (!parentSubject) return name

  return `${name} - ${parentSubject?.name}`
}

const questionToGeneric = ({ id, discipline, type, name, slug, parentSubject, watched }) => ({
  id,
  type,
  title: buildQuestionTitle({ parentSubject, name }),
  label: 'Questões',
  url: buildQuestionURL({ parentSubject, slug, discipline }),
  discipline: discipline.slug,
  subject: slug,
  watched,
})

const simulatedToGeneric = ({ id, slug, type, title, disc, sub, watched, order, weekId }) => ({
  id,
  type,
  title,
  label: 'Simulado',
  url: id ? withParams(SINGLE_SIMULATED, { slug }) : slug,
  discipline: disc,
  subject: sub,
  watched,
  order,
  weekId,
})

const pdfsToGeneric = ({ id, discipline, type, title, file, pdfType }) => {
  return ({
    id,
    type,
    title,
    label: pdfsTypes[pdfType],
    url: file,
    discipline: discipline.name,
    pdfType,
  })
}
