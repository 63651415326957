import React from 'react'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'

import { useSupportHeader } from './use-support-header'

import { colours, radius, media } from 'ui'

export const SupportHeader = () => {
  const { supportMeta, loading, error } = useSupportHeader()

  const { openedTicketsThisWeek, totalOfAllowedTicketsPerWeek } = supportMeta
  const remainingTickets = totalOfAllowedTicketsPerWeek - openedTicketsThisWeek

  if (loading) return <ContentLoading />

  if (error) {
    return (
      <Wrapper>
        <Text>Não foi possível carregar informações sobre a monitoria.</Text>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Card>
        <Title>Chamados disponíveis</Title>
        <Text>Você tem <Highlight>{remainingTickets}</Highlight> chamado(s) restantes para essa matéria essa semana.</Text>
      </Card>
      <Card>
        <Title>Limite de chamados</Title>
        <Text>
          Você pode abrir até <Highlight>{totalOfAllowedTicketsPerWeek}</Highlight> chamados dessa matéria por semana.
        </Text>
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  margin-bottom: 3rem;
  justify-content: space-between;

  > div:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  ${media.greaterThan('tablet')`
    display: flex;

    > div:not(:last-child) {
      margin-bottom: 0;
      margin-right: 1.5rem;
    }
  `}
`

const Highlight = styled.b`
  color: ${({ theme }) => theme.colours.primary};
`

const Card = styled.div`
  background-color: ${({ theme }) => theme.colours.background};
  border-radius: ${({ theme }) => theme.radius.medium};
  padding: 2rem 3rem;
  flex: 50%;
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colours.text};
  font-size: clamp(1.6rem, 5rem, 1.8rem);
  margin-bottom: 2.0rem;
  letter-spacing: -0.05em;
`

const Text = styled.p`
  color: ${({ theme }) => theme.colours.darkPurpleGray};
  font-size: 1.4rem;
  font-weight: 600;
`

const ContentLoading = () => (
  <ContentLoader
    speed={2}
    width={100}
    height={185}
    backgroundColor={colours.contentLoadingColor3}
    foregroundColor={colours.contentLoadingColor4}
    style={{ width: '100%', height: '10rem' }}
  >
    <rect x='0' y='0' rx={radius.small} ry={radius.small} width='100%' height='7rem' />
  </ContentLoader>
)
