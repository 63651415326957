import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { PhotoIcon, SearchIcon } from './button-iconset'

const ButtonIcon = ({ kind = 'search', dark, action, url, className }) => {
  const ButtonIcon = iconComponent[kind]

  return (
    <ButtonIconWrapper dark={dark} action={action} href={url} className={className}>
      <ButtonIcon dark={dark} />
    </ButtonIconWrapper>
  )
}

ButtonIcon.propTypes = {
  kind: t.string,
  dark: t.bool,
  action: t.func,
  url: t.string,
  className: t.string,
}

const iconComponent = {
  photo: PhotoIcon,
  search: SearchIcon,
}

const ButtonIconWrapper = styled.a`
  position: relative;
  display: block;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .02);
  background: ${props => props.dark ? ({ theme }) => theme.colours.primary : '#fff'};
  transition: opacity .2s ease;

  &:hover {
    opacity: .95;
  }
`

export { ButtonIcon }
