import styled from 'styled-components'
import { theme, media } from 'ui'
import { animated } from 'react-spring'

export const Overlay = styled(animated.div)`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
  z-index: ${theme.zIndex.aboveAll};
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
 `

export const Wrapper = styled.div`
  position: relative;

  ${media.lessThan('tablet')`
    padding: 1.5rem;
    width: 100%;
  `}

  > iframe {
    border-radius: ${theme.radius.medium};

    ${media.lessThan('largeDesktop')`
      width: 640px;
      height: 480px;
    `}

   ${media.greaterThan('largeDesktop')`
      width: 760px;
      height: 570px;
    `}

    ${media.lessThan('tablet')`
      width: 100%;
      border-radius: ${theme.radius.small};
    `}

  }
`

export const CloseButton = styled.button`
  border: 0;
  outline: 0;
  background: 0;
  box-shadow: 0;
  position: absolute;
  top: -6rem;
  right: -6rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in 200ms;
  width: 4rem;
  height: 4rem;

  ${media.lessThan('tablet')`
    top: -5rem;
    right: 1rem;
  `}

  &:hover {
    background-color: rgba(0,0,0, 0.5);
    transform: rotate(90deg);
  }
`

export const Label = styled.p`
  color: white;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
`
