import React from 'react'
import t from 'prop-types'
import { BarChart, Bar, XAxis, YAxis, Cell, ResponsiveContainer } from 'recharts'

const VerticalBarChart = ({ data }) => {
  const colours = ['#C84DF3', '#F5A067', '#3CAFA4', '#6200EE', '#00B5EE', '#EE00AB', '#EEC800']

  return (
    <>
      <ResponsiveContainer height={240}>
        <BarChart
          data={data}
          margin={{
            top: 30,
            right: 20,
            bottom: 0,
            left: 20,
          }}
        >
          <YAxis type='number' />
          <XAxis dataKey='' type='category' />
          <Bar dataKey='grade' barSize={35} label={{ position: 'insideTop', style: { fill: 'white' }, fontSize: 18 }}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colours[index % colours.length]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}

VerticalBarChart.propTypes = {
  data: t.arrayOf(t.shape({
    grade: t.number.isRequired,
  })).isRequired,
}

export { VerticalBarChart }
