import React from 'react'
import styled from 'styled-components'
import { rgba, darken } from 'polished'
import { media } from 'ui'

import { Link } from 'react-router-dom'

import { resourcesTypes } from 'resources'

export const Item = styled.button`
  border: 0;
  background: 0;
  box-shadow: 0;
  outline: 0;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  background: ${({ theme }) => theme.colours.background};
  padding: 1.6rem 2rem;
  border-radius: ${({ theme }) => theme.radius.small};
  will-change: box-shadow;
  transition: box-shadow .1s ease;
  display: block;
  width: 100%;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.darkTheme ? rgba(theme.colours.secondary, 0.8) : rgba(theme.colours.gray4, 0.8)};

  ${({ watched, theme }) => !watched && `
    &:hover {
      box-shadow: 0 0 0 1px ${rgba(theme.colours.third, 0.5)};
    }
  `}

  ${({ active, theme }) => active && `
    background: ${rgba(theme.colours.third, 0.1)};
  `}

  ${({ watched, theme }) => watched && `
    background: ${theme.darkTheme ? rgba(theme.colours.primaryBlue, 0.05) : rgba(theme.colours.primary, 0.05)};
    border: 1px solid ${theme.colours.primary};

    & * {
      color: ${theme.colours.primary};
    }

   svg {
      opacity: 1;
    }
  `}
`

export const WatchedIconWrapper = styled.div`
  position: absolute;
  top: 1.6rem;
  right: 2rem;
  display: block;
  width: 2rem;
  height: 2rem;
`

export const Label = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const Icon = styled.div`
  width: 2rem;
  height: 2rem;

  svg {
    height: 100%;
  }
  ${({ side }) => side && `
  position: relative;
  z-index: 2;
  width: 2rem;
  height: 2rem;
  z-index: 2;
  right: 1.4rem;
  top: 2.4rem;

  `}
`

export const LabelText = styled.span`
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.primary};
  padding-left: 1rem;
  font-weight: 600;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: .1rem;
`

export const Content = styled.div`
  padding-top: 1.2rem;
  padding-left: ${({ playlist }) => playlist ? '3.1rem' : '.1rem'};;
  display: flex;
  flex-flow: column wrap;
  align-items: start;
`

export const ContentTitle = styled.h3`
  margin: 0;
  padding: 0;
  line-height: 1.4;
  font-size: 1.4rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colours.text};
  text-align: left;

  ${media.lessThan('phone')`
    line-height: 1.6;
  `}
`

export const TagGroup = styled.div`
  display: block;
  padding-top: .6rem;
`

export const Tag = styled.span`
  display: inline-block;
  padding: .4rem 1rem;
  border-radius: ${({ theme }) => theme.radius.large};
  font-size: 1.1rem;
  text-transform: lowercase;
  background-color: ${({ theme }) => theme.darkTheme ? theme.colours.background2 : darken(0.02, theme.colours.purpleGray)};
  color: ${({ theme }) => theme.darkTheme ? theme.colours.textSecondary : theme.colours.primary};
  &:not(:first-child){
    margin-left: 1.2rem;
  }

  ${({ bg, theme }) => bg && `
    background-color: ${bg};
    color: ${theme.colours.primary};
  `}

  ${media.lessThan('phone')`
    font-size: 1rem;
    margin-left: .6rem;

    &:first-child {
      margin-left: 0;
    }
  `}
`

export const Wrapper = styled.div`
  ${Item}:not(:last-child) {
    margin-bottom: 1rem;
  }
`

export const ItemLink = styled(({ watched, ...props }) => <Link {...props} />)`
  position: relative;
  display: block;
  text-decoration: none;
  background: ${({ theme }) => theme.colours.background};
  padding: 1.6rem 2rem;
  border-radius: ${({ theme }) => theme.radius.small};
  will-change: box-shadow;
  transition: box-shadow .1s ease;
  overflow: hidden;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.darkTheme ? rgba(theme.colours.secondary, 0.8) : rgba(theme.colours.gray4, 0.8)};

  ${({ watched, theme }) => !watched && `
    &:hover {
      box-shadow: 0 0 0 1px ${rgba(theme.colours.third, 0.5)};
    }
  `}

  ${({ active, theme }) => active && `
    background: ${rgba(theme.colours.third, 0.1)};
  `}

  ${({ watched, theme }) => watched && `
    background: ${rgba(theme.colours.primary, 0.05)};
    border: 1px solid ${theme.colours.primary};

    & * {
      color: ${theme.colours.primary};
    }

    svg {
      opacity: 1;
    }
  `}

  ${({ type }) => (type === resourcesTypes.SUBJECT || type === resourcesTypes.SIMULATED || type === resourcesTypes.PDFS) && `
    margin-top: 2.4rem;
  `}


  ${({ live }) => live && `
    display: flex;
    `}

&.disabled {
    color: currentColor;
    opacity: 0.5;
    text-decoration: none;
  }
  :not(:last-child) {
     margin-bottom: ${({ type }) => (type === resourcesTypes.SUBJECT || type === resourcesTypes.SIMULATED || type === resourcesTypes.PDFS) ? '2.4rem' : '0.8rem'};
    }

`

export const LabelIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${({ type, theme }) => (type === resourcesTypes.SUBJECT || type === resourcesTypes.SIMULATED || type === resourcesTypes.PDFS) && `
    padding-bottom: 1.4rem;
    margin-top: -3px;

    span {
      font-weight: bold !important;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: -.3rem;
      width: 5.2rem;
      height: 20rem;
      border-radius: 10px;
      left: -4rem;
      top: -2rem;
      background: ${theme.colours.primary};
      z-index: 1;
    }
  `}
`
export const Content1 = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Icon2 = styled.div`
  position: relative;
  width: 2rem;
  height: 2rem;
  z-index: 2;
  position: relative;
  width: 2rem;
  height: 2rem;
  z-index: 2;
  right: 1.4rem;
  top: 2.4rem;
`
