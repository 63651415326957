import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { useWindowHeight } from '@react-hook/window-size/throttled'
import {
  TitleSmall,
  Link,
  LogoutButton,
  ProfileAvatar,
  Button,
  SidebarFeaturedStats,
  SmallNotifications,
  FreePlanMessage,
} from 'ui'
import { STUDENT_AREA, NOTIFICATIONS } from 'routes'
import { useMe } from 'resources'

const StudentSidebar = () => {
  const { isFree } = useMe()
  const height = useWindowHeight()

  return (
    <Wrapper>
      <StudentSidebarHeader>
        <LogoutButton />
      </StudentSidebarHeader>

      <StudentSidebarSection center>
        <ProfileAvatar
          sidebar
        />
        {height > 700 && <ViewProfileButton variant='ghost' to={STUDENT_AREA}>Ver perfil</ViewProfileButton>}
      </StudentSidebarSection>

      {height > 670 &&
        <StudentSidebarSection>
          <SidebarFeaturedStats />
        </StudentSidebarSection>}

      <StudentSidebarSection
        title='Notificações'
        link='Ver todas'
        linkUrl={NOTIFICATIONS}
        noBorder
        isFree
      >
        {isFree !== undefined &&
          <>
            {isFree
              ? <FreePlanMessage size='small' variant='small' />
              : <SmallNotifications />}
          </>}
      </StudentSidebarSection>
    </Wrapper>
  )
}

const StudentSidebarSection = ({ title, link, linkUrl, center, noBorder, isFree, children }) => (
  <StudentSidebarWrapper center={center} noBorder={noBorder}>
    {title &&
      <StudentSidebarSectionHeader>
        <TitleSmall>{title}</TitleSmall>
        {link && !isFree && <LinkViewMore to={linkUrl}>{link}</LinkViewMore>}
      </StudentSidebarSectionHeader>}
    {children}
  </StudentSidebarWrapper>
)

StudentSidebarSection.propTypes = {
  title: t.string,
  link: t.string,
  linkUrl: t.string,
  center: t.bool,
  noBorder: t.bool,
  isFree: t.bool,
  children: t.node,
}

const Wrapper = styled.div`
  padding: 2rem 3rem .5rem;
  width: 100%;
`

const StudentSidebarHeader = styled.div`
  position: absolute;
  right: 4rem;
  top: 3rem;
`

const StudentSidebarWrapper = styled.div`
  padding: 3.2rem 0;
  border-bottom: ${props => props.noBorder ? '0' : '1px solid rgba(238, 238, 238, .5)'};
  text-align: ${props => props.center ? 'center' : 'left'};

  @media (max-height: 800px) {
    padding: 1.8rem 0;
  }

  @media (max-height: 900px) {
    padding: 2.4rem 0;
  }
`

const ViewProfileButton = styled(Button)`
  font-size: 1.2rem;
  padding: .72rem 1.92rem;
`

const StudentSidebarSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const LinkViewMore = styled(Link)`
  font-size: 1.3rem;
  margin-top: .3rem;
`

export { StudentSidebar }
