import { confirmAlert } from 'react-confirm-alert'
import React from 'react'
import styled from 'styled-components'
import './base-styles.css'
import { animated } from 'react-spring'
import { rgba } from 'polished'

import { darkTheme, theme } from 'ui'

export const logoutDialog = (logout) => {
  const themee = checkTheme()

  confirmAlert({
    // eslint-disable-next-line react/prop-types
    customUI: ({ onClose }) => {
      return (
        <Overlay theme={themee}>
          <WrapperDialog theme={themee}>
            <Title theme={themee}>Sessão expirada</Title>
            <Message theme={themee}>Sua sessão foi encerrada por inatividade ou porque você realizou o acesso em um outro dispositivo.</Message>

            <ButtonWrapper>
              <ButtonConfirm
                theme={themee} as='button' onClick={async () => {
                  await logout()
                  onClose()
                }}
              >Ok
              </ButtonConfirm>
            </ButtonWrapper>
          </WrapperDialog>
        </Overlay>
      )
    },
  })
}

export const futureDateWarning = (data) => {
  const themee = checkTheme()

  confirmAlert({
    // eslint-disable-next-line react/prop-types
    customUI: ({ onClose }) => {
      return (
        <Overlay theme={themee}>
          <WrapperDialog theme={themee}>
            <Title theme={themee}>Aviso!</Title>
            <Message theme={themee}>O cronograma terá início dia {data}.</Message>
            <ButtonWrapper>
              <ButtonConfirm theme={themee} onClick={onClose}>Ok</ButtonConfirm>
            </ButtonWrapper>
          </WrapperDialog>
        </Overlay>
      )
    },
    closeOnEscape: false,
    closeOnClickOutside: false,
  })
}

export const genericConfirm = (confirm) => {
  const themee = checkTheme()

  confirmAlert({
    // eslint-disable-next-line react/prop-types
    customUI: ({ onClose }) => {
      return (
        <Overlay theme={themee}>
          <WrapperDialog theme={themee}>
            <Message theme={themee}>Você tem certeza?</Message>
            <ButtonWrapper>
              <ButtonConfirm
                theme={themee} as='button' onClick={async () => {
                  await confirm()
                  onClose()
                }}
              >Sim, quero apagar!
              </ButtonConfirm>
              <ButtonCancel theme={themee} onClick={onClose}>Cancelar</ButtonCancel>
            </ButtonWrapper>
          </WrapperDialog>
        </Overlay>
      )
    },
    closeOnEscape: false,
    closeOnClickOutside: false,
  })
}

export const problemPDF = () => {
  const themee = checkTheme()

  confirmAlert({
    // eslint-disable-next-line react/prop-types
    customUI: ({ onClose }) => {
      return (
        <Overlay theme={themee}>
          <WrapperDialog theme={themee}>
            <Message theme={themee}>Tivemos problemas ao gerar o seu PDF.</Message>
            <ButtonWrapper>
              <ButtonConfirm theme={themee} onClick={onClose}>Voltar</ButtonConfirm>
            </ButtonWrapper>
          </WrapperDialog>
        </Overlay>
      )
    },
    closeOnEscape: false,
    closeOnClickOutside: false,
  })
}

export const closeTicketConfirm = (confirm) => {
  const themee = checkTheme()

  confirmAlert({
    // eslint-disable-next-line react/prop-types
    customUI: ({ onClose }) => {
      return (
        <Overlay theme={themee}>
          <WrapperDialog theme={themee}>
            <Message theme={themee}>Você tem certeza?</Message>
            <ButtonWrapper>
              <ButtonConfirm
                theme={themee} as='button' onClick={async () => {
                  await confirm()
                  onClose()
                }}
              >Sim, fechar chamado!
              </ButtonConfirm>
              <ButtonCancel theme={themee} onClick={onClose}>Cancelar</ButtonCancel>
            </ButtonWrapper>
          </WrapperDialog>
        </Overlay>
      )
    },
    closeOnEscape: true,
    closeOnClickOutside: true,
  })
}

export const essayPasteConfirm = (keep, clear) => {
  const themee = checkTheme()

  confirmAlert({
    // eslint-disable-next-line react/prop-types
    customUI: ({ onClose }) => {
      return (
        <Overlay theme={themee}>
          <WrapperDialog theme={themee}>
            <Title theme={themee}>Aviso!</Title>
            <Message theme={themee}>O seu texto excedeu o número de linhas disponíveis. Os caracteres excedentes serão desconsiderados. Verifique sua redação!</Message>
            <ButtonWrapper>
              <ButtonConfirm
                theme={themee} onClick={async () => {
                  await keep()
                  onClose()
                }}
              >Ok
              </ButtonConfirm>
              {/* <ButtonCancel
                theme={themee} onClick={async () => {
                  await clear()
                  onClose()
                }}
              >Cancelar
              </ButtonCancel> */}
            </ButtonWrapper>
          </WrapperDialog>
        </Overlay>
      )
    },
    closeOnEscape: false,
    closeOnClickOutside: false,
  })
}

const checkTheme = () => {
  const storedValue = localStorage.getItem('darkMode')
  let checkedTheme = theme
  const myBoolean = storedValue ? storedValue.toLowerCase() === 'true' : false

  if (myBoolean) {
    checkedTheme = darkTheme
  }
  return checkedTheme
}

const ButtonWrapper = styled.div`
    display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
`

const ButtonCancel = styled.button`
  outline: none;
  border: none;
  background: transparent;
  display: inline-block;
  padding: 6px 18px;
  color: #0049FF;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #0049FF;
`

const ButtonConfirm = styled.button`
  outline: none;
  background: #0049FF;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #fff;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  font-weight: bold;
  `

const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 2rem;
  color: ${({ theme }) => theme.colours.text};
`

const Message = styled.h3`
  color: ${({ theme }) => theme.colours.text};
  margin: 0;
  font-size: 16px;
`

const Overlay = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background:${({ theme }) => rgba(theme.colours.background, 0.8)};
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
`
const WrapperDialog = styled.div`
  width: 400px;
  padding: 30px;
  text-align: left;
  background: ${({ theme }) => rgba(theme.colours.background, 0.8)};
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #000;
  font-size: 1.6rem;
  line-height: 1.3;
`
