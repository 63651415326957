import React, { useState, useEffect } from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { Link, TitleMedium, media } from 'ui'
import { Collapse } from 'springs'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import { useLocation } from 'react-router-dom'

const ContentBox = ({
  children,
  title,
  subtitle,
  linkLabel,
  linkUrl,
  dark,
  style,
  className,
  collapsable,
  collapsed,
  keepOpen,
  marginBottom = false,
}) => {
  const location = useLocation()
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver })
  const [open, setOpen] = useState(!collapsed)

  const setCollapse = () => collapsable && setOpen(!open)

  useEffect(() => {
    if (!keepOpen) { setOpen(false) }
  }, [keepOpen, location])

  return (
    <Wrapper
      dark={dark}
      style={style}
      className={className}
      marginBottom={marginBottom}
    >
      <Header
        onClick={collapsable ? () => setCollapse() : null}
        collapsable={collapsable}
        isOpen={open}
      >
        {title && <TitleMedium>{title} {subtitle && <Subtitle>{subtitle}</Subtitle>}</TitleMedium>}
        {collapsable && <Icon isOpen={open} />}
        {!collapsable && linkLabel && <Link to={linkUrl}>{linkLabel}</Link>}
      </Header>
      {collapsable &&
        <Collapse trigger={open} size={bounds.height}>
          <div ref={ref}><Inner isOpen={open} collapsable={collapsable}>{children}</Inner></div>
        </Collapse>}
      {!collapsable && <Inner isOpen={open} collapsable={collapsable}>{children}</Inner>}
    </Wrapper>
  )
}

ContentBox.propTypes = {
  children: t.node.isRequired,
  title: t.string,
  subtitle: t.string,
  linkLabel: t.string,
  linkUrl: t.string,
  dark: t.bool,
  style: t.object,
  className: t.string,
  collapsable: t.bool,
  collapsed: t.bool,
  keepOpen: t.string,
  marginBottom: t.bool,
}

const Wrapper = styled.div`
  padding: 2rem;
  background: ${({ theme, dark }) => dark ? theme.darkTheme ? theme.colours.background2 : theme.colours.purpleGray : theme.darkTheme ? theme.colours.mainBackground : theme.colours.background};
  border-radius: ${({ theme }) => theme.radius.large};
  height: fit-content;
  color: ${({ theme }) => theme.colours.text};
  ${media.greaterThan('tablet')`
    padding: 3rem;
  `}
  ${({ marginBottom }) => marginBottom && 'margin-bottom: 20px;'}
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: margin-bottom .4s ease;
  will-change: margin-bottom;
  ${props => props.collapsable && 'cursor: pointer;'};
`

const Subtitle = styled.span`
color: ${({ theme }) => theme.darkTheme ? theme.colours.textSecondary : theme.colours.darkPurpleGray};
  padding-left: 2rem;
`

const Inner = styled.div`
  padding-top: 3rem;
`

const Icon = styled.span`
  position: relative;
  cursor: pointer;
  width: 1.6rem;
  height: .3rem;
  &:before,
  &:after {
    content: '';
    background: ${({ theme }) => theme.colours.primary};
    position: absolute;
    left: 0;
    top: 0;
    width: 1.6rem;
    height: .3rem;
    will-change: transform;
    transition: transform .3s ease;
  }
  &:after {
    transform-origin: center;
  }
  ${props => !props.isOpen && `
    &:after {
      transform: rotate(90deg);
    }
    &:before {
      transform: rotate(180deg);
    }
  `}
`

export { ContentBox }
