import React from 'react'
import t from 'prop-types'
import { useHelpSubjects } from './use-help-subjects'
import { Select } from 'ui/form/form'

export const HelpSubjectSelect = ({ disabled, ...props }) => {
  const { subjects, loading } = useHelpSubjects()

  return <Select {...props} items={subjects} loading={loading} disabled={loading || disabled} />
}

HelpSubjectSelect.propTypes = {
  disabled: t.bool,
}
