import { useQuery, gql } from '@apollo/client'
import { pipe, defaultTo, path, map } from 'ramda'

import { renameKeys } from 'resources/composable'

export const useSupportCategorySelect = () => {
  const { data, loading } = useQuery(SUPPORT_CATEGORIES_QUERY)

  return {
    supportCategories: toOptions(data),
    loading,
  }
}

const toOptions = pipe(
  path(['supportOptions', 'categories']),
  defaultTo([]),
  map(renameKeys({
    id: 'value',
    name: 'label',
  }))
)

const SUPPORT_CATEGORIES_QUERY = gql`
  query GetSupportCategories {
    supportOptions {
      categories {
        id
        name
      }
    }
  }
`
