import styled from 'styled-components'
import { rotate } from 'ui'

const defaultSize = '2.5rem'

export const Spinner = styled.div`
  width: ${({ size }) => size || defaultSize};
  height: ${({ size }) => size || defaultSize};
  font-size: ${({ size }) => size || defaultSize};
  border: solid .15em ${({ theme }) => theme.colours.gray2};
  border-left-color: transparent;
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
  animation: ${rotate} 1s linear infinite;
`
