import React, { useEffect, useState } from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { NavLink, useLocation } from 'react-router-dom'
import { menuItems, menuStudentItems } from './menu-items'
import { LogoutIcon, NotificationBell } from './menu-icons'
import { media, FreePlanMessageModal } from 'ui'
import { useAuth, useMe, useNotificationStatus } from 'resources'
import { SUPPORT } from 'routes'

const Menu = ({ className, textIsVisible }) => {
  const { hasSupport, isFree } = useMe()
  const filteredItems = hasSupport
    ? menuItems
    : menuItems.filter((item) => item.url !== SUPPORT)
  const { liveClassname, notifications } = useNotificationStatus()
  const { count } = notifications
  const [openFreePlanModal, setOpenFreePlanModal] = useState(false)

  const handleCloseFreePlanModal = () => {
    setOpenFreePlanModal(false)
  }
  const [activeMenuVideo, setActiveMenuVideo] = useState(false)
  const { pathname } = useLocation()

  const handleClick = (item) => (evt) => {
    const linkDisabled = isFree && !item.allowedToFree

    if (linkDisabled) {
      evt.preventDefault()

      setOpenFreePlanModal(true)
    }
  }

  useEffect(() => {
    setActiveMenuVideo(false)
    if (pathname.startsWith('/video-curso-de-discursiva')) {
      setActiveMenuVideo(true)
    }
  }, [pathname])
  return (
    <>
      <MenuNav className={className}>
        <MenuList>
          {filteredItems.map((item) => (
            <MenuItem key={item.url}>
              <MenuLink
                exact={item.exact}
                to={item.url}
                className={`${
                  item.options?.className
                    ? `${item.options.className(liveClassname)}`
                    : activeMenuVideo
                    ? item.label === 'Curso de Discursivas'
                      ? 'menu-item-active'
                      : 'menu-item'
                    : 'menu-item'
                } ${isFree && !item.allowedToFree ? 'disabled' : ''}`}
                onClick={handleClick(item)}
              >
                <MenuIcon>
                  <item.icon.component {...item.icon.props} />
                </MenuIcon>

                {item.options?.className(liveClassname) === 'live-started' ? (
                  <MenuText textIsVisible={textIsVisible}>
                    Ferretto+ ESTAMOS AO VIVO
                  </MenuText>
                ) : (
                  <MenuText textIsVisible={textIsVisible}>
                    {item.label}
                  </MenuText>
                )}
                {count > 0 && item.label === 'Comunidade' ? (
                  <NotificationBell count={count > 9 ? '9+' : count} />
                ) : null}
              </MenuLink>
            </MenuItem>
          ))}
        </MenuList>
      </MenuNav>

      {openFreePlanModal && (
        <FreePlanMessageModal handleClose={handleCloseFreePlanModal} />
      )}
    </>
  )
}

const MenuStudent = ({ className, textIsVisible }) => {
  const { logout } = useAuth()
  const { isFree } = useMe()

  const [openFreePlanModal, setOpenFreePlanModal] = useState(false)

  const handleCloseFreePlanModal = () => {
    setOpenFreePlanModal(false)
  }

  const handleClick = (item) => (evt) => {
    const linkDisabled = isFree && !item.allowedToFree

    if (linkDisabled) {
      evt.preventDefault()

      setOpenFreePlanModal(true)
    }
  }

  return (
    <>
      <MenuList className={className}>
        {menuStudentItems.map((item) => (
          <MenuItem key={item.url}>
            <MenuLink
              exact={item.exact}
              to={item.url}
              className={`menu-item ${
                isFree && !item.allowedToFree ? 'disabled' : ''
              }`}
              onClick={handleClick(item)}
            >
              <MenuIcon>
                <item.icon.component {...item.icon.props} />
              </MenuIcon>
              <MenuText textIsVisible={textIsVisible}>{item.label}</MenuText>
            </MenuLink>
          </MenuItem>
        ))}

        <MenuItem>
          <MenuLink as='button' onClick={logout}>
            <MenuIcon>
              <LogoutIcon />
            </MenuIcon>
            <MenuText textIsVisible={textIsVisible}>Logout</MenuText>
          </MenuLink>
        </MenuItem>
      </MenuList>

      {openFreePlanModal && (
        <FreePlanMessageModal handleClose={handleCloseFreePlanModal} />
      )}
    </>
  )
}

const LogoutButton = () => {
  const { logout } = useAuth()

  return (
    <MenuItem>
      <MenuLink as='button' onClick={logout} noPadding>
        <MenuText right>Logout</MenuText>
        <MenuIcon>
          <LogoutIcon />
        </MenuIcon>
      </MenuLink>
    </MenuItem>
  )
}

Menu.propTypes = {
  className: t.string,
  textIsVisible: t.bool.isRequired,
}

MenuStudent.propTypes = {
  className: t.string,
  textIsVisible: t.bool.isRequired,
}

const MenuNav = styled.nav`
  margin: 0;
  padding: 0;
`

const MenuText = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  padding-left: 0.6rem;
  color: ${({ theme }) => theme.colours.darkPurpleGray};
  ${({ right }) => right && 'padding-right: .6rem;'};

  opacity: 1;
  visibility: visible;
  white-space: nowrap;
  will-change: opacity, visibility;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  ${({ textIsVisible }) =>
    !textIsVisible &&
    `
    opacity: 0;
    visibility: hidden;
  `}
`

const MenuItem = styled.li`
  display: block;
`

const MenuIcon = styled.span`
  flex-basis: 3rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const activeClassName = 'menu-item-active'

const MenuLink = styled(NavLink).attrs({ activeClassName })`
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
  background-color: transparent;
  border: none;
  width: 100%;
  cursor: pointer;
  padding: ${({ noPadding }) => (noPadding ? '0' : '1.3rem 0')};

  @media (max-height: 700px) {
    padding: ${({ noPadding }) => (noPadding ? '0' : '1.1rem 0')} !important;
  }

  ${media.greaterThan('mediumDesktop')`
    padding: ${({ noPadding }) => (noPadding ? '0' : '1.4rem 0')};
  `}

  ${media.greaterThan('largeDesktop')`
    padding: ${({ noPadding }) => (noPadding ? '0' : '1.6rem 0')};
  `}

  &.disabled {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.3;
    text-decoration: none;
  }

  &.${activeClassName} {
    ${media.greaterThan('tablet')`
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: .5em;
        bottom: .5em;
        width: .4rem;
        border-radius: 2px 0 0 2px;
        background: ${({ theme }) => theme.colours.primary};
      }
    `}
  }

  &.menu-item-live {
    ${media.greaterThan('tablet')`
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: .5em;
        bottom: .5em;
        width: .4rem;
        border-radius: 2px 0 0 2px;
        background: ${({ theme }) => theme.colours.transmissions};
      }
    `}
  }

  &.live-started {
    ${media.greaterThan('tablet')`
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: .5em;
        bottom: .5em;
        width: .4rem;
        border-radius: 2px 0 0 2px;
        background: ${({ theme }) => theme.colours.transmissions};
      }
    `}
  }

  &.${activeClassName} > ${MenuText} {
    color: ${({ theme }) => theme.colours.text};
  }

  &.${activeClassName} > ${MenuIcon} * {
    stroke: ${({ theme }) => theme.colours.primary};
  }

  &.menu-item-live {
    > ${MenuText} {
      color: ${({ theme }) => theme.colours.transmissions};
    }
  }

  &.menu-item-live {
    > ${MenuIcon} * {
      stroke: ${({ theme }) => theme.colours.transmissions};
    }
  }

  &.live-started {
    > ${MenuText} {
      color: ${({ theme }) => theme.colours.transmissions};
    }
  }

  &.live-started {
    > ${MenuIcon} * {
      stroke: ${({ theme }) => theme.colours.transmissions};
    }
  }
`

const MenuList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  &.drop {
    ${MenuLink} {
      &:after {
        display: none;
      }
    }
  }
`

export { Menu, MenuStudent, LogoutButton }
