import React, { useState } from 'react'
import t from 'prop-types'
import { rgba } from 'polished'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const EssaysMenu = ({ context, disciplines: { id } }) => {
  const [isOpen, setIsOpen] = useState('')

  return (
    <Container>
      <br />
      <Type label={isOpen === 'aulas'} onClick={() => setIsOpen('aulas')}>Aulas</Type>
      <DropdownMenu key='aulas'>
        <DropButton label={isOpen === 'aulas'}>
          <Item key={1} to={`${context}/aulas/enem`}>enem</Item>
          <Item key={2} to={`${context}/aulas/vestibular`}>vestibular</Item>
        </DropButton>
      </DropdownMenu>
      <Type label={isOpen === 'temas'} onClick={() => setIsOpen('temas')}>Temas</Type>
      <DropdownMenu key='temas'>
        <DropButton label={isOpen === 'temas'}>
          <Item key={3} to={`${context}/temas/enem`}>enem</Item>
          <Item key={4} to={`${context}/temas/vestibular`}>vestibular</Item>
        </DropButton>
      </DropdownMenu>
      <Type label={isOpen === 'analise-de-temas-de-redacao'} onClick={() => setIsOpen('analise-de-temas-de-redacao')}>Análise de temas</Type>
      <DropdownMenu key='analise-de-temas-de-redacao'>
        <DropButton label={isOpen === 'analise-de-temas-de-redacao'}>
          <Item key={5} to={`${context}/analise-de-temas-de-redacao/enem`}>enem</Item>
          <Item key={6} to={`${context}/analise-de-temas-de-redacao/vestibular`}>vestibular</Item>
        </DropButton>
      </DropdownMenu>

      <Item label='minhas-redacoes' onClick={() => setIsOpen('minhas-redacoes')} key={7} to={`${context}/minhas-redacoes`}>Minhas Redações</Item>

    </Container>
  )
}
EssaysMenu.propTypes = {
  context: t.string.isRequired,
  disciplines: t.object.isRequired,
}

const Type = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  font-size:1.2rem;
  font-weight:bold;
  text-transform:uppercase;
  letter-spacing:.04em;
  color:${({ label, theme }) => label && theme.darkTheme ? theme.colours.primaryBlue : label ? theme.colours.secondary : theme.colours.darkPurpleGray};
  display:block;
  text-decoration:none;
  padding:1rem 0;
  position:relative;
  text-align: left;
`

const DropButton = styled.div`
  width: 100%;
  border-radius: 1rem;
  transition: all .3s ease-in-out;
  padding: 0 1rem;
  background-color: ${({ theme }) => theme.darkTheme ? theme.colours.background2 : rgba(theme.colours.text, 0.1)};
  height: ${({ label }) => label ? 'auto' : '0'};
  transform: ${({ label }) => label ? 'translateY()' : 'translateY(1rem)'};
  overflow: hidden;
  opacity: ${({ label }) => label ? 1 : 0};
  border-top: ${({ label, theme }) => theme.darkTheme && label ? `px solid ${theme.colours.primaryBlue}` : label && `px solid ${theme.colours.primary}`};
`

const Container = styled.div`
`

const DropdownMenu = styled.div`

  height: auto;
  gap: 16px;
`

const activeClassName = 'active-discipline'

const Item = styled(NavLink).attrs({ activeClassName })`
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .06em;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.darkPurpleGray};
  display: block;
  text-decoration: none;
  padding: 1rem 0;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  &.${activeClassName} {
    color: ${({ theme }) => theme.darkTheme ? theme.colours.primaryBlue : theme.colours.secondary};
  }
`
