import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'
import { Title, colours, radius } from 'ui'

export const PageTitle = ({ title }) => {
  if (!title) return <ContentLoading />

  return <Wrapper>{title}</Wrapper>
}

PageTitle.propTypes = {
  title: t.string,
}

const Wrapper = styled(Title)`
  font-weight: bold;
`

const ContentLoading = (props) => (
  <ContentLoader
    speed={2}
    width={100}
    height={48}
    backgroundColor={colours.contentLoadingColor1}
    foregroundColor={colours.contentLoadingColor2}
    style={{ width: '70%', height: '4.8rem' }}
    {...props}
  >
    <rect x='0' y='0' rx={radius.small} ry={radius.small} width='70%' height='4.8rem' />
  </ContentLoader>
)
