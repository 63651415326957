import React, { useEffect, useState } from 'react'
import t from 'prop-types'
import { LogoIcon, Switch } from 'ui'
import { Wrapper, ToggleButton, LogoWrapper, SpacedMenu, BottomMenu, DarkMode } from './components'
import { ReactComponent as CollapseIcon } from './sidebar-collapse-icon.svg'
import { usePageData } from 'resources'
import { HOME } from 'routes'

const Sidebar = ({ className }) => {
  const { isSidebarOpened, openSidebar, closeSidebar } = usePageData()
  const [isChecked, setIsChecked] = useState(false)

  const handleToggle = () => {
    if (isSidebarOpened) {
      closeSidebar()
      return
    }

    openSidebar()
  }
  useEffect(() => {
    const storedValue = localStorage.getItem('darkMode')
    if (storedValue !== null) {
      setIsChecked(storedValue === 'true')
    }
  }, [])
  const logoSize = isSidebarOpened ? '4.5rem' : '3.2rem'

  return (
    <Wrapper className={className} isOpen={isSidebarOpened}>
      <ToggleButton onClick={handleToggle}><CollapseIcon /></ToggleButton>

      <LogoWrapper>
        <LogoIcon width={logoSize} url={HOME} />
      </LogoWrapper>
      {
        isSidebarOpened ? (
          <DarkMode>
            Modo escuro <Switch checked={isChecked} isSidebarOpened />
          </DarkMode>) : (<Switch checked={isChecked} />)
      }

      <SpacedMenu textIsVisible={isSidebarOpened} />
      <BottomMenu textIsVisible={isSidebarOpened} />
    </Wrapper>
  )
}

Sidebar.propTypes = {
  className: t.string,
}

export { Sidebar }
