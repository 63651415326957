import React from 'react'
import t from 'prop-types'
import { useWindowSize } from '@react-hook/window-size/throttled'

import {
  Wrapper,
  Item,
  Thumbnail,
  ThumbnailOverlay,
  ThumbnailTime,
  ThumbnailIcon,
  Content,
  Title,
  Category,
  ItemLink,
} from './components'

const VideoList = ({ items = [] }) => {
  return (
    <Wrapper>
      {items.map(({ id, ...rest }) => (
        <Video key={id} {...rest} />
      ))}
    </Wrapper>
  )
}

const Video = ({ url, title, mainVideo, classGroup }) => {
  const [width] = useWindowSize()
  const shouldShowTime = mainVideo?.timeInSeconds && width > 580

  return (
    <Item>
      <ItemLink to={url}>
        <Thumbnail>
          <ThumbnailOverlay thumbnail={mainVideo?.thumbnail}>
            {shouldShowTime && <ThumbnailTime>{mainVideo?.timeInSeconds}</ThumbnailTime>}
            <ThumbnailIcon />
          </ThumbnailOverlay>
          {mainVideo?.thumbnail && <img src={mainVideo?.thumbnail} alt={title} />}
        </Thumbnail>
        <Content>
          <Category>{classGroup?.meta?.discipline?.name}</Category>
          <Title>{title}</Title>
        </Content>
      </ItemLink>
    </Item>
  )
}

VideoList.propTypes = {
  items: t.arrayOf(t.shape({
    id: t.string,
    url: t.string,
    title: t.string,
    timeInSeconds: t.string,
    thumbnail: t.string,
  })),
}

Video.propTypes = {
  url: t.string,
  title: t.string,
  mainVideo: t.shape({
    thumbnail: t.string,
    timeInSeconds: t.string,
  }),
  classGroup: t.shape({
    meta: t.shape({
      discipline: t.shape({
        name: t.string,
      }),
    }),
  }),
}

export { VideoList }
