import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'

const SelectableTag = ({ active, label, name, onChange }) => {
  const handleChange = e => onChange(e.target.name)

  return (
    <Wrapper active={active}>
      <Checkbox
        onChange={handleChange}
        name={name}
      />
      {label}
    </Wrapper>
  )
}

SelectableTag.propTypes = {
  active: t.bool,
  label: t.string.isRequired,
  name: t.string.isRequired,
  onChange: t.func.isRequired,
}

const Wrapper = styled.label`
  ${({ theme }) => `
    background-color: ${theme.darkTheme ? theme.colours.background2 : theme.colours.purpleGray};
    border-radius: ${theme.radius.small};
    color ${theme.darkTheme ? theme.colours.text : theme.colours.secondary};
  `}

  ${({ active, theme }) => active && `
    background-color: ${theme.darkTheme ? theme.colours.primaryBlue : theme.colours.secondary};
    color: ${theme.darkTheme ? theme.colours.text : theme.colours.background};
  `}

  text-transform: uppercase;
  font-weight: bold;
  padding: 1rem 1.5rem;
  transition: all .25s ease;
  cursor: pointer;
`

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  z-index: ${({ theme }) => theme.zIndex.hidden};
`

export { SelectableTag }
