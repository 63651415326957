import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { media } from 'ui'

import {
  Poster,
  Content,
  Title,
  Weeks,
  Description,
  Row,
  Item,
  Label,
  Value,
  ButtonDetails,
  ButtonContinue,
} from './components'

export const StudyPlanActive = ({ id, poster, title, weeks, description, url, urlMoreInfoActiveStudyPlan, releaseDate, audience, endsOn }) => {
  return (
    <ActiveBox key={id} active>
      <Poster>
        <img src={poster} alt={title} />
      </Poster>
      <ActiveContent>
        <div>
          <ActiveTitle>{title}</ActiveTitle>
          <Weeks>{weeks} semanas</Weeks>
          <Row>
            <Item>
              <Label>Início</Label>
              <Value>{releaseDate}</Value>
            </Item>
            <Item>
              <Label>Término</Label>
              <Value>{endsOn}</Value>
            </Item>
          </Row>
          <Description>{description}</Description>
        </div>
        <div>
          <ButtonContinue marginRight to={url}>Continuar</ButtonContinue>
          <ButtonDetails variant='ghost' to={urlMoreInfoActiveStudyPlan}>Mais detalhes</ButtonDetails>
        </div>
      </ActiveContent>
    </ActiveBox>
  )
}

StudyPlanActive.propTypes = {
  id: t.string,
  title: t.string,
  poster: t.string,
  description: t.string,
  url: t.string,
  urlMoreInfoActiveStudyPlan: t.string,
  weeks: t.number,
  releaseDate: t.string,
  audience: t.string,
  endsOn: t.string,
}

const ActiveBox = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border-radius: ${({ theme }) => theme.radius.small};
  padding: 2em;
  max-width: 90%;
  &:last-child {
  margin-left: 31px;
      }
  background: ${({ theme }) => theme.darkTheme ? theme.colours.background : theme.colours.purpleGray};
  ${media.lessThan('tablet')`
    max-width: 100%;
    &:last-child {
  margin-left: 0;
  margin-top: 20px;
      }

  `}
`

const ActiveContent = styled(Content)`
  ${media.greaterThan('tablet')`
      padding-left: 3rem;
  `}
`

const ActiveTitle = styled(Title)`
  font-size: 2rem;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.primary};
`
