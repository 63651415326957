import React from 'react'
import styled from 'styled-components'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { Link as RouterLink } from 'react-router-dom'

import { routeConfig } from 'routes'
import Divisor from './breadcrumb-divisor.svg'

const Breadcrumb = () => {
  const breadcrumbs = useBreadcrumbs(routeConfig)

  return (
    <List>
      {breadcrumbs.map(({ match, breadcrumb }) => (
        <Item key={match.url}>
          <Link to={match.url}>{breadcrumb}</Link>
        </Item>
      ))}
    </List>
  )
}

const Link = styled(RouterLink)`
  text-decoration: none;
  color: inherit;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
`

const Item = styled.li`
  color: ${({ theme }) => theme.colours.darkPurpleGray};
  font-size: 1.2rem;
  font-weight: 600;

  &:after {
    content: '';
    width: 0.6rem;
    height: 1rem;
    display: inline-block;
    background: url(${Divisor}) no-repeat;
    margin: 0 0.84rem;
  }

  &:last-child {
    &:after {
      display: none;
    }

    ${Link} {
      color: ${({ theme }) => theme.colours.primary};
      pointer-events: none;
    }
  }
`

export { Breadcrumb }
