import { useQuery } from '@apollo/client'
import { getNodes } from 'resources'

import { STATES_QUERY } from './graphql'

export const useStates = () => {
  const { data, loading, error } = useQuery(STATES_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        withClasses: true,
      },
    },
  })

  const states = getNodes('states', data).map(toStates)

  return {
    states,
    loading,
    error,
  }
}

const toStates = ({ id, name, slug }) => ({
  id,
  name,
  slug,
})
