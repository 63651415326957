import React from 'react'
import t from 'prop-types'
import styled, { keyframes } from 'styled-components'

const ProgressBar = ({ completed, total, background, foreground, showPercentage, showCompletedOfTotal, percentage, showTitle, title, height, label }) => {
  const percentageValue = completed && total ? getPercentage(completed, total) : percentage

  return (
    <Wrapper>
      <Content>
        {showTitle && <Label>{title}</Label>}
        {showCompletedOfTotal && <Label>{completed} de {total}</Label>}
      </Content>
      <Bar background={background} height={height}>
        <BarCompleted
          style={{ width: `${percentageValue}%` }}
          foreground={foreground}
          height={height}
        >{label ? <Number>{completed}</Number> : ''}
        </BarCompleted>
      </Bar>
      {showPercentage && <Percentage foreground={foreground}>{`${percentageValue}%`}</Percentage>}
    </Wrapper>
  )
}

ProgressBar.propTypes = {
  completed: t.number,
  total: t.number,
  title: t.string,
  percentage: t.oneOfType([t.number, t.string]),
  background: t.string,
  foreground: t.string,
  showPercentage: t.bool,
  showCompletedOfTotal: t.bool,
  showTitle: t.bool,
  label: t.bool,
  height: t.string,
}

const getPercentage = (value, max) => {
  let result = (value * 100) / max

  if (value > max) {
    result = 100
  }

  return result.toFixed()
}

const showProgress = keyframes`
  from {
    width: 0;
  }
  to {
    width: ${getPercentage(props => props.completed, props => props.total)}%;
  }
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const Bar = styled.div`
  width: 100%;
  border-radius: ${({ height = 'default' }) => {
    return {
      default: '2px',
      bigger: '10px',
    }[height]
  }};
  background: ${({ background }) => background || '#E4E4F3'};
  overflow: hidden;
  height: ${({ height = 'default' }) => {
    return {
      default: '4px',
      bigger: '15px',
    }[height]
  }};
`

const BarCompleted = styled.div`
  background: ${({ foreground, theme }) => theme.darkTheme ? foreground : foreground || theme.colours.primary};
  border-radius: ${({ height = 'default' }) => {
    return {
      default: '2px',
      bigger: '10px',
    }[height]
  }};
  height: 100%;
  width: 0;
  animation: ${showProgress} 1s ease forwards;
`

const Number = styled.div`
  color: white;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  justify-content: end;
  padding-right: 1rem;
  width: 100%;
`

const Percentage = styled.span`
  font-size: 1.4rem;
  color: ${({ foreground, theme }) => theme.darkTheme ? theme.colours.primaryBlue : foreground || theme.colours.primary};
  font-weight: bold;
  padding-left: 1rem;
`

const Label = styled.p`
  margin-bottom: 4px;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.gray1};
`
const Content = styled.div`
  display: flex;
  justify-content: space-between;
`

export { ProgressBar }
