import { pipe, path, defaultTo, curry } from 'ramda'

export const getNodes = curry(
  (nodeName, data, field = 'nodes') => pipe(
    path([nodeName, field]),
    defaultTo([])
  )(data)
)

export const getPagination = curry(
  (nodeName, data) => pipe(
    path([nodeName, 'pagination']),
    defaultTo({
      page: 0,
      total: 0,
      lastPage: 0,
      perPage: 0,
    })
  )(data)
)

export const safelyGetFields = curry(
  (fields, data) => pipe(
    path(fields),
    defaultTo([])
  )(data)
)
