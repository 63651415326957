import { rgba } from 'polished'
import styled from 'styled-components'
import { Button, media } from 'ui'
import { radius } from 'ui/theme'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(38rem, 1fr));
  grid-gap: 3rem;

  ${media.lessThan('phone')`
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  `}
`

const FutureDate = styled.div`
  width: 25rem;
  height: 4rem;
  opacity: 159;
  background:  ${({ theme }) => theme.colours.background};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  font-size: 1.5rem;
  border: 2px solid ${({ theme }) => theme.colours.primary};
  color: ${({ theme }) => theme.colours.primary};
  padding: 2rem;
  position: absolute;
  font-weight: 600;
  z-index: 99;
  flex-wrap: wrap;
  align-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`

const Box = styled.div`
background: ${({ theme, active }) => active && theme.darkTheme ? theme.colours.mainBackground : theme.colours.background};
${({ futureDate }) => {
  if (futureDate) {
    return `
      background: rgb(172 180 195 / 50%);
    `
  }
}}
  position: relative;
  display: flex;
  width: 100%;
  border-radius: ${({ theme }) => theme.radius.small};
  padding: 2em;
`

const Poster = styled.div`
${({ futureDate }) => {
  if (futureDate) {
    return `
      opacity: 0.4;
    `
  }
}}
  flex-basis: 18rem;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;
    border-radius: ${({ theme }) => theme.radius.small};
    display: block;

    ${media.lessThan('mediumDesktop')`
      height: auto;
    `}
  }

  ${media.lessThan('tablet')`
    flex-basis: 15rem;
  `}

  ${media.lessThan('phone')`
    flex-basis: 8rem;
  `}
`

const Content = styled.div`
${({ futureDate }) => {
  if (futureDate) {
    return `
      opacity: 0.4;
    `
  }
}}
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 2em;

  ${Button} {
    flex-grow: 0;
    margin-top: 1rem;
  }

  ${media.greaterThan('tablet')`
    padding-left: 2.2rem;
  `}
`

const Title = styled.h3`
  margin: 0;
  padding: .4rem 0;
  color: ${({ theme }) => theme.colours.primary};
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.4;

  ${media.lessThan('tablet')`
    font-size: 1.5rem;
    padding-top: 0;
  `}

  ${media.lessThan('phone')`
    font-size: 1.4rem;
  `}
`

const Weeks = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: -0.08em;
  border: solid 1px red;
  border-radius: ${radius.small};
  background: ${({ theme }) => rgba(theme.colours.primary, 0.05)};
  border: solid 1px ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.primary};
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.primary};
  padding: .25rem .75rem;
  text-transform: uppercase;
  margin-top: .35rem;
  margin-bottom: .75rem;
  display: inline-block;
`

const Description = styled.p`
  margin-top: .35rem;
  margin-bottom: .5rem;
  padding: 0;
  line-height: 1.6;
  max-width: 93%;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: -0.03em;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.gray1};

  ${media.lessThan('tablet')`
    max-width: 100%;
    font-size: 1.2rem;
    margin-bottom: 1.5em;
  `}
`

const ButtonDetails = styled(Button)`
  padding: 1.2rem 2.3rem;
  ${media.lessThan('tablet')`
    padding: 1rem 2rem;
  `}
`
const ButtonContinue = styled(Button)`
  height: 45px;
  padding: 1.2rem 2.3rem;
  margin-right: ${({ marginRight }) => marginRight ? '5px' : 'none'};

  ${media.lessThan('tablet')`
    padding: 1rem 2rem;
  `}
`

const Row = styled.div`
  display: flex;
  margin: 1rem 0;
`

const Item = styled.div`
  &:not(:first-child) {
    margin-left: 2rem;
  }
`

const Label = styled.p`
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: -0.03em;
  color: ${({ theme }) => rgba(theme.colours.text, 0.7)};
`

const Value = styled.p`
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.gray1};
`

const ReleaseDate = styled.p`
padding: 5px;
color: ${({ theme }) => theme.darkTheme ? theme.colours.text : theme.colours.gray1};

`

export { Wrapper, Box, Poster, Content, Title, Weeks, Description, ButtonContinue, ButtonDetails, Row, Item, Label, Value, FutureDate, ReleaseDate }
