import React from 'react'
import t from 'prop-types'
import { theme } from 'ui'
import styled from 'styled-components'

const LineChart = ({ data }) => {
  return (
    <>
      <Wrapper>
        <ChartContainer>
          <Number>{data.total}</Number>
          <Label data={data}>Você: {data.resolved}</Label>
          <Ball data={data} />
        </ChartContainer>
      </Wrapper>
    </>
  )
}

LineChart.propTypes = {
  data: t.object,
}

export { LineChart }

const Wrapper = styled.div`
  background: transparent;
  padding: 0 24px;
  color: white;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 150px;
  max-width: 200px;
`

const ChartContainer = styled.div`
  height: 200px;
  border-left: 1px solid gray;
  width: 200px;
  position: relative;
  transform: rotate(60deg);
  margin-left: 50px;
  margin-top: 120px;
`

const Number = styled.div`
  color: gray;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.15px;
  transform: rotate(300deg);
  margin-top: -25px;
  margin-left: -100px;
`

const Label = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.darkTheme ? 'white' : 'black'};
  background-color: ${({ theme }) => theme.darkTheme ? theme.colours.mainBackground : theme.colours.background};
  border: 1px solid gray;
  padding: 0.5rem;
  border-radius: 20px;
  bottom: ${({ data }) => data.percentage}%;
  transform: translate(-100%) rotate(300deg);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  text-wrap: nowrap;
`

const Ball = styled.div`
  position: absolute;
  height: 14px;
  width: 14px;
  border: 2px solid ${theme.colours.success};
  background-color: ${({ theme }) => theme.darkTheme ? theme.colours.mainBackground : theme.colours.background};
  border-radius: 100%;
  bottom: ${({ data }) => data.percentage}%;
  left: -8px;
`
