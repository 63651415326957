import { useQuery, gql } from '@apollo/client'

export const useDoIHaveNewMesssage = (params) => {
  useQuery(DO_I_HAVE_NEW_MESSAGE, {
    pollInterval: 180000,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    ...params,
  })
}

export const useUpdateDoIHaveNewMesssage = (params) => {
  useQuery(DO_I_HAVE_NEW_MESSAGE, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    ...params,
  })
}
const DO_I_HAVE_NEW_MESSAGE = gql`
  query DoIHaveNewMessage {
    doIHaveNewMessage {
      message
      payload {
        ... on Live {
          id
          title
          description
          icon
          liveDate
          endDate
          linkYoutube
          linkSambaTech
          attachment
        }
        ... on MessageStringPayload {
          payload
        }
        ... on MessageCommunityNotification {
          count
        }
      }
    }
  }
`
