import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { media } from 'ui'
import PlayIcon from './play-icon.svg'

const Item = styled.li`
  display: block;
`

const Wrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  ${Item} {
    padding-bottom: 2.5rem;

    &:last-child {
      padding-bottom: 0;
    }
  }
`

const Thumbnail = styled.div`
  position: relative;
  width: 100%;
  height: 4.7rem;
  border-radius: ${({ theme }) => theme.radius.small};
  overflow: hidden;
  flex-basis: 20rem;
  max-width: 8.5rem;
  min-width: 8.5rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(100%);
  }

  ${media.greaterThan('phone')`
    max-width: 14.2rem;
    min-width: 14.2rem;
    height: 8rem;
  `}
`

const ThumbnailOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: ${({ thumbnail }) => thumbnail ? 'rgba(0, 73, 255, .7)' : 'rgba(0, 73, 255, 1)'};
  z-index: ${({ theme }) => theme.zIndex.base};
  display: flex;
  align-items: center;
  justify-content: center;
`

const ThumbnailTime = styled.span`
  color: #fff;
  font-size: 1.4rem;
  margin-right: 1.4rem;
  margin-top: -.2rem;
`

const ThumbnailIcon = styled.span`
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  background: url(${PlayIcon}) no-repeat;
  background-size: 100% auto;
`

const Content = styled.div`
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  ${media.greaterThan('phone')`
    padding-left: 3em;
  `}
`

const Title = styled.h3`
  margin: 0;
  padding: 0 0 .56rem 0;
  color: ${({ theme }) => theme.colours.text};
  font-size: 1.4rem;
  font-weight: bold;
`

const Category = styled.span`
  display: block;
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: .6rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colours.primary};
`

const ItemLink = styled(Link)`
  display: flex;
  width: 100%;
  height: 100%;
  text-decoration: none;
  transition: opacity .2s ease;
  align-items: center;

  &:hover {
    opacity: .93;
  }
`

export {
  Wrapper,
  Item,
  Thumbnail,
  ThumbnailOverlay,
  ThumbnailTime,
  ThumbnailIcon,
  Content,
  Title,
  Category,
  ItemLink,
}
