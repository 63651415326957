import React from 'react'
import t from 'prop-types'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import { useWindowSize } from '@react-hook/window-size/throttled'

import { AvatarPicture } from 'ui'
import { formatDate, setTextMaxLength } from 'resources'
import { Collapse } from 'springs'

import { useNotificationCollapse } from './hooks'
import {
  Item,
  ReadLabel,
  Header,
  Content,
  HiddenContentInner,
  HiddenContent,
  TextHeader,
  Title,
} from './components'

const DefaultNotification = ({ onMarkAsRead, id, author, releaseDate, title, description, read }) => {
  const { isOpen, handleOpen } = useNotificationCollapse(onMarkAsRead)
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver })
  const [width] = useWindowSize()

  return (
    <Item isOpen={isOpen} isRead={read} onClick={() => handleOpen(id, read)}>
      {read && <ReadLabel>Lida</ReadLabel>}
      <Header>
        <AvatarPicture name={author.name} photo={author?.avatar} size='3.6rem' />
        <Content>
          {width >= 900 &&
            <TextHeader>
              {author.name} • {formatDate(releaseDate)}
            </TextHeader>}
          <Title>
            {isOpen ? title : setTextMaxLength(title)}
          </Title>
        </Content>
      </Header>
      <Collapse size={bounds.height} trigger={isOpen}>
        <HiddenContent ref={ref}>
          <HiddenContentInner dangerouslySetInnerHTML={{ __html: description }} />
        </HiddenContent>
      </Collapse>
    </Item>
  )
}

DefaultNotification.propTypes = {
  id: t.string.isRequired,
  author: t.shape({
    name: t.string.isRequired,
    avatar: t.string,
  }).isRequired,
  releaseDate: t.string.isRequired,
  title: t.string.isRequired,
  description: t.string.isRequired,
  read: t.bool.isRequired,
  onMarkAsRead: t.func,
}

export { DefaultNotification }
