import localforage from 'localforage'

const USER_TOKEN = 'USER_TOKEN_WEB'
const USER_EMAIL = 'USER_EMAIL_WEB'

const setToken = token => localforage.setItem(USER_TOKEN, token)

const getToken = () => localforage.getItem(USER_TOKEN)

const removeToken = (successCallback) => localforage.removeItem(USER_TOKEN, successCallback)

const setEmail = value => localforage.setItem(USER_EMAIL, value)

const getEmail = () => localforage.getItem(USER_EMAIL)

export { setToken, getToken, removeToken, setEmail, getEmail }
