import React, { useState } from 'react'
import t from 'prop-types'
import { useMe } from 'resources'
import styled from 'styled-components'
import { ButtonPlanMessage } from 'ui/button-plan-message/button-plan-message'

export const PremiumContent = ({ children }) => {
  const { isFree } = useMe()

  if (!isFree) {
    return children
  }

  return (
    <PremiumContentForFreeUsersWrapper>
      {children}
    </PremiumContentForFreeUsersWrapper>
  )
}

PremiumContent.propTypes = {
  children: t.node.isRequired,
}

const PremiumContentForFreeUsersWrapper = ({ children }) => {
  const [showPremiumMessage, setShowPremiumMessage] = useState(false)

  const handleShowPremiumMessage = () => setShowPremiumMessage(true)
  const handleClosePremiumMessage = () => setShowPremiumMessage(false)

  return (
    <StyledPremiumContentForFreeUsersWrapper
      onMouseOver={handleShowPremiumMessage}
      onMouseLeave={handleClosePremiumMessage}
    >
      {showPremiumMessage && (
        <>
          <PremiumMessageOverlay />
          <PremiumMessage>
            <p>Este conteúdo é exclusivo para assinantes.</p>
            <ButtonPlanMessage />
          </PremiumMessage>
        </>
      )}
      <PremiumContentWrapper>{children}</PremiumContentWrapper>
    </StyledPremiumContentForFreeUsersWrapper>
  )
}

PremiumContentForFreeUsersWrapper.propTypes = {
  children: t.node.isRequired,
}

const PremiumMessage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1.6rem;
  color: ${({ theme }) => theme.colours.text};
  font-size: 1.6rem;
  z-index: ${({ theme }) => theme.zIndex.top};
}`

const PremiumMessageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colours.purpleGray};
  opacity: 0.75;
  transition: opacity 0.5s;
  z-index: ${({ theme }) => theme.zIndex.top};
}`

const StyledPremiumContentForFreeUsersWrapper = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.base};
  padding: 1.6rem;

  ${ButtonPlanMessage} {
    max-width: 90%;
  }
`

const PremiumContentWrapper = styled.div`
  opacity: 0.6;
  transition: opacity 0.3s;
`
