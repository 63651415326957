import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import { ReactComponent as IconError } from './error-icon.svg'
import { Button } from 'ui'

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      hasError: false,
      hasNetworkError: false,
      message: '',
    }
  }

  registerNetworkListener = () => {
    window.addEventListener('online', this.clearErrors)
    window.addEventListener('offline', this.showNetworkError)
  }

  clearErrors = () => {
    this.setState(emptyError)
  }

  showNetworkError = () => {
    this.setState(networkError)
  }

  componentDidMount () {
    this.registerNetworkListener()
  }

  componentWillUnmount () {
    window.removeEventListener('online', this.clearErrors)
    window.removeEventListener('offline', this.showNetworkError)
  }

  componentDidUpdate (previous) {
    const { pathname: previousPath } = previous.location
    const { pathname } = this.props.location

    if (pathname !== previousPath) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(emptyError)
    }
  }

  static getDerivedStateFromError (error) {
    if (error) { return unexpectedError }

    return emptyError
  }

  reloadPage = () => {
    window.location.reload(true)
  }

  render () {
    const { children } = this.props
    const { message, hasNetworkError } = this.state

    if (this.state.hasError) {
      return (
        <Container>
          <IconError />
          <ErrorMessage dangerouslySetInnerHTML={{ __html: message }} />
          <Button onClick={this.reloadPage}>Tentar novamente</Button>
        </Container>
      )
    }

    return (
      <>
        <ErrorNetwork active={hasNetworkError}>{message}</ErrorNetwork>
        {children}
      </>
    )
  }
}

const unexpectedError = {
  hasError: true,
  message: 'Ocorreu um erro inesperado.<br />Atualize a página ou tente novamente em alguns instantes.',
}

const networkError = {
  hasNetworkError: true,
  message: 'Parece que você está sem acesso a internet. Verifique sua conexão e tente novamente.',
}

const emptyError = {
  hasError: false,
  hasNetworkError: false,
  message: '',
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.aboveAll};
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  backdrop-filter: blur(.2rem);
`

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colours.secondary};
  font-size: 1.5rem;
  font-weight: 600;
  margin: 2rem auto;
  text-align: center;
  line-height: 1.6;
`

const ErrorNetwork = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colours.error};
  color: ${({ theme }) => theme.colours.text};
  height: 7rem;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  transform: translateY(-100%);
  font-size: 2rem;
  font-weight: 600;
  padding: 0 1.5rem;
  z-index: ${({ theme }) => theme.zIndex.aboveAll};

  ${({ active }) => active && `
    transform: translateY(0);
  `}
`

ErrorBoundary.propTypes = {
  children: t.node.isRequired,
  location: t.shape({
    pathname: t.string.isRequired,
  }).isRequired,
}

export default withRouter(ErrorBoundary)
