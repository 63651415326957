import React from 'react'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'

import { ContentBox, ErrorMessage, SelectableTag, colours, radius } from 'ui'
import { errorMessages, useQueryString } from 'resources'
import { useDisciplinesFilter } from './use-disciplines-filter'
import { PremiumContent } from 'ui/premium-content/premium-content'

const DisciplinesFilter = () => {
  const [value, setValue] = useQueryString('filter')
  const { disciplines, loading, error } = useDisciplinesFilter()

  if (error) {
    return (
      <ContentBox>
        <ErrorMessage message={errorMessages.DISCIPLINES_ERROR} hasRefresh />
      </ContentBox>
    )
  }

  return (
    <PremiumContent>
      <ContentBox title='Simulados realizados'>
        <Filter>
          {loading ? (
            <ContentLoading />
          ) : (
            disciplines.map(({ id, name, slug }) => (
              <SelectableTag
                key={id}
                label={name}
                name={slug}
                active={value.some((item) => item === slug)}
                onChange={setValue}
              />
            ))
          )}
        </Filter>
      </ContentBox>
    </PremiumContent>
  )
}

const Filter = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1rem 0 0 -1rem;
  width: calc(100% + 1rem);

  > * {
    margin: 1rem 0 0 1rem;
  }
`

const ContentLoading = () => (
  <ContentLoader
    speed={2}
    width={100}
    height={185}
    backgroundColor={colours.contentLoadingColor3}
    foregroundColor={colours.contentLoadingColor4}
    style={{ width: '100%', height: '18.5rem' }}
  >
    <rect
      x='0'
      y='0'
      rx={radius.small}
      ry={radius.small}
      width='100%'
      height='3.5rem'
    />
    <rect
      x='0'
      y='50'
      rx={radius.small}
      ry={radius.small}
      width='100%'
      height='3.5rem'
    />
    <rect
      x='0'
      y='100'
      rx={radius.small}
      ry={radius.small}
      width='100%'
      height='3.5rem'
    />
    <rect
      x='0'
      y='150'
      rx={radius.small}
      ry={radius.small}
      width='100%'
      height='3.5rem'
    />
  </ContentLoader>
)

export { DisciplinesFilter }
