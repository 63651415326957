import React from 'react'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'
import { isEmpty } from 'ramda'

import { AvatarPicture, ErrorMessage, colours, radius } from 'ui'
import { formatDate, setTextMaxLength, errorMessages } from 'resources'

import {
  Content,
  ItemSmallWrapper,
  TextHeader,
  SmallWrapper,
  Title,
  Empty,
} from './components'
import { useNotifications } from './hooks'

const SmallNotifications = () => {
  const { userNotifications, loading, error } = useNotifications()

  if (loading) return <ContentLoading />

  if (error) return <ErrorMessage message={errorMessages.NOTIFICATIONS_ERROR} textSize='medium' hasRefresh />

  if (isEmpty(userNotifications) || !userNotifications) {
    return <Empty small>Você não tem novas notificações.</Empty>
  }

  return (
    <SmallWrapper>
      {userNotifications.map(({ id, author, releaseDate, title }) => (
        <ItemSmallWrapper key={id}>
          <AvatarPicture name={author?.name} photo={author?.avatar} size='3.4rem' />
          <Content>
            <TextHeader small>
              {author?.name} • {formatDate(releaseDate)}
            </TextHeader>
            <Title small>
              {setTextMaxLength(title, 45)}
            </Title>
          </Content>
        </ItemSmallWrapper>
      ))}
    </SmallWrapper>
  )
}

const LoadingWrapper = styled.div`
  padding-top: 3rem;
`

const ContentLoading = () => (
  <LoadingWrapper>
    <ContentLoader
      speed={2}
      width={100}
      height={220}
      backgroundColor={colours.contentLoadingColor3}
      foregroundColor={colours.contentLoadingColor4}
      style={{ width: '100%', height: '22rem' }}
    >
      <rect x='0' y='0' rx={radius.small} ry={radius.small} width='100%' height='5.3rem' />
      <rect x='0' y='73' rx={radius.small} ry={radius.small} width='100%' height='5.3rem' />
      <rect x='0' y='146' rx={radius.small} ry={radius.small} width='100%' height='5.3rem' />
    </ContentLoader>
  </LoadingWrapper>
)

export { SmallNotifications }
