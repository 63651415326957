import React from 'react'
import t from 'prop-types'
import styled, { css } from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { theme, media } from 'ui'
import { isNil } from 'ramda'

import clipIcon from './clip-icon.svg'
import closeIcon from './close.svg'

const FileUpload = ({ theme = 'dark', label, onChange, file, disable, isMonitor }) => {
  const onDrop = acceptedFiles => {
    const [file] = acceptedFiles
    const maxSize = 4.5 * 1024 * 1024
    if (file.size > maxSize) {
      alert('O arquivo excede o tamanho máximo de 4.5 MB.')
      return
    }
    onChange(file)
  }

  const removeFile = () => {
    onChange(null)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: isMonitor ? 'image/jpeg, image/png, application/pdf, video/mp4' : 'image/jpeg, image/png' })

  if (isNil(file)) {
    return (
      <Wrapper disabled={disable} isDragActive={isDragActive} {...getRootProps()}>
        <input disabled={disable} {...getInputProps()} />

        <Content>
          <img src={clipIcon} alt='Ícone para anexo' />
          <p>{label || 'Faça upload do arquivo'}</p>
        </Content>

      </Wrapper>
    )
  }

  return (
    <Preview theme={theme}>
      <p>{file.name}</p>
      <RemoveButton onClick={removeFile} />
    </Preview>
  )
}

FileUpload.propTypes = {
  onChange: t.func.isRequired,
  file: t.object,
  label: t.string,
  theme: t.string,
  disable: t.bool,
  isMonitor: t.bool,
}

const Wrapper = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  height: 7rem;
  border-radius: 10px;
  display: block;
  border: 2px dashed ${theme.colours.gray3};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 200ms;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  outline: none;
  filter: ${({ disabled }) => disabled ? 'grayscale(100%)' : 'none'};
  :hover {
    border-color: ${theme.colours.primary};
  }

  ${p => p.isDragActive && css`
    border-color: ${theme.colours.primary};
  `}

  > input {
    display: none;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > p {
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 1rem;

    ${media.lessThan('phone')`
      display: none;
    `}
  }
`

const Preview = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  height: 7rem;
  border-radius: 10px;
  display: block;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;

  ${p => p.theme === 'dark' && css`
    background: ${theme.colours.purpleGray};
  `}

  ${p => p.theme === 'white' && css`
    background: white;
  `}
  > p {
    font-size: 1.4rem;
    font-weight: bold;
    color: ${theme.colours.primary};
  }
`

const RemoveButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;

  :after{
    content: url('${closeIcon}');
  }
`

export { FileUpload }
