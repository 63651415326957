import React from 'react'
import styled from 'styled-components'
import { colours, radius, zIndex, darkTheme } from 'ui'
import ContentLoader from 'react-content-loader'

const Wrapper = styled.div`
  position: relative;
  padding: .7rem 1rem;
  border-radius: ${({ theme }) => theme.radius.medium};
  transition: background-color .25s ease;
  min-width: 25rem;

  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.colours.background};
  }

  ${({ isOpen, theme }) => isOpen && `
    background-color: ${theme.colours.background};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
`

const ArrowDown = styled.span`
  display: block;
  width: 1rem;
  height: 1rem;
  margin-top: -0.5rem;
  margin-left: 1rem;
  opacity: .4;
  transition: opacity .1s ease;

  svg {
    max-width: 100%;
  }
`

const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  white-space: nowrap;
`

const Text = styled.div`
  padding-left: 1.5rem;
  padding-right: 1rem;
  margin-right: auto;
  user-select: none;
`

const Name = styled.span`
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colours.text};
`

const Mail = styled.span`
  display: block;
  font-size: 1.1rem;
  color: #777;
`

const DropWrapper = styled.div`
  padding: 1.5rem;
`

const ContentLoading = (props) => (
  <ContentLoader
    speed={2}
    width={200}
    height={40}
    viewBox='0 0 200 40'
    backgroundColor={colours.contentLoadingColor1}
    foregroundColor={colours.contentLoadingColor2}
    {...props}
  >
    <rect x='55' y='8' rx='3' ry='3' width='111' height='9' />
    <rect x='55' y='26' rx='3' ry='3' width='111' height='7' />
    <circle cx='20' cy='20' r='20' />
  </ContentLoader>
)

const dropdownSpringStyles = {
  position: 'absolute',
  top: '4.8rem',
  left: 0,
  right: 0,
  height: '13rem',
  backgroundColor: colours.background,
  zIndex: zIndex.top,
  borderBottomLeftRadius: radius.medium,
  borderBottomRightRadius: radius.medium,
}

const dropdownSpringStylesDarkMode = {
  position: 'absolute',
  top: '4.8rem',
  left: 0,
  right: 0,
  height: '13rem',
  backgroundColor: darkTheme.colours.background,
  zIndex: zIndex.top,
  borderBottomLeftRadius: radius.medium,
  borderBottomRightRadius: radius.medium,
}

export {
  Wrapper,
  ArrowDown,
  Item,
  Text,
  Name,
  Mail,
  DropWrapper,
  ContentLoading,
  dropdownSpringStyles,
  dropdownSpringStylesDarkMode,
}
