import React from 'react'
import styled from 'styled-components'
import styledBy from 'styled-by'
import t from 'prop-types'

import { ButtonPlanMessage } from 'ui'
import { errorMessages } from 'resources'

const FreePlanMessage = ({ size = 'medium', variant = 'success' }) => {
  return (
    <Wrapper>
      <Text size={size}>
        {errorMessages.NOT_ALLOWED_TO_FREE}
      </Text>
      <ButtonPlanMessage size={size} variant={variant} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
`

const Text = styled.p`
  margin: 0 auto;
  color: #000;
  text-align: center;
  padding-bottom: 2.5rem;

  ${styledBy('size', {
    small: () => 'font-size: 1.4rem; padding-bottom: 1.6rem;',
    medium: () => 'font-size: 1.5rem; font-weight: 600; line-height: 1.8;',
  })};
`

FreePlanMessage.propTypes = {
  size: t.string,
  variant: t.string,
}

export { FreePlanMessage }
