import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'

import { httpLink } from './http-link'
import { authLink } from './auth-link'
import { unauthorizedErrorLink } from './unauthorized-link'

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        stats: {
          merge: (existing, incoming, opts) =>
            opts.mergeObjects(existing, incoming),
        },
      },
    },
  },
  possibleTypes: {
    ResourceItem: ['Class', 'Subject', 'SimulatedMetaData', 'SimulatedMetaDataStudyPlan'],
  },
})

const client = new ApolloClient({
  link: ApolloLink.from([
    unauthorizedErrorLink,
    authLink,
    httpLink,
  ]),
  cache,
})

export default client
