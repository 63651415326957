import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import ContentLoader from 'react-content-loader'
import { colours, radius, media } from 'ui'
import { Link } from 'react-router-dom'

const Wrapper = styled.section`
  padding: 0 0 3rem;
`

const CloseWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 2rem;
  padding-bottom: .8rem;
`

const Close = styled.span`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colours.secondary};
  font-weight: 600;
  transition: opacity .2s ease;
  cursor: pointer;

  &:hover {
    opacity: .9;
  }

  &:before {
    content: '×';
    display: inline-block;
    font-size: 2.2rem;
    vertical-align: middle;
    margin-right: .2em;
    line-height: 0;
  }
`

const Box = styled.div`
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    border-radius: ${({ theme }) => theme.radius.large};
    object-fit: cover;
  }
`

const AnnoucementLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  text-decoration: none;
`

const Hide = styled.span`
  position: absolute;
  top: 1.6rem;
  right: 1.8rem;
  color: ${({ theme }) => theme.colours.secondary};
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 1;
  cursor: pointer;
  opacity: 1;
  will-change: background;
  transition: background .2s ease;
  border-radius: ${({ theme }) => theme.radius.small};
  padding: .8rem 1rem .7rem 1rem;
  background: ${({ theme }) => theme.colours.background};

  &:hover {
    background: ${({ theme }) => rgba(theme.colours.background, 0.9)};
  }

  ${media.lessThan('phone')`
    display: none;
  `}
`

const ContentLoading = (props) => (
  <Wrapper>
    <ContentLoader
      speed={2}
      width={100}
      height={220}
      backgroundColor={colours.contentLoadingColor1}
      foregroundColor={colours.contentLoadingColor2}
      style={{ width: '100%', height: '22rem' }}
      {...props}
    >
      <rect x='0' y='0' rx={radius.large} ry={radius.large} width='100%' height='22rem' />
    </ContentLoader>
  </Wrapper>
)

export {
  Wrapper,
  Box,
  AnnoucementLink,
  Hide,
  Close,
  CloseWrapper,
  ContentLoading,
}
