import styled from 'styled-components'
import t from 'prop-types'

const ChartWrapper = styled.div`
  max-height: 280px;
  overflow: auto
`

const Chart = styled.div`
  width: 100%;
  height: ${props => props.height ? `${props.height}px` : '280px'};
`

Chart.propTypes = {
  height: t.number,
}

export { Chart, ChartWrapper }
