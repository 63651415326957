import styled, { css } from 'styled-components'

import CARD_VISA from './card-flag__visa.jpg'
import CARD_MASTERCARD from './card-flag__mastercard.jpg'
import CARD_DINERS from './card-flag__diners-club.jpg'
import CARD_AMEX from './card-flag__american-express.jpg'
import CARD_DISCOVER from './card-flag__discover.jpg'
import CARD_HIPERCARD from './card-flag__hipercard.jpg'
import CARD_JCB from './card-flag__jcb.jpg'
import CARD_AURA from './card-flag__aura.jpg'
import CARD_ELO from './card-flag__elo.jpg'

const cards = {
  visa: CARD_VISA,
  mastercard: CARD_MASTERCARD,
  'diners-club': CARD_DINERS,
  'american-express': CARD_AMEX,
  discover: CARD_DISCOVER,
  hipercard: CARD_HIPERCARD,
  jcb: CARD_JCB,
  maestro: CARD_AURA,
  elo: CARD_ELO,
}

const FormControl = styled.div`
  display: block;
  padding-top: 2.2rem;
  position: relative;
  ${({ horizontal }) => !horizontal && 'padding-bottom: 2.2rem;'}
`

const FormLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.2rem;
`

const FormField = styled.input`
  font-size: 1.6rem;
  display: block;
  height: 5.5rem;
  border: 1px solid ${({ error, theme }) => error ? theme.colours.error : theme.colours.gray3};
  border-radius: ${({ theme }) => theme.radius.small};
  padding: .5rem 2rem;
  width: 100%;
  transition: box-shadow .2s ease;
  outline: 0;
  color: ${({ theme }) => theme.colours.text};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-size: auto 40px;
  background-repeat: no-repeat;
  background-position: calc(100% - .5rem) center;
  background: ${({ theme }) => theme.colours.background} ;

  &:focus {
    box-shadow: 0 0 20px rgba(0, 0, 0, .05);
    border-color: ${({ theme }) => theme.colours.primary};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colours.gray2};
    font-family: ${({ theme }) => theme.font.default};
    letter-spacing: 0;
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
    pointer-events: none;
  }

  &[type="password"] {
    font-family: caption;
    letter-spacing: .2rem;
  }

  ${({ cardFlag }) => css`
    background-image: url(${cards[cardFlag]});
  `}
`

const FormError = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.colours.error};
  position: absolute;
  right: 0;
  bottom: .5rem;
`

export { FormControl, FormLabel, FormField, FormError }
