import { generateMedia } from 'styled-media-query'

const media = generateMedia({
  smallPhone: '450px',
  phone: '580px',
  smallTablet: '760px',
  tablet: '900px',
  smallDesktop: '1100px',
  mediumDesktop: '1350px',
  largeDesktop: '1600px',
  largestDesktop: '1850px',
})

export { media }
