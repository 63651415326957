import { gql } from '@apollo/client'

export const FEATURED_STATS_QUERY = gql`
  query GetFeaturedStats {
    stats {
      featuredStats {
        id
        type
        resolved
        total
        percentage
      }
    }
  }
`
