import React from 'react'
import './style.css'

const PlayIcon = () => (
  <svg width='20px' height='20px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-1303.000000, -460.000000)'>
        <g transform='translate(279.000000, 337.000000)'>
          <g transform='translate(1025.000000, 124.000000)'>
            <circle stroke='#0049FF' strokeWidth='1.5' cx='8' cy='8' r='8' />
            <polygon fill='#0049FF' transform='translate(9.000000, 8.357143) rotate(-270.000000) translate(-9.000000, -8.357143)' points='9 6 12 10.7142857 6 10.7142857' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const QuestionIcon = () => (
  <svg width='18px' height='16px' viewBox='0 0 18 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-28.000000, -536.000000)'>
        <g>
          <g transform='translate(29.000000, 537.000000)' stroke='#FFFFFF' strokeLinecap='square' strokeWidth='1.5'>
            <line x1='7.61904762' y1='2.33333333' x2='16' y2='2.33333333' />
            <rect x='0' y='0' width='4.57142857' height='4.66666667' />
            <line x1='7.61904762' y1='11.6666667' x2='16' y2='11.6666667' />
            <line x1='0' y1='9.33333333' x2='4.57142857' y2='14' />
            <line x1='4.57142857' y1='9.33333333' x2='0' y2='14' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const SimulatedIcon = () => (
  <svg width='22px' height='22px' viewBox='0 0 22 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-26.000000, -422.000000)'>
        <g>
          <g transform='translate(27.000000, 423.000000)' stroke='#FFFFFF' strokeLinecap='square' strokeWidth='1.5'>
            <polygon points='14.6666667 19.3333333 9.96266667 20 5.33333333 19.3333333 5.33333333 12 9.96266667 12.6666667 14.6666667 12' />
            <path d='M9.96266667,6.66666667 L9.96266667,6.66666667 C8.1217175,6.66666667 6.62933333,5.1742825 6.62933333,3.33333333 L6.62933333,3.33333333 C6.62933333,1.49238417 8.1217175,1.18423789e-15 9.96266667,1.18423789e-15 L9.96266667,1.18423789e-15 C11.8036158,1.18423789e-15 13.296,1.49238417 13.296,3.33333333 L13.296,3.33333333 C13.296,5.1742825 11.8036158,6.66666667 9.96266667,6.66666667 Z' />
            <path d='M8,16 L1.35,17.6626667 C1.10098231,17.7248823 0.838362277,17.6386137 0.674745513,17.4408508 C0.511128749,17.243088 0.475576716,16.9689573 0.583333333,16.736 L3.64266667,10.108 C3.86059087,9.63575137 4.33322784,9.33333332 4.85333333,9.33333332 L15.1433333,9.33333332 C15.6650908,9.33333332 16.1389489,9.63753218 16.356,10.112 L19.386,16.738 C19.4926795,16.9710965 19.4562562,17.2447146 19.2923138,17.4417866 C19.1283715,17.6388586 18.8659527,17.7244732 18.6173333,17.662 L12,16' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const WatchedIcon = () => (
  <svg width='20px' height='20px' viewBox='0 0 54 54' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsxxlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-970.000000, -377.000000)' fillRule='nonzero'>
        <g transform='translate(279.000000, 337.000000)'>
          <g transform='translate(691.000000, 40.000000)'>
            <path fill='#0049FF' d='M27.000056,0.00011011277 C41.9116737,0.00011011277 54,12.0884369 54,27.0000551 C54,41.9116732 41.9116737,54 27.000056,54 C12.0884383,54 0.000111992436,41.9116732 0.000111992436,27.0000551 L0.000111992436,27.0000551 C-0.042318127,12.1307202 11.977151,0.0425402336 26.8463386,0.00011011277 C26.8975777,-3.67042568e-05 26.9488169,-3.67042568e-05 27.000056,0.00011011277 Z' />
            <polygon fill='#FFFFFF' points='42 19.20914 22.7692572 38 12 27.552321 16.3846777 23.343181 22.7692572 29.506621 37.6154696 15' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const GrapphicSummariesIcon = () => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsxxlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 22 23' style={{ enableBackground: 'new 0 0 22 22' }} xmlSpace='preserve'>
    <path fill='none' stroke='#FFFFFF' strokeWidth='1.5' strokeLinecap='square' strokeLinejoin='round' d='M20.5,19.4h-6.1l-1.8-3.2l3-5.2L20.5,19.4z M12.7,16.3L8,8.2L1.5,19.4h12.9L12.7,16.3z M18.5,2.6 c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S19.6,2.6,18.5,2.6z' />
  </svg>
)

export {
  PlayIcon,
  QuestionIcon,
  SimulatedIcon,
  WatchedIcon,
  GrapphicSummariesIcon,
}
